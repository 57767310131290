import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import Nav from "../../common/CheckoutReviewNav";
import Foot from "../../common/CheckoutFoot";
import B_PayPal from "../../assets/images/PayPal-Logo.wine.svg";
import CreditCard from "../../assets/images/icon_credit-card.png";
import { ReactComponent as CreditCardSvg } from "../../assets/images/icon_credit-card.svg";
import { ReactComponent as Viza } from "../../assets/images/visa.svg";
import { ReactComponent as Master } from "../../assets/images/mastercard.svg";
import { ReactComponent as Maestro } from "../../assets/images/maestro.svg";
import { ReactComponent as AmercanExpress } from "../../assets/images/american-express.svg";
import { ReactComponent as Discover } from "../../assets/images/discover.svg";
import ClearPay from "../../assets/images/logo_clearpay.png";
import Address from "./CheckoutAddress";
import Slip from "./CheckoutSlip";
import Shelter from "../../assets/images/Image1.png";
import Cart from "../../assets/images/icon_cart-add.png";
import DropDown from "../../assets/images/dropdown.png";
import Modal from "react-modal";
import {ReactComponent as Logo} from "../../assets/images/logo_azoom-symbol-colorful.svg";
// import stripe from "../../assets/images/stripe-logo-blue.png";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getClientSecret } from "../../services/payment";
import { Card } from "./Card";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import PaypalModal from "./PaypalModal";
import bgShapeLeft from "../../assets/images/bg_shape-left.svg";
import bgShapeBottom from "../../assets/images/bg_shape-bottom.svg";
import bgShapeRight from "../../assets/images/bg_shape-right.png";
import useOutsideClick from "../../hooks/useOutsideClick";
import "../../assets/scss/Common/_sortingDropdown.scss";
import { DropDownCoponent } from "../../common/DropDownComponent";
import axios from "axios";
import { setOrderId } from "../../redux/orders/orderSlice";
import { palceOrder } from "../../services/order";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { setCartSummaryOpen } from "../../redux/cart/CartSlice";

export default function CheckoutReview() {
  const { customer } = useSelector((state) => state.customer);
  const { items } = useSelector((state) => state?.cart);
  const { stripeKey } = useSelector((state) => state?.cart);
  const [loading, setLoading] = useState(false);
  const [modals, setmodals] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [donation, setdonation] = useState("5");
  const [clientSecret, setClientSecret] = useState("");
  const { paypalKey } = useSelector((state) => state.cart);
  const rewardPointsApp = useSelector((state) => state.cart.rewardPoints);
  const coupon = useSelector((state) => state.cart.coupon.code);
  const { grandPrice } = useSelector((state) => state.cart.coupon);
  const stripePromise = loadStripe(stripeKey);
  const { paymentMethod } = useSelector((state) => state.payment);
  const { cart } = useSelector((state) => state);
  const { id } = useSelector((state) => state.cart);
  console.log(id)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);

  const calculateItemPrice = useCallback(() => {
    let price =
      items.reduce(
        (sum, currentVal) =>
          sum +
          currentVal.product?.price_range?.minimum_price?.final_price?.value *
            currentVal.quantity,
        0
      ) + (subTotal < 50 ? 5 : 0);

    setSubTotal(price.toFixed(2));
  }, [items]);
  const calculateTotal = useCallback(() => {
    let price =
      items.reduce(
        (sum, currentVal) =>
          sum +
          currentVal.product?.price_range?.minimum_price?.final_price?.value *
            currentVal.quantity,
        0
      ) + (subTotal < 50 ? 5 : 0) - (coupon ? grandPrice : 0 ) ;
      if (rewardPointsApp?.is_applied) {
        price -= rewardPointsApp?.spend_points * 0.01;
      }
    setTotal(price.toFixed(2));
  }, [cart , items , rewardPointsApp]);
  useEffect(() => {
    calculateItemPrice();
    calculateTotal()
  }, [calculateItemPrice , calculateTotal]);

  const handleOrderPlaceClick = async () => {
    setLoading(true);
    try {
      const { stripe_client_secret } = await getClientSecret({
        amount: subTotal,
        currency: "gbp",
        payment_type: "card",
      });
      const { clientSecret } = stripe_client_secret;
      setClientSecret(clientSecret);
      setPaymentModal(true);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePaypalOrderClick = async () => {
    setLoading(true);
    setPaymentModal(true);
  };

  const getImageSrc = useMemo(() => {
    switch (paymentMethod) {
      case "stripe":
        return CreditCard;
      case "paypal":
        return B_PayPal;
      case "clearpay":
        return ClearPay;
      default:
        return CreditCard;
    }
  }, [paymentMethod]);

  return (
      <PayPalScriptProvider options={{
        "client-id": paypalKey,
        currency: "GBP",
      }}>
      <Nav />
      
      
      <Modal
        className="thanks_popup border px-3 py-4 shadow-lg z-30000"
        onRequestClose={() => setmodals(false)}
        isOpen={modals}
      >
        <h1>Thanks for shopping with us!</h1>
        <Logo/>
        <p>
          Please wait while we process your order (do <br />
          not refresh your browser window). This may take up to a minute.
        </p>
      </Modal>
      {paymentMethod === "stripe" ? (
        <Elements stripe={stripePromise}>
          
        </Elements>
      ) : (
        <PaypalModal
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          setmodals={setmodals}
          subTotal={subTotal}
        />
      )}
      <div className="container-fluid bg-white checkout-sku">
        <img src={bgShapeLeft} className="bg-shape bg-shape-left" alt="" />
        <img src={bgShapeBottom} className="bg-shape bg-shape-bottom" alt="" />
        <img src={bgShapeRight} className="bg-shape bg-shape-right" alt="" />
        <div className="container">
          <div className="row">
            {/* //left */}
            <div className="col-lg-8 left_div pt-3">
              <Address />
              <h3>Payment Info</h3>
              <div className="paypal_divs">
                {paymentMethod === "paypal" ? (
                  <img src={getImageSrc} width="80px" alt="stripe" />
                ) : (
                  <CreditCardSvg width="80px" height="80px" />
                )}
                <h6>{paymentMethod === "paypal" ? paymentMethod : "Card"}</h6>
                <p>{customer?.email}</p>
              </div>

              <hr />
              <div className="mobile-none">
                <h3>Delivery Method</h3>
                {items.map((item, index) => (
                  <>
                    <p className="parcel_pageno">
                      Parcel {index + 1} of {items.length}
                    </p>
                    <div className="row pro_div shadow-sm">
                      <img
                        // className="col-lg-3"
                        className="
                      
                      "
                        src={item.product.image.url}
                        alt="item-img"
                      />
                      <p className="col-lg-3">{item.product.name}</p>
                      <p className="col-lg-5">
                        Get it Tomorrow - <span>FREE 1-Day Delivery</span>
                      </p>
                    </div>
                  </>
                ))}
              </div>

              
            </div>
            {/* //right/ */}
            <div className=" border-0 col-lg-4 p-3 ">
              <div className="summary-wrapper shadow-sm ">
                <Slip />
                <hr/>   
                <div className="flex w-full items-center justify-between">
                   <Viza className="h-[70px] w-auto" />
                   <Master className="h-[70px] w-auto" />
                   <Maestro className="h-[70px] w-auto" />
                   <AmercanExpress className="h-[70px] w-auto" />
                   <Discover className="h-[70px] w-auto" />
                </div>
                {paymentMethod === "stripe" &&  <Elements  stripe={stripePromise}>
                <Card
            paymentModal={paymentModal}
            setPaymentModal={setPaymentModal}
            clientSecret={clientSecret}
            setmodals={setmodals}
            subTotal={subTotal}
          />
                  </Elements>}
                {paymentMethod === "paypal" ? (
                  <PayPalButtons
                    style={{
                      layout: "horizontal",
                      tagline: "false",
                    }}
                    createOrder={async (data) => {
                      let price = items.reduce(
                        (sum, currentVal) =>
                          sum +
                          currentVal.product?.price_range?.minimum_price
                            ?.final_price?.value,
                        0
                      );
  
                      // cart_id: "${id}"
                      var gqlData = `
            query {
              paypal_create_order(
                cart_id: "${id}"
                amount: "${price.toFixed(2)}"
              ) {
                  id
                  intent
                  status
              }
            }`;

                      var API_URL = "/graphql";
                      return fetch(API_URL, {
                        method: "post",
                        body: JSON.stringify({
                          query: gqlData,
                        }),
                        headers: {
                          "content-type": "application/json",
                          Authorization: `Bearer ${localStorage.getItem("token")}`, // todo 
                          accept: "application/json",
                        },
                      })
                        .then(function (res) {
                          console.log(res);
                          return Promise.all([res.status, res.json()]);
                        })
                        .then(function ([status, data]) {
                          if (status === 200 || status === 201)
                            return data.data.paypal_create_order.id;
                          else if (status !== 201 && data.message)
                            toast.error(data.message);
                          else toast.error("Something went wrong");
                        })
                        .catch(function (err) {
                          console.log(err);
                          toast.error("Something went wrong");
                        });
                    }}
                    onApprove={(data) => {
                      var API_URL = "/graphql";

                      return fetch(API_URL, {
                        method: "post",
                        headers: {
                          "content-type": "application/json",
                          accept: "application/json",
                        },
                        body: JSON.stringify({
                          query: `query {
                      paypal_approve_order(
                        orderID:"${data.orderID}"
                      ) {
                          id
                          status
                      }
                    }`,
                        }),
                      })
                        .then(function (res) {
                          return Promise.all([res.status, res.json()]);
                        })
                        .then(async function ([status, data]) {
                          if (status === 200 || status === 201) {
                            const { placeOrder: order } = await palceOrder({
                              cart_id: cart.id,
                            });
                            setOrderId(order.order?.order_number);
                            setPaymentModal(false);
                            setmodals(true);
                            dispatch(setCartSummaryOpen(false));
                            setTimeout(() => {
                              navigate("/orders/success", { state: order });
                            }, 3000);
                            toast.success(`You have Successfully Paid`);
                          } else if (status !== 201 && data.message)
                            alert(data.message);
                          else toast.error("Something went wrong");
                        })
                        .catch(function (err) {
                          toast.error("Something went wrong");
                        });
                    }}
                  />
                ) : (
                  <></>
                )}

                <p className="last_para">
                  By ordering, you agree to our <span>Privacy Policy</span> and{" "}
                  <span>Terms & Conditions.</span>
                  You will receive product offers from us via email, you can{" "}
                  <span>unsubscribe here</span> or via a link in the email at
                  any time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Foot />
  
      </PayPalScriptProvider>

  );
}
