import axios from 'axios';
import { URL, HEADERS } from '../constants/api';
import { raiseErrorForServices } from '../constants/errors';
//sample gqlData format - query or mutation data
const gqlDataForToken = `mutation generateCustomerToken(
  $email: String!,
  $password: String!
) {
  generateCustomerToken(
    email: $email
    password: $password
  ) {
    token
  }
}`;
const gqlDataForTokenOrderId = `mutation (
  $email: String,
  $password: String,
  $order_id: String
) {
  registerGuestCustomer(
    email: $email
    password: $password
  order_id: $order_id

  ) {
    userId  
    email
    firstname
    lastname
    orderId
  }
}`;


const gqlDataForRegistration = `mutation createCustomerV2($input: CustomerCreateInput!)
{
  createCustomerV2(input: $input) {
    customer {
      firstname
      lastname
      email
      is_subscribed
    }
  }
}`;
const gqlDataForUpdateCard= `mutation updateCartItem($input: UpdateCartItemsInput!)
{
  updateCartItems(input: $input) {
    cart {
    items {
      quantity
    }
    prices {
      grand_total{
        value
        currency
      }
    } 
  }
  }
}`;

const gqlDataForEmailAvailability = `query isEmailAvailable(
  $email: String!
){
  isEmailAvailable(
    email: $email
  ) {
    is_email_available
  }
}`;

const customerSignOutQuery = `
mutation {
  revokeCustomerToken {
    result
  }
}
`;

export const customerInfoQuery = `
query {
  customer {
    created_at
    date_of_birth
    default_billing
    default_shipping    
    firstname
    lastname
    suffix
    email 
    gender
    is_subscribed
    middlename
    group{
      group_id
      group_name
    }

    addresses{
      id
      firstname
      lastname
      street
      city
      postcode
      country_code
      telephone
    }

  }
}
`;


const gqlGetFavrioutes = `
query {
  customer {
    favourites:wishlists{
      id
      items_count      
      sharing_code
      updated_at
      items_v2 {
        items {
          id
          product {
            id
            uid
            sku
            name
            price_range{
              minimum_price{
                final_price{
                  currency
                  value
                }
              }
            }           
            azoom_product_specifications{
              publication_date:publication_year      
              publisher
              format
              authors  
              pages
              edition
              reading_time
              language
              isbn
              isbn_10
              isbn_13
              dimensions
              recommended_age
            }
            image{
              label
              url
            }
            reviews {       
              page_info{
                current_page
                page_size
                total_pages
              }
              items {          
                average_rating
                ratings_breakdown{
                  name
                  value
                }          
                created_at
                nickname
                summary
                text
              }
              #page_info {
              #  page_size
              #}
            }
          }
        }
      }
    }
  }
}`;
const gqlGetStripeKey = `
query {
  stripe_get_key
}`;
export const getStripeKey = async () => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: gqlGetStripeKey,
   
    },
  });
  return raiseErrorForServices(data); //TODO
};
export const auth = async ({ email, password ,order_id }) => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: gqlDataForToken,
      variables: {
        email: email,
        password: password,
      },
    },
  });
  return raiseErrorForServices(data); //TODO
};
export const authOrderId = async ({ email, password,order_id }) => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: gqlDataForTokenOrderId,
      variables: {
        email: email,
        password: password,
        order_id : order_id

      },
    },
  });
  return raiseErrorForServices(data); //TODO
};
export const applyCoupon = async ({ email, password }) => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: gqlDataForToken,
      variables: {
        email: email,
        password: password,
      },
    },
  });
  console.log(data);
  return raiseErrorForServices(data); //TODO
};

export const getCustomerInfo = async () => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: customerInfoQuery,
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};

export const signOut = async () => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: customerSignOutQuery,
    },
    headers: HEADERS,
  });
  if (data.data.revokeCustomerToken.result) {
    localStorage.removeItem('token');
  }
  return raiseErrorForServices(data);
};

export const checkEmailAvailability = async ({ email }) => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: gqlDataForEmailAvailability,
      variables: {
        email: email,
      },
    },
  });
  return raiseErrorForServices(data);
};

export const register = async input => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: gqlDataForRegistration,
      variables: {
        input: {
          ...input,
        },
      },
    },
  }); //TODO
  return raiseErrorForServices(data);
};
export const updateCartItems = async input => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: gqlDataForUpdateCard,
      variables: {
        input: {
          ...input,
        },
      },
    },
    headers: HEADERS,

  }); //TODO
  return raiseErrorForServices(data);
};
export const getFavoritesInfo = async () => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: gqlGetFavrioutes,

    },
    headers: HEADERS,

  }); 
  return raiseErrorForServices(data);
};
