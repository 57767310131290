import React, { useState, useMemo, useEffect } from "react";
import PayPal from "../../assets/images/logo_paypal.png";
import B_PayPal from "../../assets/images/logo_paypal-ConvertImage.png";
import B_PayPal_Wine from "../../assets/images/PayPal-Logo.wine.svg";
import Radio from "../../assets/images/radiobox.png";
import { ReactComponent as CreditCard } from "../../assets/images/icon_credit-card.svg";
import stripe from "../../assets/images/stripe-logo-blue.png";
import { useNavigate } from "react-router-dom";
import ClearPay from "../../assets/images/logo_clearpay.png";
import Address from "./CheckoutAddress";
import { useSelector } from "react-redux";
import {
  setPaymentMethod,
  setShippingMethod,
} from "../../services/paymentMethod";
import { toast } from "react-toastify";
import { updatePaymentMethod } from "../../redux/payment/paymentSlice";
import { useDispatch } from "react-redux";
import { capitalize } from "lodash";
import Input from "../../common/Form/Input";
import {
  applyCoupon,
  applyRewardPoints,
  removeCoupon,
  removeRewardPoints,
} from "../../services/cart";
import { applyDiscount, setRewardPointsApp } from "../../redux/cart/CartSlice";
import { setRewardPoints } from "../../redux/customers/customerSlice";

export default function Checkout_left() {
  const dispatch = useDispatch();
  const { customer } = useSelector((state) => state.customer);
  const { rewardPoints } = useSelector((state) => state.customer);
  console.log(rewardPoints);
  const { id } = useSelector((state) => state.cart);
  const coupon = useSelector((state) => state.cart.coupon.code);
  const rewardPointsApp = useSelector((state) => state.cart.rewardPoints);
  const { cart } = useSelector((state) => state);
  const navigate = useNavigate();
  const [paymentMethod, changePaymentMethod] = useState("card");
  const [discountState, setDiscountState] = useState(true);
  const [rewardState, setRewardState] = useState(true);
  const [discount, setdiscount] = useState(coupon);
  const [reward, setReward] = useState(coupon);
  const { logedIn } = useSelector((state) => state.customer);
  const tokken = logedIn;
  useEffect(() => {
    console.log("rewardPointsApp", rewardPointsApp);
  }, [rewardPointsApp]);

  const onClickHandler = async () => {
    dispatch(
      updatePaymentMethod(paymentMethod === "card" ? "stripe" : paymentMethod)
    );
    try {
      try{

        const data = await setShippingMethod({
          cart_id: cart.id,
          shipping_methods: [
            {
              carrier_code: "flatrate",
              method_code: "flatrate",
            },
          ],
        });
        console.log("data", data);  

      }catch(err){
       toast.warning("Please add address first.");
      }
      await setPaymentMethod({
        cart_id: cart.id,
        payment_method: {
          code: `${paymentMethod === "card" ? "stripe" : paymentMethod}payment`,
        },
      });
      console.log("paymentMethod", paymentMethod);
      navigate("/checkout/review", {
        state: { paramPaymentMethod: paymentMethod },
      });
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  };

  const applyCouponHandler = async () => {
    try {
      if (coupon) {
        const data = await removeCoupon({
          cart_id: id,
        });
        dispatch(applyDiscount({ code: "" }));
        toast.success("Discount code removed successfully.");
      } else {
        const data = await applyCoupon({
          cart_id: id,
          coupon_code: discount,
        });
        dispatch(
          applyDiscount({
            code: data.applyCouponToCart.cart.applied_coupons[0].code,
            grandPrice: data.applyCouponToCart.cart.prices.grand_total.value,
          })
        );
        toast.success("Discount code applied successfully.");
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to submit your discount code.");
    }
  };
  const applyRewardPointsHandler = async () => {
    if (rewardPointsApp?.is_applied) {
      removeRewardPointsHandler();
      return;
    }
    try {
      console.log("rewardPoints", reward);
      const {mstRewardsApplyPointsToCart} = await applyRewardPoints({
        cart_id: id,
        amount: reward,
      });
      dispatch(setRewardPointsApp(mstRewardsApplyPointsToCart.cart.mstRewardPoints));

      toast.success("Reward points  applied successfully.");
    } catch (err) {
      console.log(err);
      toast.error("Unable to submit your  Reward points.");
    }
  };
  const removeRewardPointsHandler = async () => {
    try {
      const { mstRewardsApplyPointsToCart } = await removeRewardPoints({
        cart_id: id,
        amount: 0,
      });
      dispatch(setRewardPointsApp(mstRewardsApplyPointsToCart.cart.mstRewardPoints));
      toast.success("Reward points remove successfully.");
    } catch (err) {
      console.log(err);
      toast.error("Unable to remove your  Reward points.");
    }
  };

  const getImageSrc = useMemo(() => {
    switch (paymentMethod) {
      case "stripe":
        return CreditCard;
      case "paypal":
        return B_PayPal_Wine;
      case "clearpay":
        return ClearPay;
      default:
        return CreditCard;
    }
  }, [paymentMethod]);

  return (
    <div className="col-lg-8 left_div pt-3">
      <Address />
      <h3>Payment Info</h3>
      <div className="row">
        <div className="pay_info col-lg-8 col-md-8 col-sm-12 px-3 py-4 ml-3 mr-3">
          <div className="row top flex gap-5">
            <div className="flex items-center">
              <img src={Radio} />
              {paymentMethod == "card" ? (
                <CreditCard width="80px" height="40px" className="ml-3" />
              ) : (
                <img
                  className={paymentMethod == "card" ? "debit_card" : "type"}
                  src={getImageSrc}
                  width="80px"
                  style={{
                    height: "35px",
                    maxHeight: "35px",
                    minWidth: "35px",
                  }}
                />
              )}
            </div>
            <div className=" paypal_head  font-bold mr-3">
              <div>{capitalize(paymentMethod)} </div>
              <div className="text-xs">{customer?.email}</div>
            </div>
          </div>
          <div className="middle">
            {paymentMethod === "card" ? (
              <p>
                Pay securely using your Debit or Credit Card. Azoom uses secure
                encrypted payment processes.
              </p>
            ) : (
              <p>PayPal is the safest way to making online payments.</p>
            )}
          </div>
          <div className="last">
            <button className="px-5 py-3" onClick={onClickHandler}>
              Continue
            </button>
          </div>
        </div>
      </div>

      <h4>Other Payment Methods</h4>
      <div className="radiobtn_div ">
        <div className="radio">
          <label className="container flex items-center gap-3">
            {/* <img src={CreditCard} className="second_img" /> */}
            <CreditCard />
            <p className="m-0">Pay by Credit or Debit Card</p>

            <input
              type="radio"
              checked={paymentMethod === "card"}
              name="radio"
              onChange={() => changePaymentMethod("card")}
            />
            <span className="checkmark"></span>
          </label>
        </div>

        <div className="radio2 mt-10">
          <label className="container flex items-center gap-3">
            <img
              src={B_PayPal_Wine}
              style={{ height: "35px", maxHeight: "35px", minWidth: "35px" }}
              className={
                paymentMethod === "paypal"
                  ? "paypal-img"
                  : "paypal-img filrer_gray"
              }
            />
            {/* <br /> */}
            <p className="mb-0">Add PayPal</p>

            <input
              type="radio"
              name="radio"
              checked={paymentMethod === "paypal"}
              onChange={() => changePaymentMethod("paypal")}
            />
            <span className="checkmark"></span>
          </label>

          <br />
        </div>
      </div>
      {tokken  && (
        <div>
          <span
            className="cursor-pointer underline text-[#ee5f3b] font-semibold mb-2"
            onClick={() => setDiscountState(!discountState)}
          >
            {!discountState ? "Add" : "Remove"} Gift Card/Promo Code
          </span>
          <div>
            {coupon && (
              <span className="text-tertiary font-semibold">
                Discount Applied
              </span>
            )}
          </div>
          {discountState && (
            <div className="flex mt-2">
              <div className="w-1/3 discount">
                <Input
                  label="Discount Code"
                  value={coupon ? coupon : discount}
                  name="discount"
                  disabled={coupon}
                  onChange={(e) => setdiscount(e.target.value)}
                />
              </div>
              {(discount || coupon) && (
                <button
                  onClick={applyCouponHandler}
                  className={
                    "ml-2 bg-[#EE5F3B] font-[500] text-white px-3 py-2 rounded-md  disabled:bg-white"
                  }
                >
                  {coupon ? "Remove" : "Apply"}
                </button>
              )}
            </div>
          )}
          
          <div
            className="cursor-pointer mt-2 underline text-[#ee5f3b] font-semibold mb-2"
            onClick={() => setRewardState(!rewardState)}
          >
            {!rewardState ? "Apply" : "Remove"} Reward Points
          </div>
          {rewardState && (
            <div className="flex flex-col mt-2 mb-3">
              <span className="font-semibold text-[#002060]">
                You have {rewardPoints?.balance - rewardPointsApp?.spend_points} Reward Points <span className="text-[#EE5F3B] font-[500]">(
                You can only spend a maximum of 500 of your reward points at anyone time
                )</span>

              </span>

              <div className="w-full discount flex  ">
                <Input
                  label="Reward Points"
                  value={rewardPointsApp?.is_applied ? `${rewardPointsApp?.spend_points} Zoom points applied .` :reward}
                  disabled={rewardPointsApp?.is_applied}
                  name="reward"
                  onChange={(e) => setReward(e.target.value)}
                  className="w-1/3 max-sm:w-full disabled:bg-dark text-xl "
                  big={true}
                />
                {(reward ||  rewardPointsApp?.is_applied) && (
                  <button
                    onClick={applyRewardPointsHandler}
                    className={
                      " ml-2 bg-[#EE5F3B] font-[500] text-white px-3 py-2 rounded-md  disabled:bg-white"
                    }
                  >
                    {
                      rewardPointsApp?.is_applied ? "Remove" : "Apply"
                    }
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
