import React, { useCallback, useEffect, useState } from "react";
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
import options from "./mobileMenuOptions";
import Book from "../assets/images/icons/icon_book-islam-life.svg";
import Best from '../assets/images/icons/icon_bestseller.svg';
import Arival from '../assets/images/icons/icon_new-arrivals.svg';
import Offer from '../assets/images/icons/icon_offers.svg';
import { ReactComponent as Gift } from "../assets/images/icons/icon_free-gifts.svg";
import { ReactComponent as ArrowRight } from "../assets/images/arrow_right-long.svg";
import { ReactComponent as ArrowLeft } from "../assets/images/arrow_left-long.svg";
import Logo from "./Logo";
import { getCategoriesInfo } from "../services/category";
import { toast } from "react-toastify";
import {
  addCategories,
  addOutletAndDiscountCategories,
  addProductCategory,
  addSubHeading,
  setSelectedFiltersQuery,
} from "../redux/products/productSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { returnCenter } from "../routePaths";
import { data } from "./categories";
const MobileMenuSidebar = ({
  isOpen,
  setSideNavOpen,
  closeFunc = () => {},
}) => {
  const [categories, setCategories] = useState([]);
  const [category, setcategory] = useState(null);
  const [showsub, setshowsub] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeSideNav = () => {
    if (showsub) setshowsub(false);
    else setSideNavOpen(false);
  };
  useEffect(() => {
    const getCategory = async () => {
      try {
        let categories = data;
        const outletandDiscoutCategories = categories.items.filter(
          (category) => {
            if (category.name === "Outlet" || category.name === "Discount") {
              return category.id;
            }
          }
        );
        dispatch(addOutletAndDiscountCategories(outletandDiscoutCategories));
        categories = categories.items.filter(
          (category) =>
            category.name !== "Outlet" && category.name !== "Discount"
        );
        setCategories(categories);
        dispatch(addCategories(categories));
      } catch (err) {
        toast.error(err.message);
      }
    };
    getCategory();
  }, []);
  useEffect(() => {
    // let sectionIcon = document.getElementById("hidden-heading");
    // sectionIcon.parentElement.style.display = "none";
  }, []);

  const setCategoryId = useCallback(
    (category) => {
      dispatch(
        setSelectedFiltersQuery({
          key: "category_id",
          value: {
            eq: category,
          },
        })
      );
    },
    [dispatch]
  );
  const simplifyName = (name , )=>{
    // replace space with - and remove ' 

    return name.replaceAll(/'/g, " ").toLowerCase().replaceAll(" ", "-") ;
    // name.replace("'", " ").toLowerCase().replace(" ", "-");
  }
  const addCategory = useCallback(
    async category => {
      try {
        dispatch(addProductCategory(category));
        setCategoryId(category.id);
        navigate(`/products/${simplifyName(category.name)}`);
      } catch (err) {}
    },
    [dispatch, navigate, setCategoryId]
  );
  const addCategoryHead = useCallback(
    async (category, heading) => {
        console.log(heading)
      try {
        setCategoryId(heading.id);
    
        dispatch(addProductCategory(category));
        dispatch(addSubHeading(heading));
        navigate(`/products/${simplifyName(category.name)}/${simplifyName(heading.name)}`);
      } catch (err) {}
    },
    [dispatch, navigate, setCategoryId]
  );
  const addCategorySubHead = useCallback(
    async (category, subHead, heading) => {
        console.log(heading)
      try {
        setCategoryId(heading.id);
        dispatch(addProductCategory(category));
        dispatch(addSubHeading(heading));
        navigate(`/products/${simplifyName(category.name)}/${simplifyName(subHead.name)}/${simplifyName(heading.name)}`);
      } catch (err) {}
    },
    [dispatch, navigate, setCategoryId]
  );
  const sortCategory = () => {
    var list = category.children.filter(
      (item) => !["Offers", "New Arrivals", "Best Sellers"].includes(item.name)
    );
    return list;
  };
  return (
    <>
      {isOpen && (
        <div className="menuSideBar">
          <div className="w-full flex items-center justify-between h-[60px]">
            <Logo logoType="sidebarLogo" />
            <div className="mr-3" onClick={() => setSideNavOpen(false)}>
              X
            </div>
          </div>
          <div
            onClick={closeSideNav}
            className="w-full h-[48px] bg-[#F0F0F0]  test-black text-lg flex items-center"
          >
            <ArrowLeft className="mr-[13px]" />
            {showsub ? "Back" : "Back to main Menu"}
          </div>
          <div className="catego">
            <div className={"sublist " + (showsub ? "off" : "")}>
              <div className="items">
                {category &&
                  sortCategory()?.map((cat, i) => {
                    return (
                      <div
                        key={i}
                        className="flex items-center flex-col truncate text-lg "
                      >
                        <div
                          onClick={() => addCategoryHead(category ,cat)}
                          className="item w-full flex items-center truncate text-sm font-bold"
                        >
                          <img
                            className="mr-[14px]"
                            src={cat?.image || Book}
                            alt="icon"
                          />
                          {cat.name}
                        </div>
                        {cat?.children &&
                          cat.children.map((cati, j) => {
                            return (
                              <div
                                key={j}
                                onClick={() => addCategorySubHead(category , cat ,cati)}
                                style={{
                                  border:
                                    j === cat?.children.length - 1
                                      ? "none"
                                      : "",
                                }}
                                className="w-full px-[10px] py-[10px] text-[#797979] subitem"
                              >
                                {cati.name}
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
              <ul  className='w-full  flex items-center flex-col  text-sm'>
              <li className="flex gap-3 font-bold w-full  px-[10px] py-[10px] "> 
                <img className='w-[23px]' src={Best} alt="" />
                Bestsellers
              </li>
              <li className="flex w-full gap-3 font-bold cyan  px-[10px] py-[10px]">
                <img className='w-[23px]'src={Arival} alt="" /> Arrivals
              </li>
              <li className="flex gap-3  w-full font-bold ornage  px-[10px] py-[10px]">
                <img className='w-[23px]'src={Offer} alt="" />
                Offers
              </li>
            </ul>
            </div>

            <div className="list">
              {categories.map((categoryItem, i) => (
                <div
                  key={i}
                  className={` flex items-center justify-between text-sm ${
                    categoryItem.name === "Sale" ? "active" : "nav-item"
                  }`}
                  alt={categoryItem.name}
                  onClick={() => {
                    console.log(categoryItem);
                    setshowsub(true);

                    setcategory(categoryItem);
                  }}
                >
                  <div
                    onClick={(e) => {
                      addCategory(categoryItem);
                      e.stopPropagation();
                    }}
                    className="cursor-pointe font-semibold"
                  >
                    {categoryItem.name}
                  </div>
                  <div onClick={() => {
                      setshowsub(!showsub);
                    }}className="arr h-full m-1 flex items-center justify-end w-[35px]">

                  <ArrowRight
                    
                  />
                  </div>
                </div>
              ))}
            </div>
            <div className="gifts flex flex-col w-auto items-center text-center">
              <Gift />
              Free gifts
              <br />
              on all purchases
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MobileMenuSidebar;
