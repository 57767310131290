import { set } from "lodash";
import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { Link, useLocation } from "react-router-dom";
import fvrtIcon from "../../assets/images/icons/icon_favorite.svg";
import AddToCart from "../../common/AddToCart";
import { calculateRatings, calculateAvg } from "../../utils/index";
import AddFavourite from "../MyFavourites/AddFavourite";
export default function ProductCard({
  isModalOpen,
  setModalOpen,
  addToBasket,
  product,
  getData,
  routePath,
  className = "",
  ...props
}) {
  const [isLoad, setisLoad] = useState(true);
  const reviewPoints = (product) => {
    return calculateAvg(
      calculateRatings(product.reviews?.items),
      product.reviews?.items.length
    ).toFixed(1);
  };
  let location = useLocation();
  var pathName = location.pathname;
  // if pathname  ends with / then remove it
  if (pathName !== "/") {
    if (pathName[pathName.length - 1] === "/") pathName = pathName.slice(0, -1);
  } else {
    pathName = "/products";
  }
  const disPrice = () => {
    return (product.price_range?.minimum_price?.regular_price?.value).toFixed(
      2
    );
  };
  const finalPrice = () => {
    return product.price_range?.minimum_price?.final_price?.value.toFixed(2);
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className={"prod-description  " + className}>
      <div className="img-div">
        {product.sale === 1 && <div className="tag_ bg-[#ee5f3b]">Sale</div>}
        {product.new === 1 && (
          <div
            className={
              "bg-[#B6B39F] " + (product.sale === 1 ? " tag_new" : " tag_")
            }
          >
            New
          </div>
        )}
        {isLoad && <div className="loadImage"></div>}
        <img
          onLoad={() => {
            setisLoad(false);
          }}
          className="m-auto flex"
          src={product.image.url}
          alt="product-img"
        />
        <Link
          to={`${pathName}?sku=${product.sku}`}
          state={{ product: product, route: routePath }}
          className="product-action no-underline "
        >
          <AddToCart item={product} classNames="secondary_btn" />
          <button
            className="_btn"
            onClick={(e) => {
              getData(true, product);
              e.preventDefault();
            }}
          >
            View more
          </button>
        </Link>
      </div>
      <div className="prod-name">
        <p className="name line-clamp-2">{product.name}</p>
        {
          <p className="writer">
            {product.azoom_product_specifications.publisher !== ""
              ? "By " + product.azoom_product_specifications.publisher
              : ""}
          </p>
        }
      </div>
      <p className="price">
        £{finalPrice()}
        {finalPrice() !== disPrice() && (
          <span className="line-through ml-2">£{disPrice()}</span>
        )}
      </p>
      <div className="rating-stars rating-stars-wrapper ml-3">
        <ReactStars
          count={5}
          size={15}
          value={parseFloat(reviewPoints(product))}
          edit={false}
          isHalf={true}
          emptyIcon={<i className="far fa-star" />}
          halfIcon={<i className="fa fa-star-half-alt" />}
          filledIcon={<i className="fa fa-star" />}
          activeColor="#000000"
          color="black"
        />

        {reviewPoints(product) !== "0.0" && (
          <span>{reviewPoints(product)}</span>
        )}
      </div>
      <div className="free-delivery">
        <p>FREE DELIVERY</p>
        <AddFavourite product={product} />
      </div>
    </div>
  );
}
