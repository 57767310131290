import React, { useEffect, useState } from "react";
import { calculateAvg, calculateRatings } from "../../utils";
import ProductViewImages from "../ProductsPage/ProductViewImages";
import Modal from "react-modal";
import closeicon from "../../assets/images/icon_close-menu.png";
import Specifications from "../ProductDetailPage/Specifications";
import ReactStars from "react-rating-stars-component";
import { Link, useNavigate } from "react-router-dom";
import { getProductsInfo } from "../../services/product";
import { setSelectedFilters } from "../../redux/products/productSlice";
import { useDispatch } from "react-redux";

export const ProductModal = ({
  producto,
  setModalOpen,
  isModalOpen,
  routePath = [],
}) => {
  const dispatch = useDispatch();
  const [seeMore, setseeMore] = useState(false);
  const navigate  = useNavigate();
  const [product, setproduct] = useState(producto);
  let length = product?.reviews?.items.length;
  let percentage = calculateAvg(
    calculateRatings(product?.reviews?.items),
    length
  ).toFixed(1);
  const disPrice = () => {
    return product.price_range?.minimum_price?.regular_price?.value;
  };
  const finalPrice = () => {
    return product.price_range?.minimum_price?.final_price?.value;
  };

  // console.log(description)
  // const {
  //   azoom_product_specifications: { publisher },
  // } = product;
  useEffect(() => {
    const getProduct = async () => {
      console.log(producto.name);
      let productData = {
        filter: {
          sku: { eq: decodeURIComponent(product?.sku.replace(/\+/g, " ")) },
        },
      };

      try {
        let { products } = await getProductsInfo(productData);
        // get first product from the list
        console.log(products);
        let product = products.items[0];
        setproduct(product);

        // navigate(".", { product: product });
      } catch (err) {
        console.log(err);
      } finally {
      }
    };
    getProduct();
  }, [producto]);

  return (
    <Modal
      className="quickview-modal-wrapper border shadow-lg"
      isOpen={isModalOpen}
      onRequestClose={() => setModalOpen(false)}
      ariaHideApp={false}
    >
      <div className="row">
        <div className="col-12 col-md-6 product-image-wrapper">
          <div className="basic-product-info">
            <div className="d-flex align-items-start justify-between">
              <p className="text-2xl">{product?.name}</p>
              <button
                className="btn icon-mobile"
                onClick={() => setModalOpen(false)}
              >
                <img src={closeicon} alt="closeicon" />
              </button>
            </div>
            {product?.azoom_product_specifications?.publisher && (
              <span className="seemore text-sm mb-3">
                See more by{" "}
                <span
                  onClick={() => {
                    dispatch(
                      setSelectedFilters({
                        ["publisher"]: {
                          label:
                            product?.azoom_product_specifications?.publisher,
                        },
                      })
                    );
                    navigate("/products")
                  }}
                >
                  {product?.azoom_product_specifications?.publisher}
                </span>
              </span>
            )}
            <div className="rating-stars rating-stars-wrapper mt-3">
              <ReactStars
                count={5}
                size={14}
                value={parseInt(percentage, 10)}
                edit={false}
                isHalf={true}
                emptyIcon={<i className="far fa-star" />}
                halfIcon={<i className="fa fa-star-half-alt" />}
                filledIcon={<i className="fa fa-star" />}
                activeColor="#000000"
                color="black"
              />
              <span>
                {percentage} / {length} reviews
              </span>
            </div>
          </div>
          {product && product?.media_gallery && (
            <ProductViewImages product={product} />
          )}
        </div>
        <div className="col-12 col-md-6 product-overview-wrapper">
          <div className="flex max-sm:mt-5">
            <div className="d-flex">
              <p>£{finalPrice()}</p>
              {finalPrice() !== disPrice() && <del>£{disPrice()}</del>}
            </div>
            <button
              className="btn cross-icon"
              onClick={() => setModalOpen(false)}
            >
              <img src={closeicon} alt="close-icon" />
            </button>
          </div>
          <div className="collapse-wrapper">
            <p className="heading">Product Overview</p>
            <div className="wrap-collabsible desc-dropdown">
              <h2 className="sub_heading">Description</h2>
              <input id="collapsible1" className="toggle" type="checkbox" />
              <label htmlFor="collapsible1" className="lbl-toggle"></label>
              <div className="collapsible-content ">
                <div className="content-inner overflow-y-scroll h-[100%]">
                  <p
                    className={
                      " desc-text overflow-hidden" +
                      (seeMore
                        ? " max-h-[300px]"
                        : " line-clamp-3 max-h-[100px]")
                    }
                    dangerouslySetInnerHTML={{
                      __html: product?.short_description?.html,
                    }}
                  />
                </div>
                {
                  <div
                    onClick={(e) => {
                      setseeMore(!seeMore);
                      e.preventDefault();
                    }}
                    className={
                      " text-[#ee5f3b] underline font-bold cursor-pointer"
                    }
                  >
                    {!seeMore ? "See more" : "See less"}
                  </div>
                }
              </div>
            </div>
          </div>
          {product && product?.azoom_product_specifications && (
            <Specifications product={product} />
          )}
          <div>
            <Link
              to={`/products?sku=${product?.sku}`}
              state={{ product: product, route: routePath }}
            >
              {" "}
              <button className="seedetails-btn">See Full Details</button>
            </Link>
            <button className="save-btn save-btn-wrapper">
              <i className="far fa-heart mr-2"></i> Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
