import React from 'react'
import Contact from './contact_form'
import DefaultLayout from '../../common/DefaultLayout';

export default function about_expertize() 
{
    return (
	<DefaultLayout>

    <div className="container-fluid">
        <div className="row">
            <div className="col-12 help_head">
                <h1>Our Expertise</h1>
            </div>
        </div>
    </div>
    <div className="container">
        <p className='route'>Home  /  About  /  <span>Our Expertise</span></p>

        <div className="row help_main">
            <div className="col-12 col-md-7">
                <div className="pr-4">
                    <h1>Our Expertise</h1>
                    <p>
                    At Azoom, our passion and expertise blend together seamlessly, thanks to our 20+ years of experience sourcing and distributing Islamic books and other products. This depth of knowledge sets us apart from the competition, making us the go-to platform for all your Islamic shopping needs. With just a few clicks, you can find anything and everything you've been searching for.
                    </p>
                    <p>
                    While experience is important, we also strive to innovate and look towards the future. We're not content to stick with traditional methods just because they've worked in the past. Our commitment to innovation is at the core of who we are, and it allows us to make optimal use of our expertise in the Islamic world. This approach results in an online store that is both welcoming and easy to use, allowing you to shop with confidence.
                    </p>
                    <p>
                    Whether you're looking for modest children's clothing, cushions adorned with scripture, or hard-to-find Islamic texts, our ever-growing selection of authentic gifts and treats has got you covered. We're all about celebrating what truly matters in life, and we believe that our store reflects that philosophy.
                    </p>
                    <p>If you're a business owner looking to provide your customers with the best products, we can help with that too. Simply sign up for an Azoom Wholesale Account, and you'll have access to our buying power and expertise. Our dedicated account managers are always available to assist you with anything you need, making business a breeze.
                    </p>
                    <p>At Azoom, we are passionate about providing our customers with the best shopping experience possible. Our expertise, combined with our commitment to innovation, ensures that we can deliver on that promise every time.
                    </p>
                </div>

            </div>
            <Contact/>
        </div>
    </div>

    
	</DefaultLayout>
    
)
}
