import React, { useEffect, useState } from 'react';
import ProductViewModel from '../../common/ProductViewModel';
import ProductCard from './productCard';
import Splide from '@splidejs/splide';
import { useDispatch, useSelector } from 'react-redux';
// import ProductModal from '../../common/Modal/ProductModal';
import { ProductModal } from '../ProductsPage/ProductModal';
import { useLocation } from 'react-router-dom';
import { setloader } from '../../redux/loaderSlice';

const RecommendedProducts = props => {
  let [isModalOpen, setModalOpen] = useState(false);
  let [product, setProduct] = useState('');
  const [routePath, setRoutePath] = useState([]);
  const location = useLocation();
 const dispatch = useDispatch();
  const products = useSelector(state => state.products?.recommendedProducts);

  useEffect(() => {
    new Splide('#card-slider', {
      height: '28rem',
      cover: false,
      heightRatio: 0,
      arrows: true,
      perPage: 5,
      autoWidth: true,
      autoplay: true,
      interval: 300000,
      pagination: true,
      gap: 15,
      breakpoints: {
        768: {
          perPage: 3,
        },
        768: {
          perPage: 2,
        },
        992: {
          perPage: 3,
        },
        450: {
          perPage: 2,
      autoWidth: false,
          
        },
      },
    }).mount();
  });

  const getData = (isOpen, product) => {
    setModalOpen(isOpen);

    setProduct(product);
  };
  useEffect(() => {
    const pathName = location.pathname.split('/');
    pathName[0] = 'Home';
    let breadCrumPath = [
      {
        url : "/",
        text : "Home"
      },
      {
        url : "/",
        text : "Recommended Products"
      },
    ];

    setRoutePath(breadCrumPath);
  }, []);
  return (
    <>
      {product && (
        <ProductModal
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          producto={product}
          routePath={routePath}

        />

      )}
      <div className="row">
        <div className="col-12 products-div mt-fix">
          <h6 className="text-center heading mb-5">Recommended Products</h6>
          <div className="container">
            <div className="row">
              <div className="col-12 pl-0 pr-0">
                <div id="card-slider" className="splide">
                  <div className="splide__track pl-[7px]" style={{paddingLeft : "10px"}}>
                    <ul className="splide__list ">
                      {products.map((product , i) => {
                        return (
                        <li style={{width: "235px"}} key={product.id} className="splide__slide w-[80%] ">
                          <ProductCard
                            product={product}
                            isModalOpen={isModalOpen}
                            setModalOpen={setModalOpen}
                            getData={getData}
                            routePath={routePath}
                          />
                        </li>
                      )})}
                    </ul>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RecommendedProducts;
