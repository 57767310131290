import React, { useState, useEffect } from 'react';
import basket from '../../assets/images/icons/icon_basket.svg';
import user from '../../assets/images/icons/icon_user.svg';
import orders from '../../assets/images/icons/icon_orders.svg';
import help from '../../assets/images/icons/icon_help.svg';
import book3c from '../../assets/images/book3c_2048x.png';
import girlBoyReciting from '../../assets/images/girl-boy-reciting_2048x.png';
import sayings from '../../assets/images/1_fc1508ca-d837-4f3c-8fde-07f39931440c_2048x.png';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as routes from '../../routePaths';
import { useNavigate } from 'react-router-dom';
import {
  getRecentlyViewedProducts,
  getSuggestedSearches,
  gqlDataForGettingRecentlyViewedProduct,
} from '../../services/product';
import {
  updateRecentlyViewedProducts,
  updateSuggestedProducts,
} from '../../redux/products/productSlice';
import axios from 'axios';

const Suggestions = ({ items, subTotal, setCartSummaryOpen }) => {
  const navigate = useNavigate();
  const { customer } = useSelector(state => state.customer);
  const [recentProducts, setRecentProducts] = useState([]);
  const [suggestedProducts, setSuggestedProducts] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (customer) {
      getRecommendedProducts();
    }
  }, [customer]);
  
  const getRecommendedProducts = async () => {
    try { 
      var d = (localStorage.getItem("token"))
      const { data } = await axios({
        url: "/graphql",
        method: "POST",
        data: {
          query: gqlDataForGettingRecentlyViewedProduct,
          variables: {
            search: "",
            pageSize: 10,
            currentPage: 1,
          },
        },
        headers: {
          "Content-Type": "application/json",
              Authorization: `Bearer ${d}`,
        },
      });
      const { recentViewedProducts } =  data.data;
      const { suggested_searches } = await getSuggestedSearches();
      setSuggestedProducts(suggested_searches);
      dispatch(updateSuggestedProducts(suggested_searches));
      setRecentProducts(recentViewedProducts.items);
      dispatch(updateRecentlyViewedProducts(recentViewedProducts.items));
    } catch (e) {
      console.log(e)
    } finally {
    }
  };

  return (
    <div className={customer ? 'row mt-5' : 'd-none'}>
      <div className="col-12 col-md-4 ">
        <div className="suggestions-div">
          <div className="heading-div">
            <p className="heading pl-1">Account & Basket</p>
          </div>
          <div className="main-div">
            <div>
              <div
                className="icon-div"
                onClick={() => dispatch(setCartSummaryOpen(true))}
              >
                <img src={basket} className="icon" alt="" />
                <p className="text">MY BASKET</p>
                <p className="num">{`(${items?.length || 0})`}</p>
              </div>
              <p className="price-text">
                Total:<b>£{subTotal}</b>
              </p>
            {items?.length !== 0 &&   <button
                className="btn chkout-btn"
                onClick={() => navigate('/checkout/info')}
              >
                Check Out Now
              </button>}
            </div>
            <div className="header-wrapper">
              <div className="icon-div">
                <Link
                  to={!customer ? routes.login : routes.orders}
                  className="flex"
                >
                  <img src={user} className="icon" alt="" />
                  <p className="text acc-text mt-0">MY ACCOUNT</p>
                </Link>
              </div>
              <div className="icon-div">
                <Link
                  to={!customer ? routes.login : routes.orders}
                  className="flex"
                >
                  <img src={orders} className="icon" alt="" />
                  <p className="text acc-text mt-0">MY ORDERS</p>
                </Link>
              </div>
              <div className="icon-div">
                <Link to={routes.helpPage} className="icon flex">
                  <img src={help} className="icon" alt="" />
                  <p className="text acc-text mt-0">HELP CENTER</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 ">
        <div className="suggestions-div">
          <div className="heading-div">
            <p className="heading">Recently Viewed</p>
            <Link to='/favourites' className="link">See all</Link>
          </div>
          <div className="flex px-2">
            {recentProducts?.slice(0, 3).map((recentProduct , i) => (
              <div  key={i} className="img-div d-flex justify-content-center">
                <img src={recentProduct.image.url} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4 ">
        <div className="suggestions-div">
          <div className="heading-div">
            <p className="heading">Suggested Searches</p>
            <Link to='/favourites' className="link">See all</Link>
          </div>
          <div className="flex px-2">
            {suggestedProducts?.slice(0, 3).map((recentProduct , i) => (
              <div key={i} className="img-div d-flex justify-content-center">
                <img src={recentProduct.image} alt="" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Suggestions;
