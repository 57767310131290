import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addItemsToCart } from "../services/cart";
import { addToCart } from "../redux/cart/CartSlice";
import { getCustomerInfo } from "../services/auth";
import { addCustomer } from "../redux/customers/customerSlice";

const AddToCart = ({
  classNames,
  item,
  quantity = 1,
  isVariable = false,
  childProduct = {},
  disabled = false,
  variation = false,
  upsell = [],
}) => {
  const { customer } = useSelector((state) => state.customer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(false);
  const addItemToCustomerCart = async (item) => {
    setLoading(true);
    const { sku } = item;
    try {
      let cartItems;
      if (isVariable) {
        cartItems = [
          {
            quantity: quantity,
            parent_sku: sku,
            sku: childProduct.sku,
          },
        ];
      } else {
        cartItems = [
          {
            quantity: quantity,
            sku: sku,
          },
        ];
      }
      const { addProductsToCart } = await addItemsToCart(cartItems, id);
      dispatch(addToCart(addProductsToCart.cart.items));
      toast.success("Added to Cart");
    } catch (err) {
      if ("You need to choose options for your item." === err) {
        toast.warning(err);
        navigate(`/products?sku=${sku}`);
      } else {
        toast.error(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAddToCart = async () => {
    if (variation) {
      navigate(`/productDetail/${item.id}`, { state: { product: item } });
    }
    addItemToCustomerCart(item);
    for (const itemm of upsell) {
      addItemToCustomerCart(itemm);
    }
  };

  return (
    <button
      className={classNames}
      onClick={(e) => {
        handleAddToCart(e);
        e.preventDefault();
      }}
      disabled={disabled}
    >
      {disabled
        ? "Select Variation"
        : loading
        ? "Adding to Basket"
        : "Add to Basket"}
    </button>
  );
};

export default AddToCart;
