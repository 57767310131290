import React, { useState, useEffect, useMemo } from "react";
import { set, useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  setCustomerAddress,
  changeCustomerAddress,
  setBillingAddress,
  setShippingAddress,
  gqlMutationForSettingAddress,
} from "../../services/address";
import {
  addCustomer,
  addCustomerAddress,
  addEmail,
} from "../../redux/customers/customerSlice";
import {
  addBillingAddressToCart,
  addShippingAddressToCart,
} from "../../redux/cart/CartSlice";
import { toast } from "react-toastify";
import { getCountries } from "../../services/address";
import { useDispatch } from "react-redux";
import _ from "lodash";
import Input from "../../common/Form/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CheckBox from "../../common/Form/CheckBox";
import {
  auth,
  checkEmailAvailability,
  customerInfoQuery,
  getCustomerInfo,
  register,
} from "../../services/auth";
import { Link } from "react-router-dom";
import axios from "axios";
import { setGuestEmail } from "../../services/cart";

const CheckoutAddress = () => {
  const { customer } = useSelector((state) => state.customer);
  const { shipping_addresses } = useSelector((state) => state.cart);
  const { cart } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [discountState, setDiscountState] = useState(false);
  const [pass, setpass] = useState("");
  const [loginState, setLoginState] = useState(false);
  const [OnEdit, SetOnEdit] = useState(false);
  const [countriesList, setCountries] = useState([]);
  const addresses = shipping_addresses;
  console.log(addresses);

  const formSchema = yup.object({
    email: yup.string().email().required("Email is required"),
    firstname: yup.string().required("First Name is required"),
    lastname: yup.string().required("Last Name is required"),
    city: yup.string().required("City is required"),
    street: yup.array().required("Street is required"),
    postcode: yup.string().required("Postcode is required"),
  });

  const test = async () => {
    try {
      const {
        setShippingAddressesOnCart: {
          cart: { shipping_addresses },
        },
      } = await setShippingAddress({
        cart_id: cart.id,
        shipping_addresses: [
          {
            address: {
              ...addresses[0],
              save_in_address_book: true,
            },
          },
        ],
      });
      dispatch(addShippingAddressToCart(shipping_addresses));
    } catch (err) {
      // toast.error(err.message);
    }
  };

  const formMethods = useForm({
    defaultValues: addresses
      ? { ...addresses[0], email: customer?.email }
      : {
          email: "",
          firstname: "",
          lastname: "",
          city: "",
          street: [""],
          postcode: "",
          country_code: "GB",

          // coupon  : '',
        },
    resolver: yupResolver(formSchema),
  });
  const {
    watch,
    control,
    register: registerForm,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = formMethods;
  const [address, setAddress] = useState({
    firstname: "",
    lastname: "",
    city: "",
    street: "",
    telephone: "",
    postcode: "",
    country_code: "GB",
  });
  useEffect(() => {
    console.log("costumer", customer);
    // fetchCountries();
    if (addresses) {
      setAddress({ ...addresses[0] });
      test();
    }
  }, [customer]);

  const email = watch("email");
  const firstname = watch("firstname");
  const lastname = watch("lastname");
  const city = watch("city");
  const street = watch("street");
  const postcode = watch("postcode");
  // const coupon = watch("coupon")

  const fetchCountries = async () => {
    setLoading(true);
    try {
      const { countries } = await getCountries();
      setCountries(countries);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddressChange = ({ target }) => {
    const { name, value } = target;
    if (name === "street") {
      setValue(name, [value]);
      return;
    }
    setValue(name, value);
  };

  const setBillingAddressHandler2 = async (add) => {
    try {
      const {
        setBillingAddressOnCart: {
          cart: { billing_address },
        },
      } = await setBillingAddress({
        cart_id: cart.id,
        billing_address: {
          address: {
            ...add,
            save_in_address_book: true,
          },
          same_as_shipping: true,
        },
      });
      dispatch(addBillingAddressToCart(billing_address));
    } catch (err) {
      toast.error(err.message);
    }
  };
  const setShippingAddressHandler2 = async (add) => {
    try {
      const {
        setShippingAddressesOnCart: {
          cart: { shipping_addresses },
        },
      } = await setShippingAddress({
        cart_id: cart.id,
        shipping_addresses: [
          {
            address: {
              ...add,
              save_in_address_book: true,
            },
          },
        ],
      });
      dispatch(addShippingAddressToCart(shipping_addresses));
    } catch (err) {
      toast.error(err.message);
    }
  };

  const onAddressSubmit = async (formData) => {
    // e.preventDefault();
    let res;
    try {
      const tokken = localStorage.getItem("token");
      if (!OnEdit || !tokken) {
        console.log("formData", formData);
        console.log("formData", tokken);
        if (tokken === null) {
          await setGuestEmail({
            email: formData.email,
            cart_id: cart.id,
          });

          res = _.omit(formData, ["email"]);
          dispatch(addCustomerAddress(res));
          dispatch(addEmail(formData.email));
          test();
          await setShippingAddressHandler2({ ...res, country_code: "GB" });
          await setBillingAddressHandler2({ ...res, country_code: "GB" });

          return;
        } else {
          const { createCustomerAddress } = await setCustomerAddress(
            _.omit(formData, ["email"])
          );

          await setBillingAddressHandler2(createCustomerAddress);
          await setShippingAddressHandler2(createCustomerAddress);
          res = createCustomerAddress;
        }
      } else {
        console.log("formDatdddda", formData);
        const { updateCustomerAddress } = await changeCustomerAddress({
          id: customer.addresses[0].id,
          // formData without email
          input: _.omit(formData, ["email"]),
        });
        console.log("updateCustomerAddress", updateCustomerAddress);
        await setBillingAddressHandler2(updateCustomerAddress);
        await setShippingAddressHandler2(updateCustomerAddress);
        res = updateCustomerAddress;
        SetOnEdit(false);
      }
      toast.success("Updated Address Successfully");
      setAddress(res);
      dispatch(addCustomerAddress(res));
    } catch (err) {
      toast.error(err.message);
    }
  };

  const setBillingAddressHandler = async () => {
    try {
      const {
        setBillingAddressOnCart: {
          cart: { billing_address },
        },
      } = await setBillingAddress({
        cart_id: cart.id,
        billing_address: {
          address: {
            ...addresses[0],
            save_in_address_book: true,
          },
          same_as_shipping: true,
        },
      });
      dispatch(addBillingAddressToCart(billing_address));
    } catch (err) {
      toast.error(err.message);
    }
  };

  const setShippingAddressHandler = async () => {
    try {
      const {
        setShippingAddressesOnCart: {
          cart: { shipping_addresses },
        },
      } = await setShippingAddress({
        cart_id: cart.id,
        shipping_addresses: [
          {
            address: {
              ...addresses[0],
              save_in_address_book: true,
            },
          },
        ],
      });
      dispatch(addShippingAddressToCart(shipping_addresses));
    } catch (err) {
      toast.error(err.message);
    }
  };
  const fieldError = (field) => {
    return errors[field]
      ?.message /* || (error && error.response?.data.error.param === field)*/;
  };

  // useEffect(() => {
  //   const setShippingAndBillingAddress = async () => {
  //     await setShippingAddressHandler();
  //     await setBillingAddressHandler();
  //   };
  //   if (customer?.addresses.length > 0) {
  //     setShippingAndBillingAddress();
  //   }
  // }, [customer?.addresses]);

  const countriesSelectOptions = useMemo(() => {
    return countriesList.map((country) => {
      return (
        <option key={country.id} value={country.id} defaultValue={"GB"}>
          {country.full_name_english || country.three_letter_abbreviation}
        </option>
      );
    });
  }, [countriesList]);

  const handleAddressUpdate = () => {
    SetOnEdit(true);
    setAddress(customer?.addresses[0]);
  };
  const handleLogin = async () => {
    try {
      await auth({
        email: email,
        password: pass,
      });
      // dispatch(addCustomer(data));
      const d = localStorage.getItem("token");

      const { data } = await axios({
        url: "/graphql",
        method: "POST",
        data: {
          query: customerInfoQuery,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${d}`,
        },
      });
      const { customer } = data.data;
      dispatch(addCustomer(customer));
      toast.success("Logged in successfully");
    } catch (err) {
      toast.error(err.message);
    }
  };
  const handleAvailability = async (e) => {
    if (e.target.value) {
      try {
        const { isEmailAvailable } = await checkEmailAvailability({
          email: e.target.value,
        });
        console.log("isAvailble", isEmailAvailable.is_email_available);
        if (isEmailAvailable.is_email_available) {
          setLoginState(false);
        } else setLoginState(true);
      } catch (err) {
        setLoginState(false);
      }
    }
  };

  return (
    <div className="checkout-address">
      <h3>Delivery Address</h3>
      {addresses?.length > 0 && !OnEdit ? (
        <>
          <div className="alert alert-warning text-center" role="alert">
            Azoom is using this address as your shipping and billing address
          </div>
          <p className="address_p font-semibold">
            {email}
            <br />
            {addresses[0].firstname} {addresses[0].lastname}
            <br />
            {addresses[0].street[0]}
            <br />
            {addresses[0].city} <br />
            {addresses[0].postcode}
            <br />
            {addresses[0].telephone}
          </p>
          <div className="flex justify-center">
            <button className="save-address-btn" onClick={handleAddressUpdate}>
              Update Address
            </button>
          </div>
          <hr />
        </>
      ) : (
        <>
          <div className="w-[45%] max-sm:w-full">
            <Input
              {...registerForm("email")}
              label="Email"
              value={email}
              name="email"
              requierd
              error={fieldError("email")}
              onChange={(e) => {
                setValue("email", e.target.value);
                handleAvailability(e);
              }}
            />
            {loginState && !customer ? (
              <div>
                <Input
                  label="Password"
                  value={pass}
                  name="password"
                  requierd
                  type="password"
                  error={fieldError("password")}
                  onChange={(e) => setpass(e.target.value)}
                />
                <div className="flex justify-end mt-2">
                  <Link to="/forgot-password">Forgot Password?</Link>
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm ml-4"
                    onClick={handleLogin}
                  >
                    Login
                  </button>
                </div>
              </div>
            ) : (
              <span className="text-xs text-gray-500">
                You can create an account after checkout.
              </span>
            )}
          </div>
          <form
            id="create-address"
            onSubmit={handleSubmit(onAddressSubmit)}
            className="mt-4 flex flex-wrap justify-between"
          >
            <div className="w-[48%]">
              <Input
                {...registerForm("firstname")}
                label="First Name"
                value={firstname}
                name="firstname"
                requierd
                error={fieldError("firstname")}
                onChange={(e) => setValue("firstname", e.target.value)}
              />
            </div>
            <div className="w-[49%]">
              <Input
                label="Last Name"
                value={lastname}
                name="lastname"
                requierd
                error={fieldError("lastname")}
                onChange={handleAddressChange}
              />
            </div>

            <div className="w-full">
              <Input
                label="Address"
                value={street?.[0]}
                name="street"
                requierd
                error={fieldError("street")}
                onChange={handleAddressChange}
              />
            </div>
            <div className="w-[49%]">
              <Input
                label="City"
                value={city}
                name="city"
                requierd
                error={fieldError("city")}
                onChange={handleAddressChange}
              />
            </div>
            <div className="w-[49%]">
              <Input
                label="Post Code"
                value={postcode}
                name="postcode"
                requierd
                error={fieldError("postcode")}
                onChange={handleAddressChange}
              />
            </div>
            <div className="w-full mt-3">
              <Input label="Phone" name="phone" />
            </div>
            <div className="mt-4  w-full   flex items-end  justify-end">
              {/* <div className='flex items-center justify-end '> */}

              <button
                type="submit"
                form="create-address"
                // style={{ width: '100px' , height : '45px' }}
                className="save-address-btn whitespace-nowrap "
                // onClick={onAddressSubmit}
              >
                {OnEdit
                  ? `Updat${loading ? "ing" : "e"} Address`
                  : `Sav${loading ? "ing" : "e"}  Address`}
              </button>
              {/* </div> */}
            </div>
          </form>
        </>
      )}
      {/* <hr /> */}
    </div>
  );
};

export default CheckoutAddress;
