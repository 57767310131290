import React from "react";

const Recycling = (props) => (
  <>
    <h1>Azoom and recycling through a concept of a circular economy</h1>
    <br />
    <p className="ml-0 font-[500]">
      Azoom stands out as more than just a trading platform. Our commitment to
      driving positive change through business lies at the heart of our
      operations, propelling our growth. As proud proponents of the circular
      economy, Azoom ensures that no product goes to waste or ends up in a
      landfill. Our dedication to sustainability is reflected in our practice of
      either reselling or recycling every single item that comes into our
      possession. We recognise the importance of this approach, both to us and
      to our esteemed customers.
    </p>
    <div className=" text-xl font-bold italic text-[#ee5f3b]">
      Did you know that around 320 million books get dumped in landfills every
      year?
      <br />
      <span className=" text-lg  font-[500] not-italic text-[#ee5f3b]">
        According to Environmental Protection Agency (EPA) reports
      </span>
    </div>
    <p className="ml-0 font-[500]">
      Our mission extends beyond profitability. As a socially responsible
      enterprise, we actively assist charitable organisations in managing
      surplus stock, finding new homes for items they cannot sell. By doing so,
      we help optimize their financial outcomes and enable them to generate more
      resources for their causes.
    </p>
    <div className="ml-3 text-center font-semibold text-xl">
      Recycle your unused Islamic books Fast, Free, and Convenient
    </div>
    <p className="ml-0 font-[500]">
      A lot of people are just unaware of just how many non Islamic books are
      recycled each year and that’s due to the fact that unfortunately we don’t
      have the data for this, or the means to be collected.
    </p>
    <p className="ml-0 font-[500]">
      Islamic books have been selling for decades, just take a look at the
      number of madrasahs out there and multiply it by the number of students
      they have, where each year the same number of books are purchased.
    </p>
    <p className="ml-0 font-[500]">
      Alhamdhulillah over the century the Islamic world have produced countless
      authors each one providing their own unique commentaries on Islam
      regarding advice on Marriage, teenage, health, Parenting, Imaan ( faith )
      etc .
    </p>
    <p className="ml-0 font-[500]">
      This subsequently has made created a huge demand for print media, and
      there is an unreported number of books that go unread, unrecycled and into
      land fills.
    </p>
    <div className=" text-xl font-bold italic text-[#ee5f3b]">
      EPA estimates that with a ton of paper recycled, we can save 17 trees, 3.3
      cubic yards of landfill space, 6000 kilowatts of energy, and a massive
      7000 gallons of water.
    </div>
    <p className="ml-0 font-[500]">
      Azoom provides consumers with a seamless means of trading in their old
      books. Once these items are aquired and processed, they can be resold
      through other platforms, donated to noteworthy causes, or responsibly
      recycled according to the sunnah of our Prophet ( pbuh) .
    </p>
    <p className="ml-0 font-[500]">
      We are working towards drop off points around the uk, but until that
      happens we ask you to post your items to our unit. This would be made
      available to everyone very soon inshallah .
    </p>


  </>
);
export default Recycling;
