import React , {useState} from 'react';
import ReactStars from 'react-rating-stars-component';
import { calculateAvg, calculateRatings } from '../../utils';
import { Link } from 'react-router-dom';
import AddToCart from '../../common/AddToCart';

const ItemCard = ({ item , selected  , onClick}) => {
  let length = item?.reviews?.items.length;
  let disPrice = item?.price_range?.minimum_price?.regular_price?.value;
  let percentage = calculateAvg(
    calculateRatings(item?.reviews?.items),
    length
  ).toFixed(1);
  const {
    azoom_product_specifications: { publisher },
  } = item;

  const { name, id, media_gallery } = item;

  return (
    <div onClick={onClick}  className={"item-card  shadow-md"+ (selected && " selected")}>
      <div className="img-div">
        <img src={media_gallery?.[0]?.url} alt="product-img" />
        {item?.sale === 1 && <div className="label sale">Sale</div>}
      </div>
      <div className="current-item-tag">Current Item</div>
      <div className="card-body">
        <div className="prod-name">
          <p className="name truncate">{name}</p>
          <p className="writer">By {publisher}</p>
        </div>
        <p className="price">£{disPrice}</p>
        <div className="rating-stars rating-stars-wrapper">
          <ReactStars
            count={5}
            size={15}
            value={parseFloat(percentage)}
            edit={false}
            isHalf={true}
            emptyIcon={<i className="far fa-star" />}
            halfIcon={<i className="fa fa-star-half-alt" />}
            filledIcon={<i className="fa fa-star" />}
            activeColor="#000000"
            color="black"
          />
         {
          percentage !== "0.0" && 
         <span className="recently-rated">{percentage}</span>}
        </div>
      </div>
     { <div   className="card-action">
        <AddToCart item={item} classNames="btn add-btn" />
      </div>}
     {!selected && <Link to={"/productDetail/" + id}  state={{product : item}} className="details-link ">
        {/* <AddToCart item={item} classNames="btn add-btn" /> */}
        See Details
      </Link>}
    </div>
  );
};
export default ItemCard;
