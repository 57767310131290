import React, { useEffect, useState } from 'react';
import Splide from '@splidejs/splide';
import productImg from '../../assets/images/small_2048x.png';
import AddToCart from '../../common/AddToCart';

export default function FrequentlyBought({ cosssellProducts }) {
  useEffect(() => {
    new Splide('#frequently-bought-slider', {
      // height: '20rem',
      cover: false,
      heightRatio: 0,
      arrows: true,
      perPage: 5,
      autoplay: true,
      // type: 'loop',
      interval: 300000,
      pagination: false,
      gap: 20,
      breakpoints: {
        992: {
          perPage: 3,
        },
        768: {
          perPage: 2,
          pagination: true,
        },
      },
    }).mount();
  });

  return (
    <div className="container mt-5 frequently-bought-wrapper">
      <h6 className="text-center shop-text mb-5 heading-text">
        Frequently Bought Together{' '}
      </h6>
      <div className="row">
        <div className="col-12">
          <div id="frequently-bought-slider" className="splide">
            <div className="splide__track">
              <ul style={{width: "100%" , justifyContent : "center"}} className="splide__list flex">
                {cosssellProducts.map((product , i) => (
                  <li className="splide__slide shadow-md" key={i}>
                    <div className="product">
                      <div className="img-div">
                        <img
                          src={product.image?.url || productImg}
                          alt={`product-img-${product.id}`}
                        />
                      </div>
                      <div className="text-center">
                       { <AddToCart classNames={'btn add-btn'} item={product} />}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
