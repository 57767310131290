import Splide from "@splidejs/splide";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import prodimg from "../../assets/images/touch-and-feel_5000x.png";
import { HEADERS, URL } from "../../constants/api";
import { addToCart } from "../../redux/cart/CartSlice";
import { addItemsToCart } from "../../services/cart";
import ProductCard from "../Homepage/productCard";

export default function ViewedItems() {
  const [list, setlist] = useState([]);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state?.cart);

  useEffect(() => {
    getQMostViewd();

    return () => {};
  }, []);
  const addItemToCustomerCart = async (item) => {
    const { sku } = item;
    try {
      let cartItems;

      cartItems = [
        {
          quantity: 1,
          sku: sku,
        },
      ];
      
      const { addProductsToCart } = await addItemsToCart(cartItems, id);
      dispatch(addToCart(addProductsToCart.cart.items));
      toast.success('Added to Cart');
    } catch (err) {
      toast.error(err.message);
    } finally {
    }
  };
  const gqlForGettingCMostViewd = `query MyQuery {
        mostViewedProducts (count: 5){
            id
            sku
            name
            publisher
            price
            image        
            rating

        }
      }`;
  const getQMostViewd = async () => {
    try {
      const { data } = await axios({
        url: URL,
        method: "POST",
        data: {
          query: gqlForGettingCMostViewd,
          // variables: {
          //   ...productData,
          // },
        },
        headers: HEADERS,
      });
      console.log(data);
      setlist(data.data.mostViewedProducts);
      //   setcontitySold(data.data.products.items[0].quantity_sold);
    } catch (e) {
      // alert("error");
      console.log(e);
    }

    // alert(data.data.products.items[0].quantity_sold)
  };
  useEffect(() => {
    new Splide("#card_viewd-slider", {
      height: "20rem",
      cover: false,
      heightRatio: 0,
      arrows: false,
      perPage: 5,
      // type: 'loop',
      // interval: 3000,
      pagination: false,
      gap: 20,
      breakpoints: {
        992: {
          perPage: 5,
        },
        768: {
          perPage: 5,
          pagination: true,
        },
      },
    }).mount();
  });
  return (
    <div className="container viewed-items-wrapper">
      <h2>Customers Also Viewed</h2>
      <div className="row product-card">
        <div id="card_viewd-slider" className="splide">
          <div className="splide__track h-[400px]">
            <ul className="splide__list ">
              {list.map((item, i) => {
                return (
                  <li key={item.id} className="splide__slide w-auto">
                   <div className="card min-h-[400px]">
                    <div className="img_div">
                        <img src={item?.image} />
                        <div className="product-action" onClick={()=>navigate("/products?sku="+ item?.sku)}>
                            <button onClick={()=> addItemToCustomerCart(item)} className="basket_btn">Add to Basket</button>
                            {/* <button className="quickview_btn">Quick view</button> */}
                        </div>
                    </div>
                    <div className="pl-3 pt-2 pr-1">
                        <p className="product_name line-clamp-2 min-h-[48px]">{item?.name}</p>
                        <p className="text-muted">
                          {
                            item?.publisher
                            ?
                            "by " +  
                            item?.publisher
                            :
                            ""
                          }
                          </p>
                        <h3 className="price">£{item?.price}</h3>
                        <div className="rating-stars rating-stars-wrapper">
                            <ReactStars
                                count={5}
                                size={15}
                                value={parseFloat(item?.rating)}
                                edit={false}
                                isHalf={true}
                                emptyIcon={<i className="far fa-star" />}
                                halfIcon={<i className="fa fa-star-half-alt" />}
                                filledIcon={<i className="fa fa-star" />}
                                activeColor="#000000"
                                color="black"
                            />
                            {item?.rating !==0 &&  <span className="products-rating-avg">{item?.rating}</span>}
                        </div>
                    </div>
                </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
