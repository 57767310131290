import React, { useState, useCallback } from 'react';
import BookCategory from './Categories/BookCategory';
import ChildrenCategory from './Categories/ChildrenCategory';
import ClothCategory from './Categories/ClothCategory';
import PerfumeCategory from './Categories/PerfumeCategory';
import SaleCategory from './Categories/SaleCategory';
import { useSelector, useDispatch } from 'react-redux';
import { addProductCategory } from '../../redux/products/productSlice';
import { setSelectedFiltersQuery } from '../../redux/products/productSlice';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getShopByCategories } from '../../services/home';
import { toast } from 'react-toastify';

const ShopCategories = props => {
  const [category, setCategory] = useState(null);

  const { categories } = useSelector(state => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shopCategories, setShopCategories] = useState([]);

  const getShopCategories = async () => {
    try {
      const { shopByCategory } = await getShopByCategories();
      setShopCategories(shopByCategory);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    getShopCategories();
  }, []);

  const setCategoryId = useCallback(
    category => {
      localStorage.setItem('category_id', category.id);
      dispatch(
        setSelectedFiltersQuery({
          key: 'category_id',
          value: {
            eq: category.id,
          },
        })
      );
    },
    [dispatch]
  );
  const simplifyName = (name , )=>{
    // replace space with - and remove ' 

    return name.replaceAll(/'/g, " ").toLowerCase().replaceAll(" ", "-") ;
    // name.replace("'", " ").toLowerCase().replace(" ", "-");
  }
  const addCategory = useCallback(
    async category => {
      if (category?.name === "Sale")
      {
        dispatch(addProductCategory(category));
        setCategoryId(category);
        return navigate('/products/sale');
      }
      
      try {
        dispatch(addProductCategory(category));
        setCategoryId(category);
        const categoryUrlPath = category.children[0].url_path.split('/')[0];
        navigate(`/products/${simplifyName(category?.name)}`);
      } catch (err) {}
    },
    [dispatch, navigate, setCategoryId]
  );

  const clickHandler = categoryName => {
    const selectedCategory = categories.find(
      c => c.name.toLowerCase() === categoryName.toLowerCase()
    );
    setCategory(selectedCategory);
    addCategory(selectedCategory);
  };

  return (
    <div className="row mt-5 shopbycategory-wrapper">
      <div className="col-12">
        <h6 className="text-center shop-text mb-5">Shop by Category</h6>
        <div className="container">
          <div className="row flex">
            <div
              className="col-md-2 col-6 category-div"
              onClick={() => clickHandler('Books')}
            >
              <div className={"flex flex-col items-center"}>
                {/* <img src={books} alt="" /> */}
                <BookCategory category-name />
                <p className="mt-3 category-name megamenus">Books</p>
              </div>
            </div>
            <div
              className="col-md-2 col-6 category-div"
              onClick={() => clickHandler('Children')}
            >
              <div className={"flex flex-col items-center"}>
                <ChildrenCategory />
                <p className="mt-3 category-name megamen">Children</p>
              </div>
            </div>
            <div
              className="col-md-2 col-6 category-div"
              onClick={() => clickHandler('Health & Beauty')}
            >
              <div className={"flex flex-col items-center"}>
                <PerfumeCategory />
                <p className="mt-3 category-name megamen">Fragrance</p>
              </div>
            </div>
            <div
              className="col-md-2 col-6 category-div"
              onClick={() => clickHandler('Islamic Accessories')}
            >
              <div className={"flex flex-col items-center"}>
                <ClothCategory />
                <p className="mt-3 category-name megamen">Islamic Accessories</p>
              </div>
            </div>
            <div
              className="col-md-2 col-12 category-div sale-category"
              onClick={() => clickHandler('Sale')}
            >
              <div className={"flex flex-col items-center"}>
                <SaleCategory />
                <p className="mt-3 category-name sale  mamenus">Sale</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShopCategories;
