import React, { useEffect, useCallback, useState, useMemo } from "react";
import DefaultLayout from "../../common/DefaultLayout";
import FiltersSidebar from "./FiltersSidebar";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { DefaultLayoutChildren } from "./DefaultLayoutChildren";
import { ProductModal } from "./ProductModal";
import {
  getProducts1,
  getProductsInfo,
  getSearchProductInfo,
} from "../../services/product";
import {
  addProductCategory,
  addProducts,
  setfetchingProducts,
} from "../../redux/products/productSlice";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addFilters } from "../../redux/products/productSlice";
import { setSelectedFiltersQuery } from "../../redux/products/productSlice";
import { data } from "../../common/categories";
import {
  updateCurrentPage,
  updatePageCount,
} from '../../redux/products/productSlice';
const ProductsPage = () => {
  let [isSideNavOpen, setSideNavOpen] = useState(false);
  let [isModalOpen, setModalOpen] = useState(false);
  let [product, setProduct] = useState("");
  const [routePath, setRoutePath] = useState([]);
  const categories = data.items;
  const { selectedSubHeading,  pageSize, currentPage } = useSelector(
    (state) => state.products
  );

  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const selectedFiltersQuery = useSelector(
    (state) => state?.products.selectedFiltersQuery
  );

  const getSearchProducts = useCallback(
    async (search) => {
      try {
      
        let { products } = await getSearchProductInfo(search);
        dispatch(addFilters(products.aggregations));
        dispatch(addProducts(products));
      } catch (err) {
        toast.error(err.message);
      }
    },
    [dispatch]
  );
  const setCategoryId = useCallback(
    id => {
      dispatch(
        setSelectedFiltersQuery({
          key: 'category_id',
          value: {
            eq: id,
          },
        })
        );
      },
    [dispatch , setSelectedFiltersQuery]
  );

  const addCategory = useCallback(
    async category => {
      try {
        dispatch(addProductCategory(category));
        setCategoryId(category.id);
      } catch (err) {}
    },
    [dispatch, setCategoryId]
  );
   const simplifyName = (name , subName)=>{
    // replace space with - and remove ' 

    return name.replaceAll(/'/g, " ").toLowerCase().replaceAll(" ", "-") ;
    // name.replace("'", " ").toLowerCase().replace(" ", "-");
  }
  useEffect(() => {
    const categoryName = location.pathname.split("/")[2];
    const SubCategoryName = location.pathname.split("/")[3];


    if (searchParams.get("search")) {
      getSearchProducts(searchParams.get("search"));
    } else if (
      !searchParams.get("search") && categoryName !== ""
      ) {
        if (
        categoryName &&
        categoryName !== "search"
      ) {
        var selectedCategory = categories.filter(
          (category) =>
          simplifyName(category.name) ===
            categoryName.toLowerCase()
        );
          

        if (selectedCategory[0]) {
          if (SubCategoryName){
            var selectedSubCategory = selectedCategory[0].children.filter(
              (category) =>
            (simplifyName(category.name , SubCategoryName) === SubCategoryName)
            );
            if (selectedSubCategory){
              addCategory(selectedSubCategory[0])

            }

          }
          else{
            // setCategoryId(selectedCategory[0]?.id);
            addCategory(selectedCategory[0])

          }
        }
      } else {
        const category = categories[0]; // selected the default category
        localStorage.setItem("category_id", category.id);
        dispatch(
          setSelectedFiltersQuery({
            key: "category_id",
            value: {
              eq: category.id,
            },
          })
        );
      }
    } 
      
    // getProducts();
    
  }, [
    // getProducts,
    searchParams,
    getSearchProducts,
    categories,

    dispatch,
    // location.pathname,
  ]);

  const getData = async (isOpen, product) => {
    setProduct(product);
    setModalOpen(isOpen);
  };
  useEffect(() => {
      
  }, [selectedFiltersQuery])
   useEffect(() => {
    let isFetching = true;

    const getProducts2 = async () => {
      let productData = {
        filter: selectedFiltersQuery,
        pageSize: pageSize,
        currentPage: currentPage,
      };
      console.log("getProducts2" , currentPage);
      dispatch(setfetchingProducts(true));
      try {
        let { products } = await getProducts1(productData);
        if (isFetching) {
          dispatch(addFilters(products.aggregations));
          dispatch(addProducts(products));    
        }
      } catch (err) {
        toast.error(err.message);
      } finally {
        dispatch(setfetchingProducts(false));
      }
    };
    
    getProducts2();
  
     return () => {
      isFetching = false; // Cancel ongoing fetch requests
     } 
   }, [ currentPage, pageSize  ,selectedFiltersQuery])
   useEffect(() => {
    // update current page when the filters are changed
    dispatch(updateCurrentPage(1));
  }, [selectedFiltersQuery, dispatch]);

   const getProducts = useCallback(async () => {

    let productData = {
      filter: selectedFiltersQuery,
      pageSize: pageSize,
      currentPage: currentPage,
    };
    dispatch(setfetchingProducts(true));
    try {
      let { products } = await getProducts1(productData);
      dispatch(addFilters(products.aggregations));
      dispatch(addProducts(products));
    } catch (err) {
      toast.error(err.message);
    } finally {
      dispatch(setfetchingProducts(false));
    }
}, [])
  useEffect(() => {
    const pathName = location.pathname.split("/");
    pathName[0] = "home";
    let breadCrumPath = [];
    // for (let i in pathName) {
    //   console.log(pathName.slice(0, i).join("/"));  t
    //   breadCrumPath[i] = {
    //     url: i === 0 ? "/" : pathName.slice(0, i).join("/") ,
    //     text: pathName[i].replace("-", " ").toUpperCase(),
    //   };
    // }
    setRoutePath(breadCrumPath);
  }, []);

  return (
    <>
      {product && (
        <ProductModal
          producto={product}
          setModalOpen={(e)=>{setModalOpen(e) ; setProduct(null)}}
          isModalOpen={isModalOpen}
          routePath={routePath}
        />
      )}
      <FiltersSidebar
        pageWrapId={"header-wrapper"}
        outerContainerId={"App"}
        isOpen={isSideNavOpen}
        setSideNavOpen={setSideNavOpen}
        filterProducts={getProducts}
      />
      <DefaultLayout hasMoneyBack={true} hasOptionsPartners={true}>
        <DefaultLayoutChildren
          setSideNavOpen={setSideNavOpen}
          selectedSubHeading={selectedSubHeading}
          getData={getData}

        />
      </DefaultLayout>
    </>
  );
};

export default ProductsPage;
