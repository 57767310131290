import React , {useState , useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../assets/images/dropdown.png";
import { addProducts } from "../redux/products/productSlice";

const SortingDropdown = ({sort ,setSort}) => {
	const { products } = useSelector(state => state?.products);
	const dispatch = useDispatch();

	
	return (
		<div className="btn_div">
			<div className="dropdown sortingDropdown mr-3">
				<button
					className="dropdowns dropdown-toggle"
					type="button"
					id="dropdownMenuButton1"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					<img className="rotate180deg" src={DropDown} />
					<p>Sort By</p>
					<small>{(!sort ? "Ascending" : "Descending")}</small>
				</button>
				<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
					<li onClick={
						()=>{
							
							setSort(false)
						}
					} >
						<span className="dropdown-item">
							Ascending
						</span>
					</li>
					<li onClick={
						()=>{
							
							setSort(true)

						}
					}
					>
						<span className="dropdown-item" >
							Descending
						</span>
					</li>
				</ul>
			</div>
		</div>
	);
};
export default SortingDropdown;
