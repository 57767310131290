import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  id: null,
  isCartSummaryOpen: false,
  include_free_gift: false,
  stripeKey: "",
  paypalKey: "",
  coupon: {
    code: "",
    grandPrice: 0,
  },
  rewardPoints: null,
  finalPrice: 0,
  //@added - to fix checkout address not set issue
  billing_address:{},
  shipping_addresses:[],

};



export const cartSlice = createSlice({
  name: 'cartInfo',
  initialState,
  reducers: {
    setCart: (state, action ) => {
      const {
        payload: { id, items , include_free_gift ,mstRewardPoints },
      } = action;
      state.id = id;
      state.items = items;
      state.include_free_gift = include_free_gift;
      state.rewardPoints = mstRewardPoints;
      if (action.payload?.applied_coupons)
      {
        state.coupon.code = action.payload?.applied_coupons[0].code
        state.coupon.grandPrice = action.payload?.prices?.grand_total?.value
      }
    },
    applyDiscount: (state, action ) => {
      const { payload } = action;
      state.coupon = payload;
    },
    setStrieKey: (state, action) => {
      state.stripeKey = action.payload;
    },
    setRewardPointsApp: (state, action) => {
      state.rewardPoints = action.payload;
    },
    setFreeGiftDis: (state, action) => {
      state.include_free_gift = action.payload;
    },
    setPaypalKey: (state, action) => {
      state.paypalKey = action.payload;
    },
    setCartSummaryOpen: (state, action) => {
      state.isCartSummaryOpen = action.payload;
    },
    addToCart: (state, action) => {
      const { payload } = action;
      state.items = payload;
    },
    addBillingAddressToCart: (state, action) => {
      const { payload } = action;
      state.billing_address = payload;
    },
    addShippingAddressToCart: (state, action) => {
      const { payload } = action;
      state.shipping_addresses = payload;
    },
    changeQuantity: (state, action) => {
      const {
        payload: {  items , i },
      } = action;
      state.items[i].quantity = items[i].quantity;
      // state.shipping_addresses = payload;
    },
    removeFromCart: (state, action) => {
      const { payload } = action;
      state.items = state.items.filter(item => item.id !== payload);

    

    },
  },
});

export const {
  setRewardPointsApp,
  setPaypalKey,
  setStrieKey,
  setCart,
  addToCart,
  addBillingAddressToCart,
  addShippingAddressToCart,
  setCartSummaryOpen,
  removeFromCart,
  changeQuantity,
  setFreeGiftDis,
  applyDiscount,
} = cartSlice.actions;

export default cartSlice.reducer;
