import React, { useState , useEffect} from 'react';
import helpIcon from '../../assets/images/icon_help-white.svg';
import DefaultLayout from '../../common/DefaultLayout';
import HelpSearchContact from './HelpSearchContact';
import ChangeOrder from './ChangeOrder';
import ReturnPolicy from './ReturnPolicy';
import Refund from './Refund';
import CancelOrder from './CancelOrder';
import DeliveryInfo from './DeliveryInfo';
import ReplaceItems from './ReplaceItems';
import Breadcrumbs from '../../common/Breadcrumbs';
import * as routes from '../../routePaths';
import RewardPolicy from './RewardPolicy';
import ReturnCenter from '../ReturnCenter/ReturnCenter';
import { useParams , useNavigate , useLocation} from "react-router-dom";
import Recycling from './Recycling';

const HelpPage = (props) => {
	const {id}  = useParams();
	const navigate = useNavigate()
	let policy = 'return-policy', refund = 'refund', replace = 'replace',
		changeOrder = 'changeOrder', delivery = 'delivery', cancel = 'cancel' ,reward = 'rewards-policy',recycling = 'recycling'
	let [selected, setSelected] = useState(id || policy);

	const handleHelpType = (e) => {
		setSelected(e.target.id);
		navigate(`/help/${e.target.id}`)
	// scroll to 0
	}
	const { pathname } = useLocation();

	useEffect(() => {
		const path = pathname.split("/")
		setSelected(path[path.length -1 ]);
	  window.scrollTo(0, 0);
	}, [pathname]);
	return (
		<DefaultLayout hasOptionsPartners={true}>
			<div className="container-fluid heading_div mb-4 d-flex justify-content-center align-items-center">
				<b>Help Centre  &nbsp;</b>
				<img src={helpIcon} alt="icon" />
			</div>
			<div className='container'>
				<Breadcrumbs 
				 	paths={[{url: routes.homepage,text:"Home"},{url: routes.helpPage,text:"Help"}]}
				/>
			</div>
			<div className="container mt-3 help_container mb-5">
			{/* <p className='route_help'>Home  &nbsp;/&nbsp;  Help Centre &nbsp; /&nbsp; <span> Return Policy</span></p> */}

				<div className="row">
					<div className="col-sm-9 left_div">
						{selected === reward && <RewardPolicy />}
						{selected === changeOrder && <ChangeOrder />}
						{selected === policy && <ReturnPolicy />}
						{selected === refund && <Refund />}
						{selected === cancel && <CancelOrder />}
						{selected === delivery && <DeliveryInfo />}
						{ selected === replace && <ReplaceItems /> }
						{ selected === recycling && <Recycling /> }
						<HelpSearchContact />
					</div>
					<div className="col-sm-3 right_div mobile-hidden">
						<h1>Related Articles</h1>
						<ul className="mt-5 ">
							<li className={`mb-4 ${selected === policy ? 'highlight  text-white' : ''}`} onClick={handleHelpType} id={policy}>Return Policy</li>
							<li className={`mb-4 ${selected === reward ? 'highlight  text-white' : ''}`} onClick={handleHelpType} id={reward}>Reward Policy</li>
							<li className={`mb-4 ${selected === refund ? 'highlight  text-white' : ''}`} onClick={handleHelpType} id={refund}>Refunds</li>
							<li className={`mb-4 ${selected === replace ? 'highlight  text-white' : ''}`} onClick={handleHelpType} id={replace}>Replacing Damaged Items or Missing Parts</li>
							<li className={`mb-4 ${selected === changeOrder ? 'highlight  text-white' : ''}`} onClick={handleHelpType} id={changeOrder}>How Do I Make Changes to an Order?</li>
							<li className={`mb-4 ${selected === delivery ? 'highlight  text-white' : ''}`} onClick={handleHelpType} id={delivery}>Delivery Info</li>
							<li className={`mb-4 ${selected === cancel ? 'highlight  text-white' : ''}`} onClick={handleHelpType} id={cancel}>Returns & Cancellations Form</li>
							<li className={`mb-4 ${selected === recycling ? 'highlight  text-white' : ''}`} onClick={handleHelpType} id={recycling}>Azoom Recycling</li>
						</ul>
					</div>
				</div>
			</div>
		</DefaultLayout>
	)
};
export default HelpPage;
