import { set } from "lodash";
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as CloseIcon } from "../../assets/images/icon_close-menu.svg";
import { ReactComponent as Gift } from "../../assets/images/icon_gift-card.svg";

import {
  applyDiscount,
  changeQuantity,
  removeFromCart,
  setCart,
  setFreeGiftDis,
} from "../../redux/cart/CartSlice";
import { applyCoupon, updateCartItems } from "../../services/auth";
import { removeItemfromCard } from "../../services/cart";
import { getFreeGift } from "../../services/home";
import "./style.scss";
export default function CheckoutSlip() {
  const { id, items, include_free_gift } = useSelector((state) => state?.cart);
  const coupon = useSelector((state) => state.cart.coupon.code);
  const { grandPrice } = useSelector((state) => state.cart.coupon);
  const cart = useSelector((state) => state?.cart);
  const dispatch = useDispatch();
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [savings, setSavings] = useState(0);
  const navigate = useNavigate();
  const rewardPointsApp = useSelector((state) => state.cart.rewardPoints);
  const [freeGift, setFreeGift] = useState(
  
    include_free_gift === "1" ? true : false
  );
  const calculateItemPrice = useCallback(() => {
    let price =
      items.reduce(
        (sum, currentVal) =>
          sum +
          currentVal.product?.price_range?.minimum_price?.final_price?.value *
            currentVal.quantity,
        0
      ) + (subTotal < 50 ? 5 : 0);

    setSubTotal(price.toFixed(2));
  }, [items]);
  const calculateTotal = useCallback(() => {
    let price =
      items.reduce(
        (sum, currentVal) =>
          sum +
          currentVal.product?.price_range?.minimum_price?.final_price?.value *
            currentVal.quantity,
        0
      ) + (subTotal < 50 ? 5 : 0) - (coupon ? grandPrice : 0 ) ;
      if (rewardPointsApp?.is_applied) {
        price -= rewardPointsApp?.spend_points * 0.01;
      }
    setTotal(price.toFixed(2));
  }, [cart , items , rewardPointsApp]);
  const calculateSaving = useCallback(() => {
    let price = 0;
    items.forEach((item) => {
      console.log(item.product?.price_range);
      if (item.product?.price_range?.maximum_price?.discount?.amount_off) {
        price +=
          item.product?.price_range?.maximum_price?.discount?.amount_off *
          item.quantity;
      }
    });
    if (rewardPointsApp?.is_applied) {
      price += rewardPointsApp?.spend_points * 0.01;
    }
    // if (coupon) {
    //   price += ();
    // }
    setSavings(price.toFixed(2));
    if (items?.length ===0)
    {
      navigate('/')
    }
  }, [items , rewardPointsApp]);

  useEffect(() => {
    setFreeGift(include_free_gift === "1" ? true : false);
  }, [include_free_gift]);
  useEffect(() => {

    dispatch(
      setFreeGiftDis(
         (freeGift ? "1" : "0")
      )
    );
  }, [freeGift]);
  useEffect(() => {
    calculateSaving();
    calculateItemPrice();
    calculateTotal();
  }, [items, coupon, freeGift , rewardPointsApp , calculateSaving] );

  const handleFreeGiftClick = async () => {
    const freeGift = cart.include_free_gift === "1" ? true : false;
    try {
      const { setIncludeFreeGift } = await getFreeGift({
        cart_id: id,
        include_free_gift: !freeGift ? "1" : "0",
      });
      if (setIncludeFreeGift.quote_id) {
        console.log("setIncludeFreeGift", setIncludeFreeGift);

        setFreeGift(!freeGift);
        toast.success(`Free gift ${!freeGift ? "added" : "removed"}`);
      }
    } catch (e) {
      console.log(e);
      toast.error("Unable to free gift");
    } finally {
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h2>Basket Summary</h2>

        <span
          className="item_count flex border border-2 border-rose-500 py-1 px-3 w-[100px] justify-center text-center
      rounded-pill
      
      "
        >
          {items.length} items
        </span>
      </div>

      <br />
      {items.map((item, i) => (
        <ItemRow key={i} item={item} i={i} items={items} />
      ))}

      <hr />

      <div className="flex items-center p-2 my-2">
        <div className="round mx-2">
          <input
            type="checkbox"
            id="checkbox1"
            checked={freeGift}
            onChange={handleFreeGiftClick}
          />
          <label className="mt-0" htmlFor="checkbox1"></label>
        </div>
        <Gift className="mx-2" />
        <p className="mb-0">
          I am getting a <b>FREE GIFT</b>
        </p>
      </div>
      <div className="saving flex justify-between items-center">
        <span className="font-bold">Total saving:</span>
        <span className="font-bold">£{savings}</span>
      </div>
      <div className="price_div m-2">
        <p className="amount">
          £{parseFloat(subTotal - (subTotal < 50 ? 5 : 0)).toFixed(2)}
        </p>
        <p className="amout_for">Item (s) Subtotal:</p>
      </div>

      <div className="price_div m-2">
        <p className="amount">{subTotal < 50 ? "£5.00" : "FREE"}</p>
        <p className="amout_for">Delivery:</p>
      </div>
      {coupon && (
        <div className="price_div m-2">
          <p className="amount">- £{((subTotal - (subTotal <50 ? 5 : 0) ) - grandPrice).toFixed(2)}</p>
          <p className="amout_for">
            Discount <b className="text-tertiary">({coupon})</b>
          </p>
        </div>
      )}
      {
        rewardPointsApp?.is_applied && (
          <div className="price_div m-2">
          <p className="amount">- £{rewardPointsApp?.spend_points * 0.01}</p>
          <p className="amout_for">
            Rewards Discount <b className="text-tertiary">({rewardPointsApp?.spend_points} Zoom Points)</b>
          </p>
        </div>
        )
      }
      <span className="text-xs text-gray-500">
        *Free delivery on orders over £50
      </span>
      <hr />
      <div className="total_price mt-4">
        <p className="amount">£{total}</p>
        <p className="amout_for">Total:</p>
      </div>
    </div>
  );
}

export function ItemRow({ item, i, key, items }) {
  const { id } = useSelector((state) => state?.cart);
  const { coupon } = useSelector((state) => state?.cart);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setcount] = useState(item.quantity);
  const handleProductCounter = async (opertor, i) => {
    var itemNew = items;
    if (opertor === "minus" && count === 1) return;
    try {
      var contity = opertor === "plus" ? count + 1 : count - 1;
      var cardNew = await updateCartItems({
        cart_id: id,
        cart_items: [
          {
            cart_item_id: itemNew[i].id,
            quantity: contity,
          },
        ],
      });
      dispatch(
        changeQuantity({ items: cardNew.updateCartItems.cart.items, i })
      );
      dispatch(applyDiscount({
        ...coupon,
        grandPrice: cardNew.updateCartItems.cart.prices.grand_total.value,
      }));

      setcount(cardNew.updateCartItems.cart.items[i].quantity);
    } catch (e) {
      navigate("/login/email");
    }
  };
  return (
    <div key={key} className="itemss  px-3 mb-3">
      <img className="p_image" src={item.product.image.url} alt="itempic" />
      <div className="name flex flex-col  ">
        <div className="truncate">{item.product.name}</div>
        <div className="w-20">
          <div className="flex-1 border mt-1 counter_div ">
            <button
              className="minus"
              disabled={item.quantity === 1}
              onClick={() => handleProductCounter("minus", i)}
            >
              <i className="fas fa-minus"></i>
            </button>
            <p>{count}</p>
            <button
              className="plus"
              onClick={() => handleProductCounter("plus", i)}
            >
              <i className="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <b className="price">
        £{item.product.price_range.minimum_price.final_price.value}
      </b>
      <span
        onClick={async () => {
          try {
            await removeItemfromCard(id, item.id);
          } catch (e) {
            console.log(e);
            // TODOOOO
          }
          dispatch(removeFromCart(item.id));
          if (items.length === 1) {
            navigate("/");
          }
        }}
        className="close flex items-center justify-center "
      >
        <CloseIcon />
      </span>
    </div>
  );
}
