import React, { useCallback } from "react";
import { Routes, Route, useSearchParams, useLocation } from "react-router-dom";
import * as routes from "../routePaths";
import Homepage from "./Homepage/Homepage";
import HelpPage from "./HelpPage/HelpPage";
import DeliveryInfo from "./HelpPage/DeliveryInfo";
import Productsearch from "./ProductsPage/ProductsPage";
import ProductDetailPage from "./ProductDetailPage/ProductDetailPage";
import AboutPage from "./AboutPage/AboutPage";
import OrdersPage from "./OrdersPage/OrdersPage";
import AboutExpertize from "./AboutPage/about_expertize";
import AboutContact from "./AboutPage/about_contact";
import AboutIdea from "./AboutPage/about_idea";
import AboutWhyUs from "./AboutPage/about_whyus";
import Login from "./Login/login";
import Password from "./Login/password";
import CreatePass from "./Login/createPass";
import OrderSuccess from "./OrderSuccess/OrderSuccess";
import CheckoutInfo from "./checkout/CheckoutInfo";
import CheckoutReview from "./checkout/CheckoutReview";
import ReturnCenter from "./ReturnCenter/ReturnCenter";
import MyFavourites from "./MyFavourites/MyFavourites";
import ProductReview from "./ProductReview/ProductReview";
import ReturnPolicy from "./HelpPage/ReturnPolicy";
import AuthenticatedRoute from "../components/hoc/AuthenticatedRoute";
import RewardPage from "./RewardPage/RewardPage";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useSelector } from "react-redux";
import MyProductReview from "./MyProductReviews/MyProductReviews";


const Routing = () => {
  const { paypalKey } = useSelector((state) => state.cart);

  const initialOptions = useCallback(
    () => {
      return( {
        "client-id": paypalKey,
        currency: "GBP",
      })
    },
    [paypalKey],
  )
  return (
    <Routes>
      <Route path={routes.homepage} element={<Homepage />} />
      <Route path={routes.helpPage} element={<HelpPage />} />
      <Route path={routes.productsPage} element={<ProductsPageWrapper />} />
      <Route path={routes.productsPagep} element={<ProductsPageWrapper />} />
      <Route path={routes.productsPagesub} element={<ProductsPageWrapper />} />
      <Route path={routes.products} element={<ProductsPageWrapper />} />
      <Route path={routes.deliveryInfo} element={<DeliveryInfo />} />
      {/* <Route path={routes.returnPolicy} element={<ReturnPolicy />} /> */}
      {/* <Route
        path={routes.productsSubCategoryPage}
        element={<Productsearch />}
      /> */}
      <Route
        path={routes.reviewProduct}
        element={
          <AuthenticatedRoute>
            <ProductReview />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={routes.myReviewProduct}
        element={
          <AuthenticatedRoute>
            <MyProductReview/>
          </AuthenticatedRoute>
        }
      />
      {/* <Route path={routes.productDetail} element={<ProductDetailPage />} /> */}
      {/* <Route path={routes.productDetailCat} element={<ProductDetailPage />} /> */}
      {/* <Route path={routes.productDetailSubCat} element={<ProductDetailPage />} /> */}
      <Route path={routes.aboutPage} element={<AboutPage />} />
      <Route path={routes.expertisePage} element={<AboutExpertize />} />
      <Route path={routes.contactPage} element={<AboutContact />} />
      <Route path={routes.ideaPage} element={<AboutIdea />} />
      <Route path={routes.chooseUsPage} element={<AboutWhyUs />} />
      <Route
        path={routes.orders}
        element={
          <AuthenticatedRoute>
            <OrdersPage />
          </AuthenticatedRoute>
        }
      />
      <Route
        path={routes.rewardPage}
        element={
          <AuthenticatedRoute>
            <RewardPage />
          </AuthenticatedRoute>
        }
      />
      <Route path={routes.login} element={<Login />} />
      <Route path={routes.pass} element={<Password />} />
      <Route path={routes.c_pass} element={<CreatePass />} />
      <Route path={routes.order_success} element={<OrderSuccess />} />
      <Route
        path={routes.checkout_info}
        element={
          // <AuthenticatedRoute>
            <CheckoutInfo />
          // </AuthenticatedRoute>
        }
      />
      <Route
        path={routes.checkout_review}
        element={
          // <AuthenticatedRoute>
            <CheckoutReview /> 
          // </AuthenticatedRoute>
        }
      />
      <Route path={routes.returnCenter} element={<ReturnCenter />} />
      <Route
        path={routes.myFavourites}
        element={
          <AuthenticatedRoute>
            <MyFavourites />
          </AuthenticatedRoute>
        }
      />
    </Routes>
  );
};

function ProductsPageWrapper() {
  // const { category } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sku = searchParams.get("sku");
  if (sku) {
    return <ProductDetailPage />;
  } else {
    return <Productsearch />;
  }
}

export default Routing;
