import React from 'react'
import { setSignin } from '../redux/loaderSlice';
import { useDispatch, useSelector } from 'react-redux';
import Modal from './Modal/Modal';
import { useNavigate } from 'react-router-dom';

export default function SignInModal() {
    // alert()
    const dispatch  = useDispatch()
    const navigate  = useNavigate()
    const  { signIn } = useSelector((state) => state.loader);


  return (
    <Modal
    className=" border px-3 py-4 shadow-lg"
    onClose={() => dispatch(setSignin(false))}
    show={signIn}
    >
    <Modal.Slot name='body'>
        <div className='w-full text-2xl text-dark font-bold text-center flex flex-col gap-3'>
            Please Sign In first
            <button onClick={()=>{
      navigate('/login/email');
      dispatch(setSignin(false))
            }} className='_btnTest w-[200px] flex items-center justify-center m-auto'>
                Sign In
            </button>
        </div>

    </Modal.Slot>
    </Modal>
  )
}
