import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import Breadcrumbs from "../../common/Breadcrumbs";
import DefaultLayout from "../../common/DefaultLayout";
import book from "../../assets/images/book3c_2048x.png";
import impacticon from "../../assets/images/Group 3167.png";
import * as routes from "../../routePaths";
import { useLocation, useNavigate } from "react-router-dom";
import { getRewviews, setProductReview } from "../../services/product";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const MyProductReview = (props) => {
  const getReviewsHandler = async () => {
    try {
      const { customer } = await getRewviews();
      console.log(customer);
      setreviews(customer.reviews);
    } catch (err) {
      console.log(err);
    }
  };
  const state = useLocation();
  useEffect(() => {
    getReviewsHandler();
  }, []);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [reviews, setreviews] = useState(null);
  const [ratingState, setRatingState] = useState({
    rating: 0,
    quality: 0,
    price: 0,
    value: 0,
    input: "",
    name: "",
    title: "",
  });

  const handleChange = (name, value) => {
    setRatingState({ ...ratingState, [name]: value });
  };

  const handleClick = async () => {
    const { rating, quality, value, price, input, name, title } = ratingState;
  };

  return (
    <DefaultLayout>
      <div className="container products-review-wrapper">
        <div className="row">
          <div className="col-12">
            <Breadcrumbs
              paths={[
                { url: routes.homepage, text: "Home" },
                { url: routes.productsPage, text: "Products " },
                { url: routes.myReviewProduct, text: "My Reviews" },
              ]}
            />
            <div className="border-bottom">
              <div>
                <h1 className="main-heading">My Reviews</h1>
                <p className="sec-desc">
                  Review for a chance to win a £500 shopping spree. The more
                  reviews, the better the odds <a>Terms & Conditions</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row flex w-full  max-sm:flex-col max-sm:w-full justify-between ">
          <div className="w-[75%] max-sm:w-full">
            <div className="border-bottom">
              <div className="mt-4">
                <p className="review-heading">
                  My Product Reviews{" "}
                  <span>({reviews?.items?.length} reviews)</span>
                </p>
              </div>
              {reviews?.items.map((item, index) => {
                return (
                  <>
                    <ProductReview key={index} item={item} />
                    <hr />
                  </>
                );
              })}
            </div>
          </div>
          <div className="w[1/4] mt-2 max-sm:w-full max-sm:mt-0">
            <div className="mt-4">
              <p className="impact-heading">Your Impact</p>
            </div>
            <div className="impact-wrapper ">
              <div className="impact-detail">
                <div>
                  <img src={impacticon} alt="..." />
                </div>
                <div className="d-flex align-items-center">
                  <p className="impacts-number mr-2">2</p>
                  <p>review(s) need stars</p>
                </div>
              </div>
              <div className="impact-detail">
                <div>
                  <img src={impacticon} alt="..." />
                </div>
                <div className="d-flex align-items-center">
                  <p className="impacts-number mr-2">2</p>
                  <p>review(s) need stars</p>
                </div>
              </div>
              <div className="impact-detail">
                <div>
                  <img src={impacticon} alt="..." />
                </div>
                <div className="d-flex align-items-center">
                  <p className="impacts-number mr-2">2</p>
                  <p>review(s) need stars</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <p className="sec-desc">
              * Multiple entries will increase your chances of winning. This
              promotion is only applicable to customers with an account on
              AZoom.co.uk. Promotion starts on Friday 20. August 2021 and ends
              on Sunday 19. September 2021. See <a>Terms & Conditions</a> for
              details.
            </p>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
export default MyProductReview;

export function ProductReview({ item }) {
  console.log(item)
  return (
    <div className="w-full flex  max-sm:flex-col ">
      <div className="w-1/6 h-[150px] flex items-center  flex-col max-sm:w-full max-sm:flex-row justify-end">
        <img className="h-[70%]  " src={item?.product.image?.url} alt="..." />
        <span className="max-sm:w-1/2">{item?.product?.name}</span>
      </div>
      <div className=" w-full items-start ml-2 flex flex-col justify-between">
        <p className="text-xl text-start w-full mt-2 font-semibold text-dark">
          {item?.text}
        </p>
        <div className="w-full flex gap-5">
          <div className="flex flex-col font-[500]">
            {item?.ratings_breakdown.map((item1, index) => {

              return <div className="flex w-1/4 text-[#797979]  min-w-[200px] justify-between">
                {item1?.name}
                <Stars key={index} rev={item1?.value} />
              </div>;
            })}
          </div>
          <div className="flex-1 ">
            <p>
              {item?.summary}
            </p>
            <span className="flex gap-3">
              <p>
                Review Date :
              </p>
              <p>
                {item?.created_at}
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Stars({rev}) {
  console.log(rev)
  return (
    <ReactStars
      count={5}
      size={15}
      value={parseFloat(rev)}
      edit={false}
      isHalf={true}
      emptyIcon={<i className="far fa-star  text-dark " />}
      halfIcon={<i className="fa fa-star-half-alt" />}
      filledIcon={<i className="fa fa-star" />}
      activeColor="#EE5F3B"
      color="#EE5F3B"
    />
  );
}
