import React , {useState , useEffect} from 'react';
import ReactStars from 'react-rating-stars-component';
import favicon_mobile from '../../assets/images/button_favorite-mobile.png';
import viewicon_mobile from '../../assets/images/button_quickview-mobile.png';
import Outlet from '../../assets/images/outlet.png';
import { useSelector } from 'react-redux';
import arrow from '../../assets/images/arrow_right-long-white.png';
import "./style.scss"
import { calculateRatings, calculateAvg } from '../../utils/index';
import AddToCart from '../../common/AddToCart';
import AddFavourite from '../MyFavourites/AddFavourite';
import { Link } from 'react-router-dom';
import ProductCard from '../Homepage/productCard';
import { Bars } from 'react-loader-spinner';
import { set } from 'lodash';

export default function AllProducts({routePath, getData , grid = true , sort}) {
  const { products , loading } = useSelector(state => state?.products);
  const items = products?.items;
const [itemState, setItemState] = useState(items)
  useEffect(() => {
    
      if (items)
      {

        if(sort){
          console.log(sort)
          console.log(items)
          const reversedArray = items.slice().reverse();
          setItemState([...reversedArray])
        }
        else{
          setItemState([...items])
    
        }
      }
    
    return () => {
      
    }
  }, [sort , items])
  
  return (
    <div className="products-wrapper">
      {
        loading ? <div className='w-full min-h-[60vh] flex items-center justify-center '>
           <Bars
                height="100"
                width="100"
                radius="9"
                color="#53acaa"
                ariaLabel="three-dots-loading"
                wrapperStyle
              />
        </div> :
        <>
         {grid ? <div className="row  gap-3 product-card">
    
    {itemState && itemState?.map((item, key) => {
      let reviewPoints = calculateAvg(
        calculateRatings(item.reviews?.items),
        item.reviews?.items.length
      ).toFixed(1);
      return (
        <ProductCard
        key={key}
        className="searchTest "
          product={item}
          // isModalOpen={isModalOpen}
          // setModalOpen={setModalOpen}
          getData={getData}
          routePath={routePath}
        />
      );
    })}

      <div className="banner-div outlet-banner test">
        <img
          src={Outlet}
          className={
            'main-img'
          }
          alt=""
        />

        <span className="outlet-text text-lg font-bold">
         Outlet
        <p className=" text-xs font-[500]">
          Deep discounts on overstock and discontinued products.
        </p>
        </span>
        <img src={arrow} className="arrow" alt="" />
      </div>
  </div> : 
    <div className="row product-card flex flex-col gap-5">
      {itemState?.map((item, key) => {
        let reviewPoints = calculateAvg(
          calculateRatings(item.reviews?.items),
          item.reviews?.items.length
        ).toFixed(1);
        return (   
          <div style={{display: "flex" , flexDirection : "row"}} className="card w-full " key={key}>
             <div className='img_div w-1/5 min-w-[160px] ' >
            <img
              src={item.image.url}
              className="h-full w-full"
              alt="product-img"
            />
            <img
              className="favicon-mobile"
              src={favicon_mobile}
              alt="favicon-mobile"
            />
            <img
              className="viewicon-mobile"
              src={viewicon_mobile}
              alt="view-icon"
            />
            <div className="product-action">
              <AddToCart
                item={item}
                classNames="secondary_btn"
                variation={item.variants?.length > 0 ? true : false}
              />
              <button
                className="ternary_btn"
                onClick={() => getData(true, item)}
              >
                Quick view
              </button>
            </div>
          </div>
          <div className="pl-3 pt-3 card-desc items-start flex-1 ">
            <p className="product_name ">{item.name}</p>
            { <p className="writer">
      {item.azoom_product_specifications.publisher !== "" ? "By " + item.azoom_product_specifications.publisher : "" }
      
    </p>}

            <h3 className="price">
              £{item.price_range.minimum_price.final_price.value}
            </h3>
            <div className="rating-stars rating-stars-wrapper">
              <ReactStars
                count={5}
                size={15}
                value={parseFloat(reviewPoints)}
                edit={false}
                isHalf={true}
                emptyIcon={<i className="far fa-star" />}
                halfIcon={<i className="fa fa-star-half-alt" />}
                filledIcon={<i className="fa fa-star" />}
                activeColor="#000000"
                color="black"
              />
              <span className="products-rating-avg">{reviewPoints}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center pr-2">
              <pre>FREE DELIVERY</pre>
              <AddFavourite product={item} />
            </div>
          </div>
          </div>
      
        );
      })}

      </div>
  }
        </>
      }
    
    </div>
  );
}
