import React from "react";

const CancelOrder = (props) => (
  <>
    <h1>Returns & Cancellations Form</h1>
    <br />
    <p>
      You can easily request cancellations and returns through{" "}
      <span>
        <u>
          <b>My Orders.</b>
        </u>
      </span>{" "}
      Our self-service tool will guide you through the return and cancellation
      process.
      <br />
      <br />
      If you do not wish to use{" "}
      <span>
        <u>
          <b>My Orders.</b>
        </u>
      </span>{" "}
      to cancel or return an order, you may submit your request by contacting us
      or by using the Cancellation Form below.
      <br />
      <br />
      The address in the form below is not the delivery address for your return.
      We will advise you of the return address once we receive your request.
      <br />
      <br />
      To:
      <br />
      <br />
      Fairgate house
      <br />
      <br />
      10 Brookview Close
      <br />
      <br />
      Birmingham <br />
      <br />
      West midlands <br />
      <br />
      England
      <br />
      <br />
      B19 2UB
      <br />
      <br />
      E-Mail: service@azooms.co.uk, Tel.: +44 7658765876
      <br />
      <br />
      I/We hereby give notice that I/We cancel my/our contract of sale of the
      following goods /for the supply of the following service:
      <br />
      <br />
      Ordered on:
      <br />
      <br />
      Received on:
      <br />
      <br />
      Name of consumer:
      <br />
      <br />
      Address of consumer:
      <br />
      <br />
      Signature of consumer(s) (only if this form is notified on paper):
      <br />
      <br />
      Date:
      <br />
      <br />
    </p>
  </>
);
export default CancelOrder;
