import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import "./assets/scss/style.scss";
import "../node_modules/@splidejs/splide/dist/css/splide.min.css";
import Routing from "./pages/Routes";
import {
  customerInfoQuery,
  getCustomerInfo,
  getStripeKey,
} from "./services/auth";
import {
  addCustomer,
  setLogedIn,
  setPreviousRoute,
  setRewardPoints,
} from "./redux/customers/customerSlice"; //TODO abosulite path
import { useDispatch } from "react-redux";
import {
  createEmptyCart,
  getCustomerCart,
  getEmptyCardItems,
  getRewardPts,
  gqlDataForCart,
  mergeCarts,
} from "./services/cart";
import {
  addShippingAddressToCart,
  setCart,
  setPaypalKey,
  setStrieKey,
} from "./redux/cart/CartSlice";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useSelector } from "react-redux";
import Loader from "./common/Loader/Loader";
import SignInModal from "./common/SignInModal";
import { ReactComponent as Cheked } from "./assets/images/icon_success-large.svg";
import axios from "axios";
import { getPaypalSecret } from "./services/payment";
import { setCustomerAddress } from "./services/address";
function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, signIn } = useSelector((state) => state.loader);
  const { id } = useSelector((state) => state.cart);
  const { customer } = useSelector((state) => state.customer);
  const [stripeKey, setstripeKey] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getKet = async () => {
    try {
      const { stripe_get_key } = await getStripeKey();
      const { paypal_get_key } = await getPaypalSecret();
      dispatch(setPaypalKey(paypal_get_key));
      dispatch(setStrieKey(stripe_get_key));
    } catch (e) {
      console.log(e);
    }
  };
  const initialOptions = useCallback(() => {
    return {
      "client-id": stripeKey,
      currency: "GBP",
    };
  }, [stripeKey]);
  useEffect(() => {
    getKet();
  }, []);

  const getRewardPtsFetching = async () => {
    try {
      const ds = await getRewardPts();
      dispatch(setRewardPoints(ds.customer.mstRewardPointsBalance));
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const getCustomer = async () => {
      try {
        const d = localStorage.getItem("token");
        const cardIdTokken = localStorage.getItem("EmptyCardIdTokken");

        if (d && customer) {
          getRewardPtsFetching();
        } else {
          const { data } = await axios({
            url: "/graphql",
            method: "POST",
            data: {
              query: customerInfoQuery,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${d}`,
            },
          });
          const { customer } = data.data;
          if (customer) {
            dispatch(addCustomer(customer));
            dispatch(setLogedIn(true));
            const { data } = await axios({
              url: "/graphql",
              method: "POST",
              data: {
                query: gqlDataForCart,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${d}`,
              },
            });

            const { customerCart } = data.data;
            console.log(customerCart);
            // if  id  exist  merge carts
            if (id !== null && id !== customerCart.id) {
              try {
                const { mergeCartss } = await mergeCarts(id, customerCart.id);
                dispatch(setCart(mergeCartss));
                dispatch(
                  addShippingAddressToCart(mergeCartss.shipping_addresses)
                );
              } catch (err) {}
            } else {
              dispatch(setCart(customerCart));
              dispatch(
                addShippingAddressToCart(customerCart.shipping_addresses)
              );
            }
          } else {
            if (cardIdTokken) {
              try {
                const test = await getEmptyCardItems(cardIdTokken);
                dispatch(
                  addShippingAddressToCart(test.cart.shipping_addresses)
                );
                dispatch(
                  addCustomer({
                    email: test.cart.email,
                  })
                );
                dispatch(
                  setCart({
                    id: cardIdTokken,
                    items: test.cart.items,
                  })
                );
              } catch (e) {
                console.log(e);
              }
            } else {
              const data = await createEmptyCart();
              dispatch(
                setCart({
                  id: data.createEmptyCart,
                  items: [],
                })
              );

              localStorage.setItem("EmptyCardIdTokken", data.createEmptyCart);
            }
          }
        }
      } catch (err) {}
    };

    getCustomer();
  }, [dispatch, navigate]);

  return (
    <div id="App">
      {loading && ["productDetail", ""].includes(pathname.split("/")?.[1]) && (
        <Loader />
      )}
      {<SignInModal />}
      <div id={"added"} className="added">
        <Cheked />
        Added to My Favourites
      </div>

      <div className="app">
        <Routing />
      </div>
    </div>
  );
}

export default App;
