import React, { useEffect } from 'react'
import './Loader.scss'
import Logo from '../Logo'
export default function Loader() {
    var windowWidth = window.innerWidth;
    useEffect(() => {

        document.body.style.overflow = 'hidden'
        // scroll to top on mount
        return () => {
            document.body.style.overflow = 'unset'
        }
    }, [])

  return (
    <div className='loader'>
        <Logo scale={windowWidth > 500 ? 5 : 2} logoType="logo" />
    </div>
  )
}
