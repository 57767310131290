import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { setPreviousRoute } from "../../redux/customers/customerSlice";
const AuthenticatedRoute = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false); 
  const { logedIn } = useSelector((state) => state.customer);
  const { customer } = useSelector((state) => state.customer);

    const { pathname } = useLocation();
    const checkUserToken = () => {
        const userToken = localStorage.getItem('token');
        if (!logedIn && !userToken) {
            setIsLoggedIn(false);
            dispatch(setPreviousRoute(pathname));
            console.log("not logged in");
            console.log(customer);
            return navigate('/login/email', { replace: true });
        }
        setIsLoggedIn(true);
    }
    useEffect(() => {
            checkUserToken();
        }, [isLoggedIn]);
    return (
        <React.Fragment>
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default AuthenticatedRoute;