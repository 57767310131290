import React from 'react';
import DropDown from '../../assets/images/dropdown.png';
import ReactPaginate from 'react-paginate';
import {
  updateCurrentPage,
  updatePageCount,
} from '../../redux/products/productSlice';
import { useDispatch, useSelector } from 'react-redux';

export const ProductsPagination = () => {
  const dispatch = useDispatch();

  const onPageChange = event => {
    console.log(event);
    dispatch(updateCurrentPage(event.selected + 1));
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  const onPageSizeChange = event => {
    // dispatch(updatePageCount(event));
  };

  
  const { pageSize, products } = useSelector(state => state.products);

  const total_count = products?.total_count | 1;

  return (
    <div className="flex">
      <div className="d-flex pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={onPageChange}
          pageRangeDisplayed={3}
          pageCount={Math.ceil(total_count / pageSize)}
          previousLabel="<"
          nextClassName="arrow-div"
          previousLinkClassName="arrow-div"
          renderOnZeroPageCount={null}
          className="pagination d-flex gap-2"
          activeClassName="active-no"
          activeLinkClassName="active-no"
        />
      </div>
      <div className="items-per-page-wrapper">
        <form>
          <div className="btn_div">
            <div className="dropdown sortingDropdown mr-3">
              <button
                className="dropdowns dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img className="rotate180deg" src={DropDown} />
                <p>Items Per Page</p>
                <small>{pageSize}</small>
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => onPageSizeChange(30)}
                  >
                    20
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => onPageSizeChange(30)}
                  >
                    30
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => onPageSizeChange(40)}
                  >
                    40
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
