import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import * as routes from '../routePaths';
import arrowRightLong from '../assets/images/icons/arrow_right-long-white.svg';
import hamburger from '../assets/images/icons/icon_hamburger-menu.svg';
import searchIcon from '../assets/images/icon_search.svg';
import iconFvrt from '../assets/images/icons/icon_favorite.svg';
import cartIcon from '../assets/images/icons/icon_basket.svg';
import usericon from '../assets/images/icons/icon_user.svg';
import Sidebar from './Sidebar';
import Logo from './Logo';
import MegaMenu from './MegaMenu';
import BasketSummary from './BasketSummary';
import MobileMenuSidebar from './MobileMenuSidebar';
import { useSelector } from 'react-redux';
import { getCategoriesInfo } from '../services/category';
import { toast } from 'react-toastify';
import {
  addCategories,
  addOutletAndDiscountCategories,
} from '../redux/products/productSlice';
import { useDispatch } from 'react-redux';
import { setCartSummaryOpen } from '../redux/cart/CartSlice';
import SearchInput from './SearchInput';
import {
  addProductCategory,
  addSubHeading,
  setSelectedFiltersQuery,
} from '../redux/products/productSlice';
import { saveAs } from 'file-saver';
import {data} from './categories.js';
import { getSliderAndRecommendedProducts } from '../services/product';
import { getSliderOffers } from '../services/home';

const Header = () => {
  let navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [isSideNavOpen, setSideNavOpen] = useState(false);
  const [isMobileSideOpen, setMobileSideOpen] = useState(false);
  const { customer } = useSelector(state => state.customer);
  const [subTotal, setSubTotal] = useState(0);

  const { items, isCartSummaryOpen } = useSelector(state => state.cart);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [sliderOffers, setSliderOffers] = useState([]);
  const { logedIn } = useSelector((state) => state.customer);
  const tokken = logedIn;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const calculateItemPrice = useCallback(() => {
    let price = items.reduce(
      (sum, currentVal) =>
        sum +
        (currentVal.product?.price_range?.minimum_price?.final_price?.value * currentVal.quantity),
      0
    );

    setSubTotal(price.toFixed(2));
  }, [items]);

  useEffect(() => {
    calculateItemPrice();
  }, [calculateItemPrice ]);
  const dispatch = useDispatch();
  useEffect(() => {
    getOffers();
  }, []);

  const getOffers = async () => {
    try {
      const { megamenu_offer_slider } = await getSliderOffers();
      setSliderOffers(megamenu_offer_slider.items);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const simplifyName = (name , )=>{

    return name.replaceAll(/'/g, " ").toLowerCase().replaceAll(" ", "-") ;
  }
  useEffect(() => {
    const getCategory = async () => {
      try {
        //his blog of code is to get the categories and store them  in a file 
        // const {categories} = await getCategoriesInfo();
        // const dataString = `export const  data = ${JSON.stringify(categories)};`;

        // const blob = new Blob([dataString], { type: 'text/plain;charset=utf-8' });
        
        // saveAs(blob, 'categories.js');
        let  categories = data;
        const outletandDiscoutCategories = categories.items.filter(category => {
          if (category.name === 'Outlet' || category.name === 'Discount') {
            return category.id;
          }
        });
        dispatch(addOutletAndDiscountCategories(outletandDiscoutCategories));
        categories = categories.items.filter(
          category => category.name !== 'Outlet' && category.name !== 'Discount'
        );

        setCategories(categories);
        dispatch(addCategories(categories));
      } catch (err) {
        toast.error(err.message);
      }
    };
    getCategory();
  }, []);

  const setCategoryId = useCallback(
    id => {
      dispatch(
        setSelectedFiltersQuery({
          key: 'category_id',
          value: {
            eq: id,
          },
        })
      );
    },
    [dispatch]
  );
  
  document.addEventListener("DOMContentLoaded", function(){
    window.addEventListener('scroll', function() {
        if (window.scrollY > 25) {
          document.getElementById('navbar_top').classList.add('navpos');
          if (windowWidth< 600)
          {
            document.getElementById('header').style.display ="none";

          }
          // add padding top to show content behind navbar
        } else {
          document.getElementById('navbar_top').classList.remove('navpos');
           // remove padding top from body
           if (windowWidth< 600)
           {
             document.getElementById('header').style.display ="flex";
           }

          document.body.style.paddingTop = '0';
        } 
    });
  }); 
  const addCategory = useCallback(
    async category => {
      if (category?.name === "Sale")
      {
        dispatch(addProductCategory(category));
        setCategoryId(category.id);
        return navigate('/products/sale');
      }
      try {
        dispatch(addProductCategory(category));
        setCategoryId(category.id);
        navigate(`/products/${simplifyName(category.name)}`);
      } catch (err) {}
    },
    [dispatch, navigate, setCategoryId]
  );

  return (
    <div>
      <Sidebar
        pageWrapId={'header-wrapper'}
        outerContainerId={'App'}
        isOpen={isSideNavOpen}
        setSideNavOpen={setSideNavOpen}
      />
      <MobileMenuSidebar
        pageWrapId={'header-wrapper'}
        outerContainerId={'App'}
        isOpen={isMobileSideOpen}
        setSideNavOpen={setMobileSideOpen}
        closeFunc={()=>{
          setSideNavOpen(false)}}
      />
      <BasketSummary
        pageWrapId={''}
        outerContainerId={'App'}
        isOpen={isCartSummaryOpen}
        setSideNavOpen={setCartSummaryOpen}
        subTotal={subTotal}
      />
      <div id="header-wrapper" className="header-wrapper">
        <div className="container-fluid ">
          <div className="row upper-header ">
            <div className="col-12 ">
              <div className="container flex-container">
                <div className="flex block items-center ">
                  <div className="header-text">
                    <p className="text1 text-xl">Best Sellers Essentials</p>
                  </div>
                  <div className="flex  items-center shop-now-text">
                    <p className="text2">SHOP NOW</p>
                    <img className="arrow-right" src={arrowRightLong} alt="" />
                  </div>
                </div>
                <div>
                  <p className="font-[500] text-white mb-0">Free Delivery & returns to most of UK</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div  id="navbar_top" className="container-fluid ">
          <div className="row border-bottom">
            {/* <div className="col-12 "> */}
              <div className="container">
                <div id="header" className="header">
                  <div id="logo-div" className="logo-div">
                    <button
                      className="flex menu-button d-block d-md-flex items-center"
                      onClick={() => setSideNavOpen(true)}
                    >
                      <div className='position-relative cont'>
                      <img className="header-icon" src={hamburger} alt="" />

                      <div className="notification  "></div>
                      </div>
                      <p className="text">Menu</p>
                    </button>
                    <Link to={routes.homepage} className="flex">
                      <Logo logoType="headerLogo" />
                    </Link>
                  </div>

                  <SearchInput id="search"/>
                  
                  <div className="icons flex items-center">
                      <img
                        src={iconFvrt}
                        className="acc-icons heart"
                        alt=""
                        onClick={() =>
                          navigate(`${routes.myFavourites}`)
                        }
                      />
                    {/* <div> */}
                      <Link
                        to={!tokken ? routes.login : routes.orders}
                        className="flex items-center "
                      >
                        <img src={usericon} className="acc-icons" alt="" />
                        <div className="acc-text mt-[5px] mobile-hidden">{!tokken ? "LOGIN" :"MY ACCOUNT"}</div>
                      </Link>
                    {/* </div> */}
                    <div className="flex">
                      <div
                        className="basket-item"
                        onClick={() => dispatch(setCartSummaryOpen(true))}
                      >
                        <img src={cartIcon} className="acc-icons" alt="" />
                       {items?.length !== 0 &&  <span className="quantity">{'' || items?.length}</span>}
                      </div>
                   { parseInt(subTotal)!== 0 &&   <p  onClick={() => dispatch(setCartSummaryOpen(true))} className={customer ? 'acc-text-basket cursor-pointer' : 'd-none'}>
                        £{subTotal}
                      </p>}
                    </div>
                  </div>
                </div>
                  <SearchInput  className="flex search-input-resp items-center " id="search7"/>
              </div>
            {/* </div> */}
          </div>
        </div>
        <div className="container-fluid">
          <div className="row center bg nav-small">
            <nav className="navbar">
              {categories.map((category, i) => (
                <div className="dropdown" key={category.id}>
                  <a
                    className={`dropbtn navbar-brand cursor-pointer ${
                      category.name === 'Sale' ? 'active' : 'nav-item'
                    }`}
                    alt={category.name}
                    onClick={() => {addCategory(category) }}
                  >
                    {category.name}
                  </a>
                  { category.name !== 'Sale' &&  <ul className="dropdown-content">
                    <MegaMenu i={i} category={category} sliderOffers={sliderOffers}/>
                  </ul>}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
