import React, { useState, useEffect, useCallback } from "react";
import arrow from "../../assets/images/arrow_right-long-white.png";
import { useDispatch, useSelector } from "react-redux";
import { getFiltersInfo } from "../../services/filter";
import {data} from "../../common/categories.js";
import {
  addFilters,
  setSelectedFilters,
  setSelectedFiltersQuery,
  removeSelectedFilter,
  removeSelectedFilterQuery,
  addProductCategory,
  addSubHeading,
} from "../../redux/products/productSlice";
import Checkbox from "../../common/Form/CheckBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Bars } from "react-loader-spinner";
import DropDown from "../../assets/images/dropdown.png";

import { toast } from "react-toastify";
import { filterOptions } from "../../constants/filter";
import { Data } from "../../common/Modal/ProductModal";
import { destroySDKScript } from "@paypal/react-paypal-js";
import Input from "../../common/Form/Input";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function Filters() {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState({});
  const [more, setmore] = useState(false);
  const [price, setprice] = useState("");
  const [filtersss, setfiltersss] = useState([]);
  const [range, setrange] = useState({
    min: 0,
    max: 1000,
  });

  const { filters, loading, selectedFilters, selectedFiltersQuery } =
    useSelector((state) => state?.products);

  useEffect(() => {
    // make price in filter array second

    const getCategory = async () => {
      try {
        let { products } = await getFiltersInfo({
          category_id: selectedFiltersQuery.category_id,
        });
        dispatch(addFilters(products.aggregations));
        let toggleObj = {};
        products.aggregations.forEach((filter) => {
          toggleObj[filter.attribute_code] = true;
        });
        setToggle(toggleObj);
      } catch (err) {
        toast.error(err.message);
      }
    };
    getCategory();
  }, [dispatch]);

  useEffect(() => {
    if (filters) {
      var test = filters;
      var newList = test?.filter((item) =>
        ["category_uid"].includes(item.attribute_code)
      );
      var newList1 = test?.filter((item) =>
        ["price", "new", "author", "sale"].includes(item.attribute_code)
      );
      var newList2 = test?.filter(
        (item) =>
          !["category_uid", "price", "new", "author", "sale"].includes(
            item.attribute_code
          )
      );
      var list = newList.concat(newList1).concat(newList2);

      setfiltersss([...list]);
    }
  }, [filters]);
  const isFilterApplied = (filterName, label) => {
    if (
      selectedFilters[filterName] &&
      selectedFilters[filterName]?.label === label
    ) {
      return true;
    }
    return false;
  };

  const getFilteredProducts = async (filter, children) => {
    let { value } = children;
    if (
      selectedFilters[filter.attribute_code] &&
      selectedFilters[filter.attribute_code].label === children.label
    ) {
      dispatch(removeSelectedFilter({ key: filter.attribute_code }));
    } else {
      console.log("children", filter.attribute_code ,children.label);
      dispatch(
        setSelectedFilters({
          [filter.attribute_code]: {
            label: children.label,
          },
        })
      );
    }
    let queryFilter = selectedFiltersQuery[filter.attribute_code];
    switch (filter.attribute_code) {
      case filterOptions.price:
        value = value.split("_");
        if (
          queryFilter &&
          queryFilter?.from === value[1] &&
          queryFilter?.to === value[0]
        ) {
          dispatch(removeSelectedFilterQuery({ key: filter.attribute_code }));
        } else {
          dispatch(
            setSelectedFiltersQuery({
              key: filter.attribute_code,
              value: {
                from: value[0],
                to: value[1],
              },
            })
          );
        }
        break;
      case filterOptions.category:
        if (queryFilter && queryFilter?.in & ([0] === value)) {
          dispatch(removeSelectedFilterQuery({ key: filter.attribute_code }));
        } else {
          dispatch(
            setSelectedFiltersQuery({
              key: filter.attribute_code,
              value: {
                eq: [value],
              },
            })
          );
        }
        break;
      default:
        if (queryFilter && queryFilter?.eq === value) {
          dispatch(removeSelectedFilterQuery({ key: filter.attribute_code }));
        } else {
          dispatch(
            setSelectedFiltersQuery({
              key:
                filter.attribute_code === "category_uid"
                  ? "category_id"
                  : filter.attribute_code,
              value: {
                eq: value,
              },
            })
          );
        }
        break;
    }
  };
  const aggregations = filtersss;
  const changeToggle = (attribute_code) => {
    setToggle({ ...toggle, [attribute_code]: !toggle[attribute_code] });
  };

  return (
    <div className="filters-wrapper">
      <div className="inp_div">
        <div className="flex">
          <label className="switch">
            <input
             checked={isFilterApplied("sale", "1")}
             onChange={(e) => {
               getFilteredProducts(aggregations.find((x)=>x.attribute_code === "sale"), {
                 label:  "1" ,
                 value:"1" ,
               });
             }}
            type="checkbox" id="togBtn"></input>
            <div className="slider round"></div>
          </label>
          <p className="delivery-text mt-1">Sales and Offers</p>
        </div>
        <div className="search-input-wrapper mt-2 mb-2">
          <form className="full-width">
            <div className="box-search">
              <input type="text"></input>
              <span>Postcode</span>
            </div>
            
          </form>
          <div className="box">
            <img src={arrow} alt="arrow" />
          </div>
        </div>
        <div className="loading-box">
          {loading && (
            <span className=" w-full flex justify-between">
              Filtering the Products{" "}
              <Bars
                height="20"
                width="20"
                radius="9"
                color="#53acaa"
                ariaLabel="three-dots-loading"
                wrapperStyle
                
              />
            </span>
          )}
        </div>
        <p className="mt-2 font-weight-bold mb-0">
          <span>
            <FontAwesomeIcon icon={faFilter} style={{ color: "#53acaa" }} />{" "}
          </span>
          Filters Applied: {Object.keys(selectedFilters).length}
        </p>
        {filtersss
          ?.slice(0, !more ? 5 : aggregations.length - 1)
          ?.map((filter, key) => {
            return (
              <div className="filters" key={key}>
                <FilterData
                  opend={
                    ["Price", "Category", "New", "Author", "Sale"].includes(
                      filter.label
                    )
                      ? true
                      : false
                  }
                  range={range}
                  setrange={setrange}
                  title={filter.label}
                  children={<div>destroySDKScr';l'ipt</div>}
                  filterOpt={filter.options}
                  filter={filter}
                  isFilterApplied={isFilterApplied}
                  getFilteredProducts={getFilteredProducts}
                  selectedFilters={selectedFilters}
                  filters={filters}
                />
              </div>
            );
          })}
        <p
          onClick={() => {
            setmore(!more);
          }}
          className="show_more_filters cursor-pointer"
        >
          {!more ? "See More Filters" : "See Less Filters"}
        </p>
      </div>
    </div>
  );
}

export function FilterData({
  title,
  children = "",
  opend = false,
  filter = null,
  filterOpt = [],
  isFilterApplied,
  getFilteredProducts,
  range,
  setrange,
  selectedFilters,
  filters,
}) {
  const [isFilter, setisFilter] = useState(false);
  const genratePrice = (label, i) => {
    if (label.includes("-") === -1 && i === 0) {
      return "Under £" + label;
    } else if (!label.includes("-") && i === filterOpt.length - 1) {
      return "Above £" + label.split("-")[0];
    } else {
      var d;
      return "£" + label.split("-")[0] + " to £" + label.split("-")[1];
    }
  };
  const [open, setopen] = useState(opend);
  useEffect(() => {
    if (selectedFilters[filter.attribute_code]) {
      setisFilter(true);
    } else setisFilter(false);
  }, [selectedFilters, filterOpt]);
  const prices = [
    {
      label: "Under £15",
      value: "0_15",
    },
    {
      label: "£20 - £30",
      value: "20_30",
    },
    {
      label: "£30 - £50",
      value: "30_50",
    },
    {
      label: "£50 - £100",
      value: "30_100",
    },
    {
      label: "100 & Above",
      value: "100_400",
    },
  ];
  return (
    <div className="w-full  border-b-2 border-danger">
      <div
        onClick={() => setopen(!open)}
        className="w-full  items-center flex justify-between  transition duration-150 ease-out md:ease-in overflow-hidden"
      >
        <span className="text-[#404041] font-semibold flex items-center">
          {title}{" "}
          {isFilter &&
            selectedFilters[filter.attribute_code]?.label !== "0" && (
              <div className="w-2 h-2 bg-tertiary rounded-full ml-2"></div>
            )}
        </span>
        <div>
          <img
            style={{ transform: open ? "rotate(180deg)" : "" }}
            className={"cursor-pointer "}
            onClick={() => setopen(!open)}
            src={DropDown}
            alt=""
          />
        </div>
      </div>
      <div
        className={
          (open ? "max-h-[300px] " : "max-h-[0px] ") +
          "transition duration-150 ease-out md:ease-in  overflow-y-scroll "
        }
      >
        {title === "Price" && (
          <div className="flex flex-col items-start">
            <div className="flex w-full gap-3 items-center my-3 ">
              <div className="flex-1">
                <Input
                  type="number"
                  placeholder="Min"
                  className="flex-1"
                  label="Min"
                  small={true}
                  value={range.min}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setrange({ ...range, min: 0 });
                    }
                    getFilteredProducts(filter, {
                      label:
                        (e.target.value ? e.target.value : 0) + "-" + range.max,
                      value:
                        (e.target.value ? e.target.value : 0) + "_" + range.max,
                    });
                  }}
                  onChange={(e) => {
                    setrange({ ...range, min: e.target.value });
                  }}
                />
              </div>
              <div className="w-1/2">
                <Input
                  type="number"
                  placeholder="Max"
                  className="w-1/2"
                  label="Max"
                  small={true}
                  value={range.max}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      setrange({ ...range, max: 1000 });
                    }
                    getFilteredProducts(filter, {
                      label:
                        range.min +
                        "-" +
                        (e.target.value ? e.target.value : 1000),
                      value:
                        range.min +
                        "_" +
                        (e.target.value ? e.target.value : 1000),
                    });
                  }}
                  onChange={(e) => {
                    setrange({ ...range, max: e.target.value });
                  }}
                />
              </div>
            </div>
            {prices.map((price, i) => {
              return (
                <div key={i} className="mt-1 w-full">
                  <Checkbox
                    color="primary"
                    size="sm"
                    className="flex gap-2 items-center"
                    label={price.label}
                    checked={isFilterApplied(
                      filter.attribute_code,
                      price.label
                    )}
                    onChange={() => {}}
                    onClick={() => getFilteredProducts(filter, price)}
                  />
                </div>
              );
            })}
          </div>
        )}
        {title === "New" && (
          <div className="mt-2">
            <Checkbox
              color="primary"
              size="sm"
              className="flex gap-2 items-center"
              label={"New"}
              checked={isFilterApplied(filter.attribute_code, "1")}
              onChange={() => {}}
              onClick={() =>
                getFilteredProducts(filter, {
                  label: "1",
                  value: "1",
                })
              }
            />
            <Checkbox
              color="primary"
              size="sm"
              className="flex gap-2 items-center"
              special={true}
              label={"Offers"}
              checked={isFilterApplied("offers", "1")}
              onChange={() => {}}
              onClick={() =>
                getFilteredProducts(
                  filters.find((f) => f.attribute_code === "sale"),
                  {
                    label: "1",
                    value: "1",
                  }
                )
              }
            />
          </div>
        )}
        {title === "Sale" && (
          <div className="flex mt-2">
            <div className="flex">
              <label className="switch">
                <input
                  checked={isFilterApplied(filter.attribute_code, "1")}
                  onChange={(e) => {
                    getFilteredProducts(filter, {
                      label:  "1" ,
                      value: "1",
                    });
                  }}
                  type="checkbox"
                  id="togBtn"
                ></input>
                <div className="slider round"></div>
              </label>
              {/* <p className="delivery-text mt-1">Sales and Offers</p> */}
            </div>
          </div>
        )}
        {title === "Category" && <Categories />}
        {title !== "New" &&
          title !== "Sale" &&
          title !== "Price" &&
          title !== "Category" &&
          filterOpt.map((children, key) => {
            return (
              <div key={key} className=" mt-2 ml-2">
                <div className="cursor-pointer">
                  <Checkbox
                    color="primary"
                    size="sm"
                    className="flex gap-2 items-center"
                    label={
                      title === "Price"
                        ? genratePrice(children.label, key)
                        : children.label
                    }
                    checked={isFilterApplied(
                      filter.attribute_code,
                      children.label
                    )}
                    onChange={() => {}}
                    onClick={() => getFilteredProducts(filter, children)}
                  />
                </div>
              </div>
            );
          })}
      </div>
      <hr />
    </div>
  );
}

export function Categories() {
 const location = useLocation()
const dispatch = useDispatch ();
const navigate = useNavigate();
  const [categories, setCategories] = useState(data.items);
  // get category and subcategory from the location
  const {cat , subcat  ,subSubCat} = useParams();
  useEffect(() => {
    setCategories(data.items);
    // decode the url cat and subcat
    var cate = cat;
    var subCate = subcat;
  if (cate)
  {
    const  tmp = categories.filter((c) => c?.children?.[0]?.url_path.split('/')[0] === cate);
    // // if (subCate)
    // // {
    // //   const tmp1 = tmp[0]?.children.filter((c) => c.name === subCate);
    // //   // tmp[0].children = tmp1;
    // // }
    const tmp2 = categories.filter((c) => c?.children?.[0]?.url_path.split('/')[0] !== cate);
    const tmp3 = tmp.concat(tmp2);

    setCategories([...tmp3]);
  }
}, [location]);

  // get the category and subcategory from the url
  const setCategoryId = useCallback(
    id => {
      
      dispatch(
        setSelectedFiltersQuery({
          key: 'category_id',
          value: {
            eq: id,
          },
        })
      );
    },
    [dispatch]
  );
  const simplifyName = (name , )=>{

    return name.replaceAll(/'/g, " ").toLowerCase().replaceAll(" ", "-") ;
  }
  const addCategory = useCallback(
    async category => {
      try {
        dispatch(addProductCategory(category));
        setCategoryId(category.id);
        navigate(`/products/${simplifyName(category.name)}`);
      } catch (err) {}
    },
    [dispatch, navigate, setCategoryId]
  );
  const addCategoryHead = useCallback(
    async (category, heading) => {
      try {
        setCategoryId(heading.id);
    
        dispatch(addProductCategory(category));
        dispatch(addSubHeading(heading));
        navigate(`/products/${simplifyName(category.name)}/${simplifyName(heading.name)}`);
      } catch (err) {}
    },
    [dispatch, navigate, setCategoryId]
  );
  const addCategorySubHead = useCallback(
    async (category, subHead, heading) => {
      try {
        setCategoryId(heading.id);
        dispatch(addProductCategory(category));
        dispatch(addSubHeading(heading));
        navigate(`/products/${simplifyName(category.name)}/${simplifyName(subHead.name)}/${simplifyName(heading.name)}`);
      } catch (err) {}
    },
    [dispatch, navigate, setCategoryId]
  );

  return (
    <div className="p-2  overflow-y-scroll">
      {categories.map((category, i) => {
        const subCategories = category?.children || [];
        const catUrl = simplifyName(category.name);

        return (
          <div  key={i} className="mt-1 w-full cursor-pointer ">
            <div  onClick={()=> addCategory(category) } className={"w-full"   + (cat === catUrl ? " text-[#ee5f3b] " : "")}>
              {category.name}

            </div>
            {cat && cat === catUrl && subCategories?.map((subCategory, j) => {
              const subSubCategory = subCategory?.children || [];
              const catSubUrl = simplifyName(subCategory.name);
              return <div  key={j} className={"ml-2 text-sm  my-2" + (subcat === catSubUrl ? " text-[#ee5f3b] " : "")}>
                <div className="w-full" onClick={()=>addCategoryHead(category , subCategory)}>
                  {subCategory.name} 

                </div>
                {
                  subcat && subcat === catSubUrl && subSubCategory?.map((subSubCategoryitem, x) => {
                    const catSubSubUrl = simplifyName(subSubCategoryitem.name);

                    return <div key={x} onClick={()=>addCategorySubHead(category ,subCategory ,subSubCategoryitem)} style={{color : (subSubCat === catSubSubUrl ? " #ee5f3b" : "#343a40") }} className={"ml-4 text-sm my-2"}>
                      {subSubCategoryitem.name}
                    </div>;
                  }
                  )
                }

                </div>;
            })}

            {}
          
          </div>
        );
      })}
    </div>
  );
}
