import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { Link, useNavigate } from "react-router-dom";
import * as routes from "../routePaths";
import visa from "../assets/images/logo_visa.png";
import maestro from "../assets/images/logo_maestro.png";
import amex from "../assets/images/logo_amex.png";
import barclays from "../assets/images/logo_barclays.png";
import applePay from "../assets/images/logo_apple-pay.png";
import klarna from "../assets/images/logo-klarna.png";
import clearPay from "../assets/images/logo_clearpay.png";
import trustPilot from "../assets/images/logo_trustpilot.png";
import dhl from "../assets/images/logo_dhl.png";
import ups from "../assets/images/logo_ups.png";
import panther from "../assets/images/logo_panther.png";
import xdp from "../assets/images/logo_xdp.png";
import dpd from "../assets/images/logo_dpd.png";
import bjs from "../assets/images/logo_bjs.png";
import parcel from "../assets/images/logo_parcel_force-worldwide.png";
import hermes from "../assets/images/logo_hermes.png";
import dx from "../assets/images/logo_dx.png";
import arrowxl from "../assets/images/logo_arrowxl.png";
import phone from "../assets/images/icons/icon_phone.svg";
import time from "../assets/images/icon_time.png";
import facebook from "../assets/images/icons/logo_facebook.svg";
import instagram from "../assets/images/icons/logo_instagram.svg";
import linkedin from "../assets/images/icons/logo_linkedin.svg";
import twitter from "../assets/images/icons/icon_twitter.svg";
import MoneyBackGuarantee from "./MoneyBackGuarantee";
import { set } from "lodash";

const Footer = ({ hasMoneyBack, hasOptionsPartners }) => {
  const COOKIE_KEY = "cookie-accepted";
  const navigate = useNavigate()

  const [acceptCookies, setacceptCookies] = React.useState(true);
  const handleAccept = () => {
    localStorage.setItem(COOKIE_KEY, "true");
    setacceptCookies(false);
  };

  const handleReject = () => {
    localStorage.setItem(COOKIE_KEY, "true");
    setacceptCookies(false);
  };
  useEffect(() => {
    // This effect will run once on mount to check if the cookie has been accepted before
    if (localStorage.getItem(COOKIE_KEY) === "true") {
      setacceptCookies(false);
    }
  }, []);

  return (
    <div className="footer-wrapper mt-5">
      {hasMoneyBack && <MoneyBackGuarantee />}
      <section className="container-fluid">
        {hasOptionsPartners && (
          <div className="row border-bottom mt-5">
            <div className="col-12">
              <div className="container footer-opts">
                <div className="row mb-5">
                  <div className="col-md-4 col-sm-12">
                    <p className="opts-text">Trusted Shopping</p>
                    <img src={trustPilot} className="trust-icon" alt="" />
                    <div className="rating-stars rating-stars-wrapper">
                      <ReactStars
                        size={20}
                        value={2.5}
                        count={5}
                        edit={false}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star" />}
                        activeColor="#00B67A"
                        color="#00B67A"
                      />
                      <span>4.6 out of 5.0</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 margin ">
                    <p className="opts-text brand-title">Payment Options</p>
                    <div className="flex flex-wrap">
                      <img src={visa} className="brand-icon" alt="" />
                      <img
                        src={maestro}
                        className="brand-icon pay-maestro"
                        alt=""
                      />
                      <img src={amex} className="brand-icon" alt="" />
                      <img src={barclays} className="brand-icon" alt="" />
                      <img
                        src={applePay}
                        className="brand-icon pay-maestro"
                        alt=""
                      />
                      <img src={klarna} className="brand-icon" alt="" />
                      <img src={clearPay} className="brand-icon" alt="" />
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 margin">
                    <p className="opts-text brand-title">Delivery Partners</p>
                    <div className="flex flex-wrap">
                      <img
                        src={dhl}
                        className="delvery-partners-icon dhl"
                        alt=""
                      />
                      <img
                        src={ups}
                        className="delvery-partners-icon ups"
                        alt=""
                      />
                      <img
                        src={panther}
                        className="delvery-partners-icon panther-xdp-dpd"
                        alt=""
                      />
                      <img
                        src={xdp}
                        className="delvery-partners-icon panther-xdp-dpd"
                        alt=""
                      />
                      <img
                        src={dpd}
                        className="delvery-partners-icon panther-xdp-dpd"
                        alt=""
                      />
                      <img
                        src={bjs}
                        className="delvery-partners-icon bjs"
                        alt=""
                      />
                      <img
                        src={parcel}
                        className="delvery-partners-icon parcel"
                        alt=""
                      />
                      <img
                        src={hermes}
                        className="delvery-partners-icon hermes"
                        alt=""
                      />
                      <img
                        src={dx}
                        className="delvery-partners-icon dx"
                        alt=""
                      />
                      <img
                        src={arrowxl}
                        className="delvery-partners-icon arrowxl"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row border-bottom">
          <div className="col-12">
            <div className="container">
              <div className="row mt-4 mb-4">
                <div className="col-md-4 col-sm-6 col-6">
                  <ul>
                    <li>
                      <b>About Us</b>
                    </li>
                    <li className="item">
                      <Link to={routes.aboutPage}>About azoom</Link>
                    </li>
                    <li className="item">
                      <Link to={routes.chooseUsPage}>Why us?</Link>
                    </li>
                    <li className="item">
                      <Link to={routes.expertisePage}>Our Expertise</Link>
                    </li>
                    <li className="item">
                      <Link to={routes.ideaPage}>Got an idea</Link>
                    </li>
                    <li className="item">
                      <Link to={"/help/recycling"}>Azoom Recycling</Link>
                    </li>
                    <li className="item">
                      <Link to={routes.contactPage}>Contact us</Link>
                    </li>

                    {/* <li className="item"><Link to={routes.homepage}>Gift cards</Link></li> */}
                  </ul>
                </div>
                <div className="col-md-4 col-sm-6 col-6">
                  <ul>
                    <li>
                      <b>Customer Services</b>
                    </li>
                    <li className="item">
                      <Link to={routes.orders}>My Orders</Link>
                    </li>
                    <li className="item">
                      <Link to={routes.orders}>My Account</Link>
                    </li>
                    <li className="item">
                      <Link to={routes.homepage}>Track My Order</Link>
                    </li>
                    <li className="item">
                      <Link to={routes.rewardPolicy}>Reward Policy</Link>
                    </li>
                    <li className="item">
                      <Link to={routes.returnCenter}>Delivery information</Link>
                    </li>

                    <li className="item">
                      <Link to={routes.returnPolicy}>Return Policy</Link>
                    </li>
                   
                  </ul>
                </div>
                <div className="col-md-4 col-sm-6">
                  <ul>
                    <li>
                      <b>Contact Us</b>
                    </li>
                    <li>
                      <button  onClick={()=>{
						navigate("/about/contact")
					  }}  className="btn action-btn service">
                        <i className="far fa-clock" /> Quick Service
                      </button>
                    </li>
                    <li className="call-us-btn-li">
                      <button  onClick={()=>{
						
						navigate("/about/contact")
					  }} className="btn action-btn call">
                        <i className="fa fa-phone-volume" />
                        Call Us
                      </button>
                    </li>
                    <li className="item support">
                      <b>Customer Service</b>
                    </li>
                    <li className="item support">Open, Closes 10.00 am</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-3  mt-4">
          <div className="row">
            <div className="col-12 flex">
              <div className="terms-border">
                <a href="/help/return-policy">Terms of use</a>
              </div>
              <div className="terms-border ml-2">
                <a href="/help/return-policy">Privacy Policy</a>
              </div>
              <div className="terms-border ml-2">
                <a href="/help/return-policy">Terms of Preferences</a>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-3 ">
          <div className="row mt-3">
            <div className="flex  items-center col-12">
              <Link target="_blank" to={"https://www.facebook.com/azoomuk"}>
                <img src={facebook} className="social-icon" alt="" />
              </Link>
              <Link target="_blank" to={"https://www.instagram.com/azoomuk/"}>
                <img src={instagram} className="social-icon" alt="" />
              </Link>
              {/* <Link to={routes.homepage}><img src={linkedin} className="social-icon" alt="" /></Link> */}
              {/* <Link to={routes.homepage}><img src={twitter} className="social-icon" alt="" /></Link> */}
            </div>
          </div>
        </div>
        <div className="container mt-3 ">
          <div className="row mt-3">
            <div className="col-12 footer-text">
              <p className="mb-4">
                © Copyright 2021 by AZoom LTD, 10 Brookview Close, Birmingham, west midlands B19 2UB
              </p>
              <p className="mb-4">
                <b>AZoom </b>is an online platform that specializes in the sale
                and distribution of Islamic literature and household items. It
                is a family-run business that is deeply committed to serving the
                community in which it was raised and catering to the needs of
                the younger generation. Our success has always been rooted in
                our exceptional ability to understand and respond to the needs
                and desires of our customers. With over 20 years of experience
                in the industry, we have forged strong partnerships with
                overseas suppliers, allowing us to source some of the most
                sought-after literature in the community. Our mission is to make
                Islamic materials accessible to all, regardless of status or
                demand, and to ensure a fair price that enables our suppliers to
                continue creating more books for their community.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      {acceptCookies ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 cookies-div">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="text-div">
                      <p className="text">
                        This website uses cookies to distinguish you from other
                        users. This helps us to provide you with a good user
                        experience and also allows us to improve our website.
                        <a href={routes.returnPolicy} className="privacy-link">
                          Privacy Policy
                        </a>
                      </p>
                      <div className="display">
                        <a className="cookie-link">Cookie Settings</a>
                        <button
                          onClick={() => handleAccept()}
                          className="btn btn-primary cookies-btn"
                        >
                          Accept All Cookies
                        </button>
                        <button
                          onClick={() => handleReject()}
                          className="btn btn-secondary cookies-btn "
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Footer;
