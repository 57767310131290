import React, { useState, useEffect } from "react";
import DefaultLayout from "../../common/DefaultLayout";
import { useSelector } from "react-redux";
import { getCustomerInfo } from "../../services/auth";
import { addCustomer } from "../../redux/customers/customerSlice"; //TODO abosulite path
import { useDispatch } from "react-redux";
import { getCustomerCart } from "../../services/cart";
import { setCart } from "../../redux/cart/CartSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getOrderInfo } from "../../services/order";
import { DropDownCoponent } from "../../common/DropDownComponent";
const OrderSuccess = () => {
  const  state  = useLocation().state;


  const { items } = useSelector((state) => state?.cart);
  const { customer } = useSelector((state) => state?.customer);
  const dispatch = useDispatch();
  const shipping_addresses = customer?.addresses;
  const [newlist, setnewlist] = useState([]);
  const [order, setorder] = useState();
  const [thouht, setthouht] = useState("");
  const navigate = useNavigate();
  const { logedIn } = useSelector((state) => state.customer);
  const tokken = logedIn;
  useEffect(() => {
    const getCustomer = async () => {
      try {
        const { customer } = await getCustomerInfo();
        const data  = await getOrderInfo();

        var ordertmp = data.customer.orders.items.find((item) => item.number === state?.order?.order_number);
        setorder(ordertmp)
        dispatch(addCustomer(customer));
        if (customer) {
          const { customerCart } = await getCustomerCart();
          dispatch(setCart(customerCart));
        }
      } catch (err) {}
    };
    if (items.length === 0) {
      // navigate("/")
    }
    setnewlist([...items]);
    getCustomer();
    return () => {};
  }, []);
  return (
    <DefaultLayout hasOptionsPartners={true}>
      <div className="container order-success-wrapper">
        <div className="row mt-5">
          <div className="col-12 col-md-8">
            <p className="thanks-heading">Thanks for Your Order!</p>
            <p className="order-no">Your order # is {state?.order?.order_number}</p>
            <p className="order-msg">
              We’ll email your order confirmation to {customer?.email}
              <br></br>
              For details on your gift purchase, review the confirmation email
              we sent you or visit {tokken ? <a href={"/orders"} className="link">My Orders</a>
              : <Link to={"/login/password"} state={{paramEmail: customer?.email , orderId : state?.order?.order_number}} className="link">Create account</Link>  
            }
            </p>
            {

            }
            <div>
              <p className="parcel-heading">
                Parcel {newlist.length} of {newlist.length}
              </p>
              <p className="order-msg">
                {shipping_addresses &&
                  `Delivering To: ${shipping_addresses[0].street[0]}, ${shipping_addresses[0].city} ${shipping_addresses[0].postcode}`}
                
                <br></br>Estimated Delivery: Will despatch it today
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="float-right  w-[275px]">
            <DropDownCoponent
            bottom={true}
              placeholder="How did you hear about us?"
              value={thouht}
              onChange={(e) => setthouht(e.label)}
              options={[
                {
                  value: "Social Media",
                  label: "Social Media",

                },
                {
                  value: "Advertisment",
                  label: "Advertisment",
                },
                {
                  value: "Other",
                  label: "Other",
                },
              ]}
            />

            </div>
            <button className="btn submit-btn">Submit</button>
          </div>
        </div>
        {newlist?.map((item , i) => (
          <div key={i} className="row product-row border-b-1 my-1 ">
            <div className="col-12 product-resp-details">
              <p className="product-name">{item?.product?.name}</p>
              <p className="author">By LearningRoots.com</p>
            </div>
            <div className="col-12 col-md-3 product-img">
              <img
                src={item?.product?.image.url}
                alt="itempic"
                height="200px"
              />
            </div>
            <div className="col-12 col-md-9 product-details">
              <p className="product-name">{item?.product?.name}</p>
              <p className="author">By LearningRoots.com</p>
              <p className="price">
                £{item?.product?.price_range.minimum_price?.final_price.value}
              </p>
              <p className="quantity">Quantity: {item?.quantity}</p>
            </div>
    
          </div>
        ))}
      </div>
    </DefaultLayout>
  );
};
export default (OrderSuccess);
