import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import DefaultLayout from "../../common/DefaultLayout";
import prodimg from "../../assets/images/touch-and-feel_5000x.png";
import SortingDropdown from "../../common/SortingDropdown";
import { useDispatch, useSelector } from "react-redux";
import { calculateRatings, calculateAvg } from "../../utils/index";

import { getFavoritesInfo } from "../../services/auth";
import { setFavourites } from "../../redux/customers/customerSlice";
import { Bars } from "react-loader-spinner";
import { set } from "lodash";

const MyFavourites = () => {
  const { favourites } = useSelector((state) => state.customer);
  const [loading, setloading] = useState(true);
  const [favList, setFavList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const getFavorites = async () => {
      try {
        const { customer } = await getFavoritesInfo();
        console.log(customer.favourites[0]);
        setFavList([...customer.favourites[0].items_v2.items]);
        dispatch(setFavourites(customer.favourites[0]));

      } catch (err) {
        console.log(err);
      }
    };
    if (favourites === null) {
      getFavorites();
    }
  }, []);
  useEffect(() => {
    if (favourites === null) return;
    setFavList([...favourites.items_v2.items]);
    setloading(false);
  }, [favourites]);

  return (
    <DefaultLayout hasOptionsPartners={true}>
      <div className="container container_fav pt-3">
        <div className="mb-3">
          {/* <Breadcrumbs
            paths={[
              { url: routes.homepage, text: 'Home' },
              { url: routes.productsPage, text: 'My Account' },
              { url: routes.productDetail, text: 'My Orders' },
            ]}
          /> */}
        </div>
        <div className="row mt-5 heading-row">
          <div className="col-12 col-md-8">
            <h1 className="heading_fav">My Favourites</h1>
            <p className="result">{favList?.length || 0} Results</p>
          </div>
          <div className="col-12 col-md-4">
            <div className="inp_div_fav">
              <button className="share_btn">
                <i className="fa fa-share mr-2"></i>
                Share
              </button>
              <SortingDropdown />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row fav_card_div">
            {loading ? (
              <div className="w-full min-h-[60vh] h-[300px] flex items-center justify-center ">
              <Bars
                height="100"
                width="100"
                radius="9"
                color="#53acaa"
                ariaLabel="three-dots-loading"
                wrapperStyle
              />

              </div>
            ) : (
              favList?.map((item,i) => {
                const product = item.product;
                let reviewPoints = calculateAvg(
                  calculateRatings(product.reviews?.items),
                  product.reviews?.items.length
                ).toFixed(1);
                return (
                  <div key={i} className="col-sm-12 col-md-6 col-lg-3">
                    <div className="cards">
                      <div className="img-div">
                        <img
                          src={product.image.url}
                          alt=""
                          width={100}
                          height={150}
                        />
                      </div>
                      <div className="content-div">
                        <p className="mt name">
                          {product.name}
                          <br />
                        </p>
                        <p className="h-10">
                          {product.azoom_product_specifications.publisher && (
                            <span>
                              By{" "}
                              {product.azoom_product_specifications.publisher}
                            </span>
                          )}
                        </p>
                        <h3 className="price">
                          £{product.price_range.minimum_price.final_price.value}
                        </h3>
                        <div className="rating-stars rating-stars-wrapper">
                          <ReactStars
                            count={5}
                            size={15}
                            value={parseFloat(reviewPoints)}
                            edit={false}
                            isHalf={true}
                            emptyIcon={<i className="far fa-star" />}
                            halfIcon={<i className="fa fa-star-half-alt" />}
                            filledIcon={<i className="fa fa-star" />}
                            activeColor="#000000"
                            color="black"
                          />
                          {reviewPoints === 0.0 && <span>{reviewPoints}</span>}
                        </div>
                        <pre>FREE DELIVERY</pre>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
export default MyFavourites;
