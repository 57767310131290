import React from 'react';
import DefaultLayout from '../../common/DefaultLayout';
import i1 from '../../assets/images/icon_quote-small.png'
import i2 from '../../assets/images/icon_quote-small.png'

const AboutPage = (props) => (
	<DefaultLayout hasOptionsPartners={true}>
		<div className="container-fluid">
			<div className="row">
				<div className="col-12 help_head">
					<h1>About</h1>
				</div>
			</div>
		</div>
		<div className="container">
			<p className='route'>Home  /  About  /  <span>About AZoom</span></p>

			<div className="row help_main">
				<div className=" col-md-7">
					<div className="pr-4">
						<h1>About  AZoom</h1>
						<p>At Azoom, our mission is to bridge the gap between people from diverse backgrounds and the rich heritage of Islam. With an unwavering commitment to sourcing only the finest products across a broad spectrum of categories, we ensure that our platform is the ultimate destination for all your cultural and faith-related needs. Shopping with us guarantees peace of mind, making it a stress-free experience.
                    </p>
						<p>Our passion for what we do drives us to constantly seek out new and exciting products that are sure to capture your attention. We believe in going the extra mile to offer something special, unique, and unforgettable with just a few clicks on our site. Once you browse through our extensive range of offerings, we're confident that you'll return to us time and time again.
                    </p>
						<p>
						As dedicated now as we were on the first day of our business, we strive to bring you fresh and innovative products with every visit to our site. 
                    </p>
						<p>
							<ul className="para_ul">
								<li>       You can trust us to work closely with authors and grow to a point where we can even assist with publishing. 
                        </li>
								<li>        We're also committed to building strong relationships with leading manufacturers and distributors to ensure that you always get the best possible price.
                        </li>
								
							</ul>
						</p>
						<p>
						So, why not take some time to explore our platform and discover all that Azoom has to offer? We promise to take care of all the hard work so that you can sit back, relax, and enjoy the ultimate shopping experience.
                    </p>
						<div className="gdiv">
							<img className="i1" src={i1} alt="ixon" />
							<img className="i2" src={i2} alt="ixon" />

							<p>We simply wouldn’t have it any other way.  </p>
						</div>
					</div>
				</div>
				<div className="col-md-5 contactform">
					<h1>Contact Form</h1>
					<input type="text" placeholder="First Name" />
					<input type="text" placeholder="Last Name" />
					<input type="text" placeholder="Email" />
					<input type="text" placeholder="Phone" />
					<input type="text" placeholder="Subject" />
					<textarea className="msg" type="text" placeholder="Message"></textarea>
					<p className="pp">0/30</p>

					<div className="mt-5">
						<p>
							<input type="checkbox" /> I agree to the <a>Terms & Conditions</a> and <a>Privacy
                            Policy</a>
						</p>
						<button>Send</button>
					</div>
				</div>
			</div>
		</div>
	</DefaultLayout>
);
export default AboutPage;
