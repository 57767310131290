import React, { useState, useCallback, useEffect, useMemo , useRef } from 'react';
import DropDown from '../assets/images/dropdown.png';
import useOutsideClick from "../hooks/useOutsideClick";
import "./style.scss"

export  function DropDownCoponent({
    value = "" ,
     onChange = () => {} ,
      options = [],
      placeholder = "Select an option",
    bottom= false,
    }){
      const [open, setopen] = useState(false)
    const modalRef = useRef();
    useOutsideClick(modalRef, () => open && setopen(false));
    return (
      <div className="dropdownn px-0 mb-2 ">
                        <button onClick={()=>{setopen(!open)}} className="dropdowns">
                          <img src={DropDown} />
                          <p>{placeholder}</p>
                          <small>{value}</small>
                        </button>
                        {open && <div  ref={modalRef} className={"dropdown-contentt " + (bottom ? " bottomm" : "")}>
                          {
                            options?.map((option, index) => (
                              <div className='font-bold' key={index} href="#" onClick={() => {onChange(option) ; setopen(false)} }>{option.label}</div>
                            ))
  
  
                          }
  
                        </div>}
                      </div>
    )
  }