import axios from 'axios';
import { URL, HEADERS } from '../constants/api';
import { raiseErrorForServices } from '../constants/errors';

const gqlMutationForGettingShopByCategory = `query {
  shopByCategory:categoryList(
    filters: {      
      is_featured: { 
          in: [1] 
      }      
    }
  ) {
     id    
    name
    product_count
    image    
    uid
    level
    url_key      
    description 
    svg:animation  
  }
}`;

const gqlQueryForSliderOffer = `query {

  #megamenu offer slider
  #========================================
  megamenu_offer_slider: products(    
    filter: {        
      megamenu_offer_slider: {
        eq: "1"
      }
    }    
  ) {   
    total_count  
    items {       
      id      
      name
      sku 
      azoom_product_specifications{
          publisher
      }              
      price_range {        
        minimum_price {
          final_price{
            value
            currency
          }
        }
      }                 
      image{
          url
      } 
      stock_status      
      short_description {
        html
      }
      reviews {
        items {          
          average_rating
          ratings_breakdown{
            name
            value
          } 
        }
        
      }
    }      
  }
  
}`;

const gqlMutationForFreeGift = `mutation setIncludeFreeGift($input: SetIncludeFreeGiftInput!){   
  setIncludeFreeGift(
    input: $input
  ){
    quote_id
    include_free_gift
  }
}`;

export const getShopByCategories = async _ => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: gqlMutationForGettingShopByCategory,
    },

    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};

export const getSliderOffers = async _ => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: gqlQueryForSliderOffer,
    },

    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};

export const getFreeGift = async input => {
  const { data } = await axios({
    url: URL,
    method: 'POST',
    data: {
      query: gqlMutationForFreeGift,
      variables: {
        input,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
