import React, { useEffect, useState } from "react";
import DefaultLayout from "../../common/DefaultLayout";
import book from "../../assets/images/book3c_2048x.png";
import OrderDetailSidebar from "../../common/OrderDetailSidebar";
import search from "../../assets/images/icon_search-white.png";
import filter from "../../assets/images/icon_filter.png";
import Modal from "react-modal";
import closeicon from "../../assets/images/icon_close-menu.png";
import {ReactComponent as  InfoIcon} from "../../assets/images/icons/icon_info.svg";
import Breadcrumbs from '../../common/Breadcrumbs';
import * as routes from '../../routePaths';
import { getOrderInfo } from "../../services/order";
import { useDispatch, useSelector } from "react-redux";
import { addOrder } from "../../redux/orders/orderSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import InvoiceDetailSidebar from "../../common/InvoiveDetailSidebar";
import AddToCart from "../../common/AddToCart";
import { addItemsToCart } from "../../services/cart";
import { toast } from "react-toastify";
import { addToCart } from "../../redux/cart/CartSlice";

import { Bars } from "react-loader-spinner";


const orderTypes = [
  {
    id: "allOrders",
    title: "All Orders",
  },
  {
    id: "inProgress",
    title: "In-progress Items",
  },
  {
    id: "delivered",
    title: "Delivered Items",
  },
  {
    id: "cancelled",
    title: "Cancelled Items",
  },
  {
    id: "returned",
    title: "Returned Items",
  },
];

const OrdersPage = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [invoiceIndex, setInvoiceIndex] = useState("");
  const [invoiceDetail, setInvoiceDetail] = useState([]);
  const [orderType, setOrderType] = useState("allOrders");
  const [orderDetail, setOrderDetail] = useState([]);
  const [orders, setorders] = useState([]);
  const [allItems, setallItems] = useState([]);
  const [orderIndex, setOrderIndex] = useState("");
  const [loading, setloading] = useState(true);
  const { order } = useSelector((state) => state?.order);
  const { customer } = useSelector((state) => state?.customer);
  const { id } = useSelector((state) => state?.cart);
  // const items = customer?.orders?.items;
  console.log(customer)
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const addItemToCustomerCart = async (item) => {
    const { product_sku } = item;
    try {
      let cartItems;

      cartItems = [
        {
          quantity: 1,
          sku: product_sku,
        },
      ];
      
      const { addProductsToCart } = await addItemsToCart(cartItems, id);
      dispatch(addToCart(addProductsToCart.cart.items));
      toast.success('Added to Cart');
    } catch (err) {
      toast.error(err.message);
    } finally {
    }
  };

  const sortItems = (items) => {
    var data = []
    if (items)
    {
      if (orderType === "delivered") {
        data = items.map(
          (item) => 
          {return {
            ...item,
            items: item.items.filter((item) => item.status === "Shipped")
          }}
        );
      }
      else if (orderType === "cancelled") {
        data = items.map(
          (item) => 
          {return {
            ...item,
            items: item.items.filter((item) => item.status === "Canceled")
          }}
        );
      }
      else if (orderType === "returned") {
        data = items.map(
          (item) => 
          {return {
            ...item,
            items: item.items.filter((item) => item.status === "Refunded")
          }}
        );
      }
      else if (orderType === "inProgress") {
        console.log(items)
        data = items.map(
          (item) => 
          {return {
            ...item,
            items: item.items.filter((item) => item.status === "Ordered")
          }}
        );
      }
      else{
        
        data = items
      }
      // remove items with no items
      data = data.filter((item) => item.items.length > 0)
  
      console.log(data)
      setorders([...data])
    }
  }
  useEffect(() => {
    const getOrders = async () => {
      setloading(true)
      try{
        
        const data = await getOrderInfo();
        setallItems([...data.customer.orders.items])
        setorders([...data.customer.orders.items])
      }
      catch(err){
        console.log(err)
      }
      setloading(false)
    }
    getOrders()
    
    
  }, [ ]);
  useEffect(() => {
    sortItems(allItems)

    
    
  }, [orderType]);

  
  const openOrderDetail = (item, index) => {
    setIsOpen(true);
    setOrderDetail(item);
    setOrderIndex(index);
  };
  const openInvoiceDetail = (item) => {
    setInvoiceOpen(true);
    console.log(item)
    setInvoiceDetail(item)
    // setOrderDetail(item);
  };
  return (
    <>
      {orderIndex !== "" && (
        <OrderDetailSidebar
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          orderDetail={orderDetail}
          orderIndex={orderIndex}
        />
      )}
      
        <InvoiceDetailSidebar
          isOpen={invoiceOpen}
          setIsOpen={setInvoiceOpen}
          invoiceDetail={invoiceDetail}
          invoiceIndex={invoiceIndex}
        />

      {/* Fix Me */}
      <DefaultLayout hasOptionsPartners={true}>
        <div className="container main-container pt-4">
          <div className="mb-4">
          <Breadcrumbs
            paths={[
              { url: routes.homepage, text: 'Home' },
              { url: routes.productsPage, text: 'My Account' },
              { url: routes.productDetail, text: 'My Orders' },
            ]}
          />

          </div>
          {/* dheading div --> */}
          <div className="main-heading-div row mb-5">
            <div className="col-md-8 col-12">
              <div className="heading-flex">
                <h1 className="heading ">
                  <b>My Orders</b>
                </h1>
                <button className="btn filters-btn">
                  <img src={filter} />
                  Filters
                </button>
              </div>
            </div>
            <div className="col-md-4 input-group search-input">
              <input
                type="text"
                className="form-control"
                placeholder="Search by keyword or order"
                aria-describedby="basic-addon2"
              />
              <button className="input-group-append btn-search border-0 flex-center">
                {/* <i className="fas fa-search"></i> */}
                <img src={search} />
              </button>
            </div>
          </div>
          {/* dul --> */}
          <ul className="tabs-div row mt-5 mb-5 max-sm:text-[10px] ">
            {orderTypes?.map((type) => (
              <li
                className={orderType === type.id ? "active " : ""}
                key={type.id}
                onClick={() => setOrderType(type.id)}
              >
                {type.title}
              </li>
            ))}
          </ul>

          {loading ? <div className="flex-center">

          <Bars
                height="20"
                width="20"
                radius="9"
                color="#53acaa"
                ariaLabel="three-dots-loading"
                wrapperStyle
                wrapperClass
              />
          </div> : orders?.map((item, i) => {
          if (item.items.length > 0) 
            return (
              <div className="card card_div border-0 mb-5" key={i}>
                <div className="row top_row">
                  <div className="col-12">
                    <div className="flex-content">
                      <div className="flex order-date">
                        <div className="right-border">
                          <p className="mr-3">
                            {" "}
                            <i className="mr-1 far fa-calendar-alt"></i> Date
                            Placed:{" "}
                            {moment(item.order_date).format("MMMM Do, YYYY")}{" "}
                            {item.status}{" "}
                          </p>
                        </div>
                        <p>
                          &nbsp; &nbsp; Order #: <b> {item.number}</b>{" "}
                        </p>
                      </div>
                      <div className="flex t-price">
                        <p>
                          {" "}
                          Total Price: <b>£{item.total.grand_total.value}</b> (
                          {item.items.length} items){" "}
                        </p>
                        <p onClick={()=>{openInvoiceDetail(item?.invoices)}} className="view_invoice cursor-pointer">View Invoice</p>
                      </div>
                    </div>
                  </div>
                </div>
                {item.items.map((orderDetail, index) => (
                  <div
                    className="row no-gutters mt-3 bg-white"
                    key={orderDetail.id}
                  >
                    <div className="col-md-3 card_img_div ">
                      <img src={
                        orderDetail.product_image
                      } className="card-img" alt="..." />
                    </div>
                    <div className="col-md-6">
                      <div className="card-body card_body">
                        <h5 className="card-title card_title">
                          {orderDetail.status}
                          <span>
                            <i className="mr-2 far fa-calendar-alt"></i>
                            {moment(item.order_date).format("MMMM Do, YYYY")}
                          </span>
                        </h5>
                        <p className="card-text">
                          <small>Original Item Replaced</small>
                        </p>
                        <div className="mt-3 mb-3">
                          <p className="card-text">
                            <b>{orderDetail.product_name}</b>
                          </p>
                          <p className="card-text">
                            <small className="text-muted">
                              <b>By LearningRoots.com</b>
                            </small>
                          </p>
                        </div>
                        <p className="card-text">
                          <small className="text-muted">
                            Quantity: {orderDetail.quantity_ordered}
                          </small>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-3 buttons flex-center flex-column">
                      <button
                        className="rounded mb-2 b1"
                        onClick={() => openOrderDetail(item, index)}
                      >
                        <b>View Details</b>
                      </button>
                      <button onClick={()=>navigate("/product-review" , {state: {product : orderDetail}})} className="rounded mb-2 b2">
                        <b>Write a Product Review</b>
                      </button>

                      <button onClick={()=>addItemToCustomerCart(orderDetail)} className="rounded mb-2 b3">
                        <b>Buy Again</b>
                      </button>
                    </div>
                    <div className="col-md-3 resp-btn">
                      <div className="flex-btn ">
                        <button
                          className="rounded b1"
                          onClick={(item) => openOrderDetail(item , index )}
                        >
                          <b>Viewv Details</b>
                        </button>
                        <button onClick={()=>addItemToCustomerCart(orderDetail)}  className="rounded b3">
                          <b>Buy Again</b>
                        </button>
                      </div>
                      <div className="flex-btn ">
                        <button onClick={()=>navigate("/product-review" , {state: {product : orderDetail}})} className="rounded mb-2 b2">
                          <b>Write a Product Review</b>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
            else
                  <div key={i}></div>
          })}
          {
           ( orders.length  === 0&& !loading)&&  (

              <div className="m-auto text-center font-bold flex flex-col items-center">
               {
                orderType === "returned" && <div className=" return w-full border-2- h-[75px]">
                  <div className="icon">
                <InfoIcon/>
                  </div>
                    Need to return or replace an item ?
                    <span className="underline text-danger font-bold cursor-pointer">
                      View items eligible for return
                    </span>
                </div>}
                We couldn’t find any {orderTypes.find(
                  (type) => type.id === orderType
                ).title } 
                <button className="_btnTest w-[150px] mb-2 b2  mt-2" onClick={() => setOrderType("allOrders")}>
                  View All Orders
                </button>
              </div>
            )

          }
          
        </div>
          
      </DefaultLayout>
    </>
  );
};
export default (OrdersPage);
