import axios from "axios";
import { URL, HEADERS } from "../constants/api";
import { raiseErrorForServices } from "../constants/errors";

const gqlDataForClientSecret = `query stripe_client_secret(
  $cart_id: String!
  $amount: Float
) {
    stripe_client_secret(
      cart_id : $cart_id
      amount: $amount
    ) {      
      clientSecret
    }
  }
`;

export const getClientSecret = async (paymentData) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForClientSecret,
      variables: paymentData,
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
const gqlDataForPaypalSecret = `query {
  paypal_get_key
}
`;

export const getPaypalSecret = async (paymentData) => {
  const { data } = await axios({
    url: URL,
    method: "POST",   
    data: {
      query: gqlDataForPaypalSecret,  
    },
    // headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
