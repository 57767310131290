import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { palceOrder } from "../../services/order";
import { setOrderId } from "../../redux/orders/orderSlice";
import { getCustomerInfo } from "../../services/auth";
import { addCustomer } from "../../redux/customers/customerSlice"; //TODO abosulite path

import { useDispatch } from "react-redux";
import { getCustomerCart } from "../../services/cart";
import { setCart, setCartSummaryOpen } from "../../redux/cart/CartSlice";
import { getClientSecret } from "../../services/payment";
export const Card = ({
  paymentModal,
  setPaymentModal,
  setmodals,
  subTotal,
}) => {
  const [loading, setLoading] = useState(false);
  // const [order, setorder] = useState(null);
  const elements = useElements();
  const stripe = useStripe();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState("");

  const { cart } = useSelector((state) => state);
  const { items ,id  } = useSelector((state) => state?.cart);
  const {customer} = useSelector((state) => state?.customer);
// function to check if the input is empty or not

useEffect(() => {
  console.log("customer", customer);
  const handleOrderPlaceClick = async () => {
    
    setLoading(true);
    try {
      
      const { stripe_client_secret } = await getClientSecret({
        cart_id : id,
        amount: subTotal,
      });
      const { clientSecret } = stripe_client_secret;
      console.log("client secret ", clientSecret)
      setClientSecret(clientSecret);
      setPaymentModal(true);
    } catch (e) {
      console.log(e)
      // toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };
  // if (subTotal > 0) 
  handleOrderPlaceClick()

  return () => {
    
  }
}, [subTotal])

const [isCardValid, setIsCardValid] = useState(false);
const handleCardChange = (event) => {
  setIsCardValid(event.complete);
};
  const handleSubmit = async (e) => {
    e.preventDefault();
    const billing_details = {
            address : {
              city : customer?.addresses[0]?.city,
              country : customer?.addresses[0]?.country_code,
              line1 : customer?.addresses[0]?.street[0],
              postal_code : customer?.addresses[0]?.postcode,

        },
         email : customer?.email,
          name : customer?.firstname + " " + customer?.lastname,
          phone : customer?.addresses[0]?.telephone, 
      }
    console.log("clientSecret", clientSecret);
    if (!stripe || !elements || !isCardValid  || loading) {
      // Stripe.js has not yet loaded.

      return;
    }
    // setmodals(true);
    setLoading(true);
    try {
      const payment_method = {
        card: elements.getElement(CardElement),
        billing_details,
      };
      console.log("payment_method", payment_method);
      const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: payment_method
      });
      console.log(paymentIntent)
    if (paymentIntent.id) {
      const { placeOrder: order } = await palceOrder({
        cart_id: cart.id,
      });
      setOrderId(order.order?.order_number);
      setmodals(true);
    setPaymentModal(false);
      setLoading(false);
      dispatch(setCartSummaryOpen(false));
      setTimeout(() => {
    navigate("/orders/success" , {state : order});
      }, 3000);
      toast.success(
        `You have Successfully Paid ${subTotal} GBP`
      );
      if (stripeError) {
        setLoading(false);
  
        toast.error("Unable to Pay with your card");
      }
    }
    } catch (error) {
      console.log(error)
      setLoading(false);
      // toast.error("Unable to Pay with your card");
    }
    
  };
  return (
 
       <form id="payment-form" onSubmit={handleSubmit} className="card-form">
        <label htmlFor="card-element" className="mb-3">
          Enter your Card Details
        </label>
        <CardElement onChange={handleCardChange} options={{ hidePostalCode: true }} />
        <div className="d-flex justify-content-center my-3">

          <button  type="submit" disabled={!isCardValid}
                    className="place_order_btn btn-primary"
                    
                  >
                    {loading ? "Paying" : "Place Your Order"}
                  </button>
        </div>
      </form> 

  );
};
