import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  signIn: false,
};

export const loaderSlice = createSlice({
  name: 'loaderInfo',
  initialState,
  reducers: {
        setloader: (state, action) => {
        const { payload } = action;
        state.loading = payload;
        },
        setSignin: (state, action) => {
        const { payload } = action;
        console.log(payload)
        state.signIn = payload;
        },


  },
});

export const {
  setloader,
  setSignin
} = loaderSlice.actions;

export default loaderSlice.reducer;
