import axios from "axios";
import { URL, HEADERS } from "../constants/api";
import { raiseErrorForAddToCart, raiseErrorForServices } from "../constants/errors";
//sample gqlData format - query or mutation data
export const gqlForRewardPts = `query customer{
  customer {
  
    mstRewardPointsBalance{
      balance   
      #tier_id
      transactions{
        transaction_id
        comment 
        amount
        created_at
        is_expired        
      }
    }
        
  }  
}`;
export const gqlSetGuestEmail = `
  mutation ($input: SetGuestEmailOnCartInput!) {
    setGuestEmailOnCart(
      input: $input
    )
  {
    cart {
      email
    }
  }
}`;
const qglDataForRemoveRewardPts = `
mutation($input : MstRewardsApplyPointsToCartInput!) {
  mstRewardsApplyPointsToCart(
      input: $input      
  ) {
      cart {
          prices {
              grand_total {
                  value
                  currency
              }
          }
          mstRewardPoints {
              is_applied
              spend_points
              base_spend_amount {
                  value
              }
              spend_amount {
                  value
              }
              spend_min_points
              spend_max_points
              earn_points
          }
      }
  }
}
`;
const qglDataForApplyRewardPts = `
mutation ($input : MstRewardsApplyPointsToCartInput!){
  mstRewardsApplyPointsToCart(
      input: $input
  ) {
      cart {
          prices {
              grand_total {
                  value
                  currency
              }
          }
          mstRewardPoints {
              is_applied
              spend_points
              base_spend_amount {
                  value
              }
              spend_amount {
                  value
              }
              spend_min_points
              spend_max_points
              earn_points
          }
      }
  }
}
`;

export const gqlDataForCart = `query customerCart {
  customerCart {
    shipping_addresses {
      firstname
      lastname
      street
      city

      telephone
    }
    include_free_gift
    id
    applied_coupons {
      code
    }
    prices {
      grand_total{
        value
        currency
      }
    }
    mstRewardPoints {
      is_applied
      spend_points
      earn_points
      base_spend_amount {
          value
          currency
      }
      spend_amount {
          value
          currency
      }
      spend_min_points
      spend_max_points
      earn_points
  }
    items {
      id
      product {
        id
        name
        sku
        price_range {        
          minimum_price {
            regular_price {
              value
              currency
            }
            discount{
              amount_off
              percent_off
            }
            final_price{
              value
              currency
            }
  
          }
          maximum_price {
            regular_price {
              value
              currency
            }
            discount{
              amount_off
              percent_off
            }
            final_price{
              value
              currency
            }
          }
          
        }
        
        image {
          label
          url
        }
      }
      quantity
    }
  }
}`;

export const gqlDataForCartEmpty = `query ($cartId: String!) {
  cart (cart_id: $cartId) {
    email
    shipping_addresses {
      firstname
      lastname
      street
      city

    }
    include_free_gift
    id
    applied_coupons {
      code
    }
    prices {
      grand_total{
        value
        currency
      }
    }
    mstRewardPoints {
      is_applied
      spend_points
      earn_points
      base_spend_amount {
          value
          currency
      }
      spend_amount {
          value
          currency
      }
      spend_min_points
      spend_max_points
      earn_points
  }
    items {
      id
      product {
        id
        name
        sku
        price_range {        
          minimum_price {
            regular_price {
              value
              currency
            }
            discount{
              amount_off
              percent_off
            }
            final_price{
              value
              currency
            }
  
          }
          maximum_price {
            regular_price {
              value
              currency
            }
            discount{
              amount_off
              percent_off
            }
            final_price{
              value
              currency
            }
          }
          
        }
        image {
          label
          url
        }
      }
      quantity
    }
  }
}`;

const gqlMutationForAddingItem = `mutation addProductsToCart( $cartId: String!, $cartItems: [CartItemInput!]! ) {
  addProductsToCart(
    cartId: $cartId
    cartItems: $cartItems
  ) {
    cart {
      mstRewardPoints {
        is_applied
        spend_points
        earn_points
        base_spend_amount {
            value
            currency
        }
        spend_amount {
            value
            currency
        }
        spend_min_points
        spend_max_points
        earn_points
    }
      items {
        id     
        product {
          id
          name
          sku
          price_range {        
            minimum_price {
              regular_price {
                value
                currency
              }
              discount{
                amount_off
                percent_off
              }
              final_price{
                value
                currency
              }
    
            }
            maximum_price {
              regular_price {
                value
                currency
              }
              discount{
                amount_off
                percent_off
              }
              final_price{
                value
                currency
              }
            }
            
          }
          image {
            label
            url
          }
        }
        quantity
      }
    }
    user_errors {
      code
      message
    }
  }
}`;

const gqlMutationForRemovingItem = `mutation ($cartId: String!, $cartItemId: Int!) {
  removeItemFromCart(
    input: {
      cart_id: $cartId,
      cart_item_id: $cartItemId
    }
  )
 {
  cart {
    items {
      id
      product {
        name
      }
      quantity
    }
    prices {
      grand_total{
        value
        currency
      }
    }
  }
 }
}`;
const gqlDataForEmptyCart = `mutation {
  createEmptyCart
}
`;

// create gqldatat to merge cart with src cart id and dest cart id

const gqlDataForMergeCart = `mutation ($source_cart_id: String!, $destination_cart_id: String!)
 {
  mergeCarts(
    source_cart_id: $source_cart_id
    destination_cart_id: $destination_cart_id
  )
  {
    include_free_gift
    id
    email
    billing_address {
      city
      country {
        code
        label
      }
      firstname
      lastname
      postcode
      region {
        code
        label
      }
      street
      telephone
    }
    shipping_addresses {
      firstname
      lastname
      street
      city

      


      
      telephone
  
    }
     available_payment_methods {
      code
      title
    }
    selected_payment_method {
      code
      title
    }
    applied_coupons {
      code
    }
    prices {
      grand_total{
        value
        currency
      }
    }
    items {
      id      
      product {
        name
        sku
        price_range {        
        minimum_price {
          regular_price {
            value
            currency
          }
          discount{
            amount_off
            percent_off
          }
          final_price{
            value
            currency
          }

        }
        maximum_price {
          regular_price {
            value
            currency
          }
          discount{
            amount_off
            percent_off
          }
          final_price{
            value
            currency
          }
        }
        
      }
      image {
        label
        url
      }     
      }
      quantity      
    }
    mstRewardPoints {
            is_applied
            spend_points
            earn_points
            base_spend_amount {
                value
                currency
            }
            spend_amount {
                value
                currency
            }
            spend_min_points
            spend_max_points
            earn_points
        }
  }
}
`;

export const applyRewardPoints = async (input) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: qglDataForApplyRewardPts,
      variables: {
        input : input
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data, "addProductsToCart");
};
export const removeRewardPoints = async (input) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: qglDataForRemoveRewardPts,
      variables: {
        input : input
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data, "addProductsToCart");
};

export const removeItemfromCard = async (id, item_id) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlMutationForRemovingItem,
      variables: {
        cartId: id,
        cartItemId: item_id,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data, "addProductsToCart");
};
export const getCustomerCart = async () => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForCart,
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
export const createEmptyCart = async () => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForEmptyCart,
    },

    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
export const getEmptyCardItems = async (id) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForCartEmpty,
      variables: {
        cartId: id,
      },
    },

    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
export const mergeCarts = async (src, dest) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForMergeCart,
    },
    variables: {
      source_cart_id: src,
      destination_cart_id: dest,
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};

export const addItemsToCart = async (cartItems, cartId) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlMutationForAddingItem,
      variables: {
        cartItems: cartItems,
        cartId: cartId,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForAddToCart(data, "addProductsToCart");
};
export const setGuestEmail = async (input) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlSetGuestEmail,
      variables: {
        input : input
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data, "addProductsToCart");
};

const gqlDataForApplyCoupon = `mutation applyCouponToCart(
  $input: ApplyCouponToCartInput,
) {
  applyCouponToCart(
    input: $input
  ) {
    cart {
      items {
        product {
          name
        } 
        quantity
      }
      applied_coupons {
        code
      }
      prices {
        grand_total{
          value
          currency
        }
      }
    }
  }
}`;
const gqlDataForRemoveCoupon = `mutation removeCouponFromCart(
  $input: RemoveCouponFromCartInput,
) {
  removeCouponFromCart(
    input: $input
  ) {
    cart {
      items {
        product {
          name
        } 
        quantity
      }
      applied_coupons {
        code
      }
      prices {
        grand_total{
          value
          currency
        }
      }
    }
  }
}`;

export const applyCoupon = async (input) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForApplyCoupon,
      variables: {
        input: input,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data, "addApplyCoupon");
};
export const removeCoupon = async (input) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForRemoveCoupon,
      variables: {
        input: input,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data, "addremoveCoupon");
};
export const getRewardPts = async () => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data:{
      query: gqlForRewardPts,
    },

    headers: HEADERS,
  });
  return raiseErrorForServices(data, "gqlForCartReward");
};
