import React from 'react';
import SortingDropdown from '../../common/SortingDropdown';
import filter from '../../assets/images/icon_filter.png';
import { useSelector } from 'react-redux';
import Checkbox from '../../common/Form/CheckBox';
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 import { faTh } from "@fortawesome/free-solid-svg-icons";
 import { faList } from "@fortawesome/free-solid-svg-icons";
export const  ProductHeader = ({
  // selectedSubHeading,
  setSort,
  totalProducts,
  setSideNavOpen,
  grid,
  setgrid,
  sort,
}) => {
  const { selectedCategory } = useSelector(state => state.products);

  return (
    <div>
      {/* <h1 className="main-heading">{selectedSubHeading}</h1> */}
      <div className="row">
        <p className="sec-desc col-12 col-md-8">
          {selectedCategory?.description || ''}
          <FontAwesomeIcon style={{fontSize: "24px", marginRight: "10px" , color : grid ?  "#68AAA9" : "" }} 
          icon={faTh }
            onClick={() => setgrid(true)}
          />

          <FontAwesomeIcon style={{fontSize: "24px" , color : !grid ?  "#68AAA9" : ""}} 
          onClick={() => setgrid(false)}
          icon={faList} />
          <small className="d-none d-md-block text-muted ">
            {totalProducts} Results
          </small>
        </p>
        <div className="col-12 col-md-4 d-none d-md-block">
          <SortingDropdown setSort={setSort} sort={sort} />
        </div>
        <div className="col-12 d-md-none results-filter-wrapper">
          <div>
            <span>{totalProducts} Results</span>
          </div>
          <div>
            <button
              className="btn filters-btn"
              onClick={() => setSideNavOpen(true)}
            >
              <img src={filter} />
              Sort a Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
