import React  , {useEffect}from 'react'
import "./style.scss";

export default function Input({
    label,
    onChange,
    
    value,
    handleKeyDown,
    requierd = false,
    error= "",
    small= false,
    type = "text",
    big = false,
    ...rest
 }) {
    useEffect(() => {
      
      return () => {
        
      }
    }, [])
    
  return (
    <div className={rest.className}>
          {requierd && <span className="requierd text-danger text-[10px]">Requierd *</span>}
         <div className={("box-search") + 
         (value !== "" ? " focusedTrue" : "")
        
        }>

                    <input
                      {...rest}
                      className={(error !== "" ? " border-danger" : "") + " " + (big ? "" : "")}
                      onChange={onChange}
                      value={value}
                      onKeyDown={handleKeyDown}
                        type={type}
                    />
                    <span className={(error !== "" ? "text-danger" : "") + " leading-none"} >
                        {label}
                    </span>
        </div>
        {error !== "" && <span className="text-danger text-[10px]">{error}</span>}
    </div>
  )
}
