import React, { useEffect } from "react";
import { push as Menu } from "react-burger-menu";
import bus from "../assets/images/icons/icon_free-delivery.svg";
import Down from "../assets/images/dropdown.png";
import Badge from "../assets/images/icon_protect.png";
import Del from "../assets/images/icons/icon_trash.svg";
import Ticked from "../assets/images/ok.png";
import Cartadd from "../assets/images/addd.png";
import Gift from "../assets/images/icons/icon_gift-card.svg";
import ShipItemIcon from "../pages/ReturnCenter/ShipItemIcon";
import { useSelector } from "react-redux";
import BasketNotLogged from "./BasketNotLogged";
import { useNavigate } from "react-router-dom";
import { setCartSummaryOpen } from "../redux/cart/CartSlice";
import { removeFromCart } from "../redux/cart/CartSlice";
import { useDispatch } from "react-redux";
import CloseIcon from "../assets/images/icon_close-menu.png";
import { removeItemfromCard, removeItemsToCart } from "../services/cart";
const BasketSummary = ({ isOpen, setSideNavOpen, subTotal, ...props }) => {
  const { customer } = useSelector((state) => state.customer);
  const { items, id } = useSelector((state) => state?.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Menu
      className="bg-primary"
      isOpen={isOpen}
      customBurgerIcon={false}
      onClose={() => dispatch(setCartSummaryOpen(false))}
      {...props}
      right
    >
      {true ? (
        <div className="basket-summary bg-menu-2 bg-light px-3 drop-shadow-sm ">
          <div className="progress_status">
            <img src={bus} alt="bus" />
            <div className="progress_div flex flex-col flex-2 ">
              {Math.round(50 - subTotal) > 0 && (
                <p style={{ margin: "0px" }}>
                  You’re £{Math.round(50 - subTotal)} away from{" "}
                </p>
              )}
              <h3 className="free_delivery">FREE DELIVERY</h3>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${(subTotal * 100) / 50}%` }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
          <button
            className="secondary_btn"
            onClick={() => navigate("/checkout/info")}
          >
            <i className="fa fa-shopping-cart mr-2"></i>
            Review Basket
          </button>
          <button
            disabled={items.length === 0}
            className="ternary_btn ml-3"
            onClick={() => navigate("/checkout/info")}
          >
            Check Out Now
          </button>

          <div className="subtotal">
            <pre>Basket Summary</pre>
            <span>
              Subtotal: <b> £{subTotal}</b>
            </span>
          </div>

          <div className="min-itemss">
            {items.map((item, index) => {
              return (
                <div key={index} className="itemsss p-3 shadow my-2">
                  <div className="itemss_up ">
                    <img
                      className="img_pro"
                      src={item.product.image.url}
                      alt="itempic"
                    />
                    <div className="item_name truncate">
                      <img
                        src={CloseIcon}
                        alt="close"
                        onClick={async () => {
                          // remove item from cart
                          try {
                            await removeItemfromCard(id, item.id);
                          } catch (e) {
                            // TODOOOO
                          }
                          dispatch(removeFromCart(item.id));
                          if (items.length === 1) {
                            dispatch(setCartSummaryOpen(false));
                          }
                        }}
                        className="itemss-close"
                      />
                      <b>
                        £
                        {
                          item.product.price_range.minimum_price.final_price
                            .value
                        }
                      </b>
                      {item.product.name}
                      <br />
                      <div className="text-[#ee7d3b] mt-1 text-sm font-bold">
                        {item.quantity}
                        <span className="text-[#ee5f3b] text-xs"> X</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="free_gift_div">
            <div className="gift_div mt-3 mb-4">
              <img src={Gift} />
              <p>
                <b>FREE GIFT </b>included
              </p>
            </div>

            <div className="free_uk_delivery px-3">
              <ShipItemIcon elemId="basketSummaryShipIcon" />
              <pre>Free UK Delivery</pre>
              <p>
                All deliveries take between 3-5 working days. Due to Covid
                delivery disruptions may occur.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <BasketNotLogged />
      )}
    </Menu>
  );
};
export default BasketSummary;


