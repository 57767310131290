import React, { useState, useEffect, useRef } from "react";
import Splide from "@splidejs/splide";

export default function UpSell({
  upSellProducts = [],
  selectedPro,
  setselectedPro,
}) {
  const [width, setwidth] = useState(window.innerWidth);
  let splide = null;
  const sliderRef = useRef(null);
  const [loading, setloading] = useState(true);

  const disPrice = (product) => {
    return product?.price_range?.minimum_price?.regular_price?.value;
  };
  useEffect(() => {
    window.addEventListener("resize", () => {
      setwidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setwidth(window.innerWidth);
      });
    };
  }, []);
  useEffect(() => {
    if (sliderRef?.current) {
      splide = new Splide(sliderRef?.current, {
        height: "12rem",
        perPage: 3,
        autoWidth: true,
        // cover: false,
        arrows: false,
        // autoplay: true,
        // type: 'loop',
        // interval: 1500,
        pagination: false,
        gap: "1rem",
        breakpoints: {
          768: {
            perPage: 2,
          },
        },
      }).mount();
    }
  });

  const finalPrice = (product) => {
    return product?.price_range?.maximum_price?.final_price?.value;
  };
  const handelClick = (id) => {
    var data = selectedPro;

    var index = selectedPro.indexOf(id);
    if (index === -1) {
      data.push(id);
    } else {
      data.splice(index, 1);
    }
    setselectedPro([...data]);
  };
  return (
    <>
      <div className="row upsellCont">
        <div className="tooltipa">Exlusive bundel with this listing</div>
        <div
          style={{ width: "100%" }}
          className=" flex w-full p-2 overflow-hidden  "
        >
          <div ref={sliderRef} className="splide w-full ">
            <div className="splide__track ">
              <ul className="splide__list  ">
                {upSellProducts?.map((item, i) => {
                  console.log(item);
                  return (
                    <li
                      key={i}
                      onClick={() => handelClick(i)}
                      className={
                        " splide__slide upsell w-[180px] flex overflow-hidden flex-col items-center rounded-xl bg-white  " +
                        (selectedPro.includes(i) ? " selected" : "") +
                        (loading ? " load" : "")
                      }
                    >
                      <img
                        onLoad={() => {
                          if (i === 0) {
                            setloading(false);
                          }
                        }}
                        className={
                          "h-[130px]  m-auto " + (loading ? " hidden" : "")
                        }
                        src={item.image.url}
                        alt="imgupsell"
                      />
                      <div
                        style={{ justifyContent: "space-around" }}
                        className={
                          " w-full flex items-center font-bold justify-evenly " +
                          (loading ? " hidden" : "")
                        }
                      >
                        <div
                          className={
                            "text-xl text-tertiary" + (loading ? " hidden" : "")
                          }
                        >
                          £{item.price_range?.maximum_price?.final_price?.value}
                        </div>
                        {item.price_range?.maximum_price?.final_price?.value !==
                          item?.price_range?.minimum_price?.regular_price
                            ?.value && (
                          <div
                            className={
                              " text-gray-400 line-through" +
                              (loading ? " hidden" : " ")
                            }
                          >
                            £
                            {
                              item?.price_range?.minimum_price?.regular_price
                                ?.value
                            }
                          </div>
                        )}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function UpsellLazyLoad() {
  return (
    <div className="flex upselLoad gap-2 mx-3 my-2">
      <div className="w-[180px] h-[192px] load overflow-hidden rounded"></div>
      <div className="w-[180px] h-[192px] load overflow-hidden rounded"></div>
      <div className="w-[180px] h-[192px] load overflow-hidden rounded"></div>
    </div>
  );
}
