import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../common/Breadcrumbs';
import Filters from './Filters';
import Products from './Products';
import { useSelector } from 'react-redux';
import { ProductHeader } from './ProductHeader';
import { ProductsPagination } from './ProductsPagination';
import { RecentlyViewed } from './RecentlyViewed';
import { getRecentlyViewedProducts } from '../../services/product';
import { updateRecentlyViewedProducts } from '../../redux/products/productSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { capitalize } from 'lodash';

export const   DefaultLayoutChildren = ({
  selectedSubHeading,
  setSideNavOpen,
  getData,

}) => {
  const { products } = useSelector(state => state?.products);
  const { customer } = useSelector(state => state.customer);
  const [routePath, setRoutePath] = useState([]);
  const [grid, setgrid] = useState(true);
  const [recentProducts, setRecentProducts] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [sort, setSort] = useState(false);

  useEffect(() => {
    if (customer) {
      getRecommendedProducts();
    }
  }, [customer]);

  const getRecommendedProducts = async () => {
    try {
      const { recentViewedProducts } = await getRecentlyViewedProducts();
      setRecentProducts(recentViewedProducts);
      dispatch(updateRecentlyViewedProducts(recentViewedProducts));
    } catch (e) {
    } finally {
    }
  };
  useEffect(() => {
    const pathName = location.pathname.split('/');
    pathName[0] = 'Home';
    let breadCrumPath = [];
    for (let i = 0 ; i <  pathName.length ; i++) {
      breadCrumPath[i] = {
        url:  "/"+ pathName.slice(1, i + 1).join("/") ,
        text: capitalize(pathName[i].replace("-", " ")),
      };
    }
    setRoutePath(breadCrumPath);
  }, [location]);

  return (
    <div className="container products-page-wrapper">
      <div className="row">
        <div className="col-lg-3 col-md-4 d-none d-md-block">
          <Filters customId="desktop" />
        </div>
        <div className="col-lg-9 col-md-8 col-sm-12">
          <Breadcrumbs paths={routePath} />
          <ProductHeader
          sort={sort}
          setSort={setSort}
          grid={grid}
          setgrid={setgrid}
            selectedSubHeading={selectedSubHeading}
            totalProducts={products?.items?.length || 0}
            setSideNavOpen={setSideNavOpen}
          />
          <Products sort={sort} routePath={routePath} grid={grid} getData={getData} />
          <ProductsPagination />
          {recentProducts?.items?.length > 0 && (
            <RecentlyViewed recentProducts={recentProducts.items} />
          )}
        </div>
      </div>
    </div>
  );
};
