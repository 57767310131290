import React , {useState} from 'react'
import styled from 'styled-components';
import Modal from './Modal'
import { calculateAvg, calculateRatings } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as faStarEmpty } from '@fortawesome/free-regular-svg-icons';
import { faStarHalfAlt as faStarHalf, faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
export default function ProductModal({
    show = false,
    onClose = null,
    product = {},
}) {
    const [active, setactive] = useState(0)
  let length = product.reviews?.items.length;

    let percentage = calculateAvg(
        calculateRatings(product.reviews?.items),
        length
      ).toFixed(1);
      let disPrice = product.price_range?.minimum_price?.regular_price?.value;
      let finalPrice = product.price_range?.minimum_price?.final_price?.value;
    const handleClose = () => {
        onClose && onClose();
      };


      const renderStars = (rating) => {
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating)
            {
                stars.push(
                    <FontAwesomeIcon

                    icon={faStarSolid}
                    key={i}
                    />
                );
            }
            else if (i === Math.ceil(rating) && !Number.isInteger(rating))
            {
                stars.push(
                    <FontAwesomeIcon
                    icon={faStarHalf}
                    key={i}
                    />
                );
            }
            else 
            {
                stars.push(
                  <FontAwesomeIcon 
                    icon={faStarEmpty} 
                    key={i} 
                  />
                );
            }

        }
        return stars;
      };
  const [backgroundPosition, setBackgroundPosition] = useState("center");

      const handleMouseMove = e => {
        // if (isMobile || isTablet)
        // 		return;
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.pageX - left) / width) * 100;
        const y = ((e.pageY - top) / height) * 100;
        setBackgroundPosition(`${x}% ${y}%`);
      };
      const handleMouseOut = () => {
        setBackgroundPosition(`center`);
      };
  return (
    <Modal show={show} onClose={handleClose} size='xl'>

    <Modal.Slot name='body'>
        <ModalStyles>
            <div className='firstSection' >
                <div className='title'>
                    {product?.name}
                    <br/>
                    {/* {product?.name} */}
                </div>
                {
                    product?.azoom_product_specifications.publisher && 
                    <div>
                        See more by <a src={product?.azoom_product_specifications.publisher} alt="">
                            {product?.azoom_product_specifications.publisher}
                        </a>
                    </div>
                }
                <div className='reviews'>
                    <div className='starts'>
                        {renderStars(percentage)}                       
                    </div>
                    <span>
                    {percentage}
                    {" / "}
                    {length} Reviews
                    </span>
                </div>
                    <div className='Imagescont'>
                        <div className='main'>
           
                                {/* <img label={product.media_gallery?.[active]?.label} src={product.media_gallery?.[active]?.url} /> */}
                                <div
          className="main_image"
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseOut}
          style={{
            backgroundImage: `url(${product.media_gallery?.[active]?.url})`,
            backgroundPosition: backgroundPosition,
          }}
        ></div>

                        </div>
                        <div className='indexing'>
   
                            {
                                product?.media_gallery?.slice(0, 3).map((image, index) => {
                                    return <div onClick={()=>setactive(index)} className={active === index ? "active" : ""} key={index}>
                                        <img alt={image?.label}src={image?.url} />
                                    </div>
                                }
                                )

                            }
                            
            
                        </div>

                    </div>


            </div>
            <div className='secondSection' >
                <div>
                <div className='price'>
                    £{finalPrice}
                  {finalPrice !== disPrice  &&   <span>
                        £{disPrice}
                    </span>}
                </div>
                <div className='title'>
                    Product Overview cd
                </div> 
            { product?.short_description.html &&    <Data title={"Description"} text={product?.short_description.html} isOpen={true} />}
            {product?.specifications &&     <Data title={"Specifications"} text={product?.specifications}  isOpen={false} />}
                
                </div>
                <div className='buttons'>

                    <Button text={"See Full Details"} type="primary" />
                    <Button text={"Save"} type="se" />
                </div>

            </div> 
        </ModalStyles>
    </Modal.Slot>
  </Modal>
  )
}

export  function Data({title , text , isOpen = false, }) {
  const [open, setopen] = useState(isOpen)
  return (
    <DataStyles>
        <div  onClick={()=>setopen(!open)} className='header'>
            <div className='title'>
                {title}
            </div>
            <div onClick={()=>setopen(!open)}className={open ? 'icon rotate' :"icon"} > 
                <FontAwesomeIcon icon={faCaretUp} />
        
            </div>
        </div>
        {
            <div className={open ? 'text' :"text close"}>
                {
                    Array.isArray(text) ? 
                    <>
                    {
                        text?.map((item, index) => {
                            return  <p
                            key={index}
                            className="desc-text"
                            dangerouslySetInnerHTML={{ __html: text }}
                          />
                        })
                    
                    } 
                    </>:
                    <div
                    // className="-"
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                }
            </div>
        }

    </DataStyles>
  )
}
const DataStyles = styled.div`
margin: 10px 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;

    .header{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        >.title{
            font-size: 18px;
            font-weight: 700;
            color: #404041;
            letter-spacing: -0.43px;
        }
        >.icon{
            /* font-size: px; */
            color: #808080;
        }
        >.rotate{
            /* font-size: px; */
            >svg{
            transform: rotate(180deg);
            }
        }
    }
    .close{
        max-height: 0px !important;
    }
    .text{
            transition: max-height 0.4s ease-in-out;

        overflow: hidden;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-height: 700px;
        >div{
            width: 100%;
            font-size: 16px;
            font-weight: 400;
            color: #404041;
            letter-spacing: -0.36px;
            max-width: 100%;

        }
        /* word-wrap: break-word;/ */
    }

`;
const ModalStyles = styled.div`
    min-width: 820px;
    min-height: 500px;
    max-width: 820px;
    max-height: 644px;
    display: flex;
    .active{
        border: 1px solid #53ACAA;

    }
    .secondSection{
        display: flex;
        flex-direction: column;
        /* width: 50%;
         */
        flex : 1;
        /* height: 644px;\ */
        height: 100%;
    justify-content       :space-between ;
        .price{
             font-weight: 800;
             font-size: 38px;
            letter-spacing: 0px;
            color: #53ACAA;
            display: flex;
            align-items: center;
            gap: 10px;
            >span{
                font-size: 23px;
                text-decoration: line-through;
                color: #AFAFAF;
            }
            margin-bottom: 33px;
        }
        .title{
            font-size: 26px;
            font-weight: 700;
            color: #404041;
            letter-spacing: -0.65px;
            max-width: 100%;
            margin-bottom: 10px;
        }
        .buttons{
            align-self: stretch;
            width: 100%;
            /* height: 210px; */
            display: flex;
            align-items: center;
            gap: 10px;
            flex-direction: column;
            margin-bottom: 0px;

        }
    }
    .firstSection{
        display: flex;
        flex-direction: column;
        width: 50%;
        height: 100%;
        .title{
            font-size: 26px;
            font-weight: 700;
            color: #404041;
            letter-spacing: -0.65px;
            max-width: 100%;
            margin-bottom: 10px;
        }
        a{
            text-decoration: underline;
            color: #EE5F3B;
            cursor: pointer;
        }
        >.reviews{
            display: flex;
            width: 100%;
            margin-top: 28px;
            .starts{
                display: flex;
                align-items: center;
                gap: 3px;
                margin-right: 13px;
            }
        }
        >.Imagescont{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            margin-top: 28px;
            .main{
                width: 356px;
                height: 356px;
                margin: 0 auto;
                display: flex;
                    align-items: center;
                    justify-content: center;
                .main_image{
                    content: "";
                    width: 80%;
                    height: 80%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    &:hover {
                        cursor: zoom-in;
                        background-size: 105% 105%;
                        
                    }
                }
            }
            .indexing{
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 113px;
                width: 100%;
                gap: 10px;
                justify-content:center;
                >div{
                    width: 113px;
                    height: 113px;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    > img{
                    width: 70%;
                    object-fit: cover;
                }
                }
            }

        }
    }
`;


export  function Button({
    text,
    onClick,
    type,
    className,
    disabled,
    style,
    ...props
}) {
  return (
    <ButtonStyle onClick={onClick} type={type}
    >{text}</ButtonStyle>
  )
}

const ButtonStyle = styled.button`
    width: 100%;
    height: 50px;
    background: #68AAA9 0% 0% no-repeat padding-box;
    border-radius: 6px; 
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border: ${props => props.type === "primary" ? "none" : "2px solid #68AAA9"};
    letter-spacing: -0.4px;
    background: ${props => props.type === "primary" ? "#68AAA9 0% 0% no-repeat padding-box" : "white"};
    color: ${props => props.type === "primary" ? "white" : "#53ACAA"};
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    &:hover{
        color: ${props => props.type === "primary" ? "#53ACAA" : "white"};
        background: ${props => props.type === "primary" ? "white" : "#68AAA9 0% 0% no-repeat padding-box"};
        border: 2px solid #68AAA9;

    }
    &:disabled{
        background: #AFAFAF;
        cursor: not-allowed;

    }

`;