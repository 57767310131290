import React , {useCallback , useEffect, useState} from "react";
import { slide as Menu } from "react-burger-menu";
import Img from "../assets/images/render7_2048x.png";
import book from "../assets/images/book3c_2048x.png";
import html2pdf from 'html2pdf.js';

const InvoiceDetailSidebar = ({
  isOpen,
  setIsOpen,
  invoiceDetail,
  invoiceIndex,
}) => {
    const [total, settotal] = useState(0)
    const [isDownloading, setIsDownloading] = useState(false);
  //   const { firstname, lastname, city, postcode, region, telephone, street } = invoiveDetail?.shipping_address;
  //   const { product_name, quantity_ordered,status , product_image } = invoiveDetail?.items[invoiceIndex]
  //   const { number } = invoiveDetail
  //   const { value } = invoiveDetail?.total?.grand_total
  useEffect(() => {
    invoiceItemsTotal()
  }, [invoiceDetail])
  
  // get the invoice items price total
    const invoiceItemsTotal = 
    
      () => {
        var total = 0;
        invoiceDetail?.[0]?.items.map((item) => {
            total += (item.product_sale_price?.value * item.quantity_invoiced);
            }
        );
        settotal (total);
      }
    
      function downloadPDF() {
        setIsDownloading(true);
        const element = document.getElementById('component-to-download');
        const options = {
          margin: [0, 0],
          filename: 'invoice.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };
        html2pdf().set(options).from(element).save().then(() => {
            setIsDownloading(false);
          });
      }
      

  return (
    <Menu
      isOpen={isOpen}
      customBurgerIcon={false}
      onClose={() => setIsOpen(false)}
      right
    >
      {invoiceDetail?.length !== 0 ? (
        <div  className="mt-[30px] p-2 flex flex-col  gap-1">
            <div className="text-2xl font-semibold mb-2 flex justify-between items-center">
                Invoice Details
            <button 
            onClick={downloadPDF}
            className="ternary_btn text-sm">
               {isDownloading ? "Downloading.." : "Download"}
            </button>
            </div>
        <div id={"component-to-download"} >

          {invoiceDetail[0]?.items.map((item, index) => {
            return (
              <div className="invoiceCard flex flex-col gap-2 shadow drop-shadow-md p-3 mb-2 ">
                <div className="w-full flex items-center justify-between text-sm">
                  <div className=" font-medium ">SKU</div>
                  <div className="font-semibold text-dark">
                    {item.product_sku}
                  </div>
                </div>
                <div className="w-full flex items-center justify-between text-sm">
                  <div className=" font-medium ">Price</div>
                  <div className="font-semibold text-dark">
                  £{item.product_sale_price?.value}
                  </div>
                </div>
                <div className="w-full flex items-center justify-between text-sm">
                  <div className=" font-medium ">Qty invoiced</div>
                  <div className="font-semibold text-dark">
                  {item.quantity_invoiced}
                  </div>
                </div>
                <div className="w-full flex items-center justify-between text-sm">
                  <div className=" font-medium ">Subtotal</div>
                  <div className="font-semibold text-dark">
                  £{item.quantity_invoiced * item.product_sale_price?.value}
                  </div>
                </div>
                <hr/>
                <div className="w-full flex items-center justify-between text-sm">
                  <div className=" font-medium ">Total</div>
                  <div className="font-semibold text-dark">
                  £{item.quantity_invoiced * item.product_sale_price?.value}
                  </div>
                </div>

              </div>
            );
          })}
          <hr className="mt-3"/>
            <div className="w-full flex items-center justify-between text-sm">
                  <div className=" font-medium ">Shipping & Handling</div>
                  <div className="font-semibold text-dark">
                  {total > 30 ?"Free" :"£"+5.00}
                  </div>
                </div>
                <div className="w-full flex items-center justify-between text-sm">
                  <div className=" font-medium ">Grand Total</div>
                  <div className="font-semibold text-dark">
                  £{total > 30 ? total 
                  : total + 5}
                  </div>
                </div>
        </div>
        </div>
      ) : (
        <div
          style={{ display: "flex" }}
          className="bg-light h-[100vh] flex  items-center justify-center"
        >
          No Invoice Found
        </div>
      )}
    </Menu>
  );
};
export default InvoiceDetailSidebar;
