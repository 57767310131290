import React, { useCallback, useEffect, useRef, useState } from 'react';
import Splide from '@splidejs/splide';
import Best from '../assets/images/icons/icon_bestseller.svg';
import Arival from '../assets/images/icons/icon_new-arrivals.svg';
import Offer from '../assets/images/icons/icon_offers.svg';
import Quran from '../assets/images/icons/icon_book-quaran-hadith.svg';
import historyIcon from '../assets/images/icons/icon_book-history-society.svg';
import child from '../assets/images/icons/icon_children-teen.svg';
import Book from '../assets/images/icons/icon_book-islam-life.svg';
import productImg from '../assets/images/small_2048x.png';
import bgShapeLeft from '../assets/images/bg_shape-left.svg';
import bgShapeBottom from '../assets/images/bg_shape-bottom.svg';
import bgShapeRight from '../assets/images/bg_shape-right.png';
import fvrtIcon from '../assets/images/icons/icon_favorite.svg';
import ReactStars from 'react-rating-stars-component';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useOutsideClick from "../hooks/useOutsideClick";

import {
  addProductCategory,
  addSubHeading,
  setSelectedFiltersQuery,
} from '../redux/products/productSlice';
import { getSliderOffers } from '../services/home';
import { toast } from 'react-toastify';
import AddToCart from './AddToCart';

export default function MegaMenu({ category , sliderOffers ,i }) {
  const dispatch = useDispatch();
  let splide = null;
  let navigate = useNavigate();

  const sliderRef = useRef(null);

  const { categories } = useSelector(state => state.products);

  const setMenuCategoryId = useCallback(
    id => {
      localStorage.setItem('category_id', id);
      dispatch(
        setSelectedFiltersQuery({
          key: 'category_id',
          value: {
            eq: id,
          },
        })
      );
    },
    [dispatch]
  );
  const simplifyName = (name , )=>{
    // replace space with - and remove ' 

    return name.replaceAll(/'/g, " ").toLowerCase().replaceAll(" ", "-") ;
    // name.replace("'", " ").toLowerCase().replace(" ", "-");
  }
  const addMenuCategory = useCallback(
    async category => {
      try {
        dispatch(addProductCategory(category));
        navigate(`/products/${category.name}`);
      } catch (err) {}
    },
    [dispatch, navigate]
  );

  const clickHandler = categoryName => {
    const selectedCategory = categories.find(
      c => c.name.toLowerCase() === categoryName.toLowerCase()
    );
    setMenuCategoryId(selectedCategory.uid);
    addMenuCategory(selectedCategory);
  };



  useEffect(() => {
    if (sliderRef.current && sliderOffers.length > 0) {

      splide =  new Splide('#megamenu-slider'+ category.id, {
      height: '23rem',
      cover: false,
      heightRatio: 0,
      arrows: false,
      perPage: 1,
      autoplay: true,
      autoHeight: true,
      type: 'loop',
      interval: 1500,
      pagination: false,
      gap: '1rem',
      pagination: true,
    }).mount();
  }
  return () => {
    if (splide) {
      splide.destroy();
    }
  };
  } , [sliderOffers]);

  const setCategoryId = useCallback((id) => {
    dispatch(
      setSelectedFiltersQuery({
        key: 'category_id',
        value: {
          eq: id,
        },
      })
    );
  }, [category, dispatch]);

  const addCategory = useCallback(
    async category => {
      try {
        dispatch(addProductCategory(category));
        setCategoryId(category.id);
        navigate(`/products/${simplifyName(category.name)}`);
      } catch (err) {}
    },
    [dispatch, navigate, setCategoryId]
  );
  const addCategoryHead = useCallback(
    async (category, heading) => {
        console.log(heading)
      try {
        setCategoryId(heading.id);
    
        dispatch(addProductCategory(category));
        dispatch(addSubHeading(heading));
        navigate(`/products/${simplifyName(category.name)}/${simplifyName(heading.name)}`);
      } catch (err) {}
    },
    [dispatch, navigate, setCategoryId]
  );
  const addCategorySubHead = useCallback(
    async (category, subHead, heading) => {
        console.log(category ,subHead , heading)
      try {
        setCategoryId(heading.id);
        dispatch(addProductCategory(category));
        dispatch(addSubHeading(heading));
        navigate(`/products/${simplifyName(category.name)}/${simplifyName(subHead.name)}/${simplifyName(heading.name)}`);
      } catch (err) {}
    },
    [dispatch, navigate, setCategoryId]
  );

  const getHeadingIcons = useCallback(title => {
    switch (title) {
      case 'islam-life':
        return Book;
      case 'children-teen':
        return child;
      case 'qur-an-hadith':
        return Quran;
      case 'history-society':
        return historyIcon;
      default:
        return Book;
    }
  }, []);
  //  make a function that returns sorted array by  those have & in category childern name comes last
  const sortCategory =()=>{
      var newList = category.children.filter((item) => item.name.includes('&'));
       newList.sort((a , b)=>{
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      })
      var newList2 = category.children.filter((item) => !item.name.includes('&'));
      newList2 =  newList2.concat(newList);
      var newList3 =  newList2.filter((item) => item.children.length === 0);
      // var newList4 = newList2.filter((item) => item.children.length === 0);
      var list = newList3.filter((item) => (!["Offers" ,"New Arrivals" ,"Best Sellers"].includes(item.name)));

      return  list;
  }
  const sortCategoryOptions =()=>{
      var newList = category.children.filter((item) => item.name.includes('&'));
      var newList2 = category.children.filter((item) => !item.name.includes('&'));
      newList2 =  newList2.concat(newList);
      var newList3 =  newList2.filter((item) => item.children.length > 0);
      var newList4 = newList2.filter((item) => item.children.length === 0);
      var list = newList3.filter((item) => (!["Offers" ,"New Arrivals" ,"Best Sellers"].includes(item.name)));
      if (category.name === "Education") list = list.concat(newList4);
      return  list;
  }




  const renderCategoryList = useCallback(() => {
    return (
      <div className='w-full flex flex-wrap quran items-start'>
{           sortCategory().map((heading, key) => {
          return (
          <div className='col-lg-4 col-md-6 col-sm-12 quran ' key={key}>
            <p style={{color : "black"}} className="sub_heading text-black font-bold  " >
              
              <img  className="min-w-[18px] h-auto"src={heading.image || Book} alt="" />
              <span style={{color : "black"}} onClick={() => addCategoryHead(category,heading)}>
                {heading.name}
              </span>
            </p>
            <ul>
              {heading.children.map((subHeading, index) => (
                <li key={index} onClick={() => { addCategorySubHead(category ,heading ,subHeading)}}>
                  {subHeading.name}
                </li>
              ))}
            </ul>
          </div>)
    })}


          
        </div>
      );
  }, [category, addCategory]);
  const renderCategoryListOptions = useCallback(() => {
    return (
      <div className='w-full flex flex-wrap quran items-start'>
{           sortCategoryOptions().map((heading, key) => {
          return (
          <div className='col-lg-4 col-md-6 col-sm-12 quran  font-bold ' key={key}>
            <p style={{color : "black"}}  className="sub_heading text-black font-bold flex items-center whitespace-nowrap">
              
              <img  className="min-w-[18px] h-auto"src={heading.image || Book} alt="" />
              <span style={{color : "black"}}  onClick={() => addCategoryHead(category,heading)}>
                {heading.name}
              </span>
            </p>
            <ul>
              {heading.children.map((subHeading, index) => (
                <li key={index} onClick={() => addCategorySubHead(category ,heading ,subHeading)}>
                  {subHeading.name}
                </li>
              ))}
            </ul>
          </div>)
    })}


          
        </div>
      );
  }, [category, addCategory]);

  return (
    <div className="mega-menu-wrapper">
      <div className="row mega-menu-row">
        <div className="col-9 mega-col-9 flex flex-col items-start min-h-[600px]">
         {category.name !== "Education" &&  <div className="row links-row w-full mt-[15px]">{renderCategoryList()}</div>}

          <div className="row links-row w-full">
          {renderCategoryListOptions() }
          </div>
 
            <ul  className='w-full  flex items-center gap-5 quran ml-[25px] mt-auto  mb-2'>
              <li className="flex w-1/3 gap-3 font-bold  ">
                <img className='w-[18px]' src={Best} alt="" />
                Bestsellers
              </li>
              <li className="flex w-1/3 gap-3 font-bold cyan">
                <img className='w-[18px]'src={Arival} alt="" /> Arrivals
              </li>
              <li className="flex w-1/3 gap-3 font-bold ornage">
                <img className='w-[18px]'src={Offer} alt="" />
                Offers
              </li>
            </ul>
          <button  onClick={()=>  navigate(`/products/`)} className="view_products ml-[25px] ">View All Products</button>
          <img src={bgShapeLeft} className="bg-shape bg-shape-left" alt="" />
          <img
            src={bgShapeBottom}
            className="bg-shape bg-shape-bottom"
            alt=""
          />
          <img src={bgShapeRight} className="bg-shape bg-shape-right" alt="" />
        </div>

        {/* <!-- //slider --> */}
        <div className="col-lg-3 col-md-6 col-sm-12 offers-wrapper">
          <div className="offers-div">
            <h2 className="heading text-center">HOT OFFER</h2>
            <div style={{marginTop: "-120px"}} className="products-div megamenu-products-div w-full">
              
              <div ref={sliderRef} id={"megamenu-slider"+ category.id} className="splide splide-dimen">
                <div className="splide__track  w-full ">
                  <ul className="splide__list">
                    {sliderOffers.map((sliderOfferProduct , i) => {
                      return (
                      <li key={i}  className="splide__slide my-3   ">
                        
                        <div style={{marginLeft: "10px", marginRight: "10px", marginBottom: "10px" , maxHeight:"300px"}}   className="prod-description max-h-[200px]  overflow-hidden  flex flex-col">
                          <div className="img-div overflow-hidden">
                            <img alt="" className='-full' src={sliderOfferProduct.image.url} />
                          </div>
                          <div className="prod-name-mega-menu  ">
                            <p className="name text-lg">{sliderOfferProduct.name}</p>
                            <p className="writer text-xs">
                              {
                                sliderOfferProduct.azoom_product_specifications
                                  .publisher ? (
                                  <span>
                                    by 
                                    <span className='font-bold'>
                                    {" "}{sliderOfferProduct.azoom_product_specifications.publisher}

                                    </span>
                                  </span>) : null
                              }
                            </p>
                          </div>
                          <div className="ml-3 d-flex">
                          </div>

                          <p className="price">
                            £
                            {
                              sliderOfferProduct.price_range.minimum_price
                                .final_price.value
                            }
                          </p>
                          <div className='mt-auto py-1 w-full'>
                            <AddToCart
                              item={sliderOfferProduct}
                              classNames=" _btnTest w-[90%] my-2 "
                            />
                          <div className="flex w-[90%] mx-2 items-center justify-between">
                            <p className='whitespace-nowrap  text-xs text-[#53acaa] font-semibold '>FREE DELIVERY</p>
                            <img className='p-0' src={fvrtIcon} alt="" />
                          </div>
                          </div>
                        </div>
                      </li>
                    )
                          }
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="action-div">
              <button
                className="primary_btn"
                onClick={() => clickHandler('Sale')}
              >
                View All Offers
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
