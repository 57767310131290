import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchParam,
  setSelectedProduct,
} from "../redux/products/productSlice";
import * as routes from "../routePaths";
import searchIcon from "../assets/images/icon_search.svg";
import {
  getProductsInfo,
  getSearchProduct1,
  getSearchProductInfo,
} from "../services/product";
import { Bars } from "react-loader-spinner";
import useOutsideClick from "../hooks/useOutsideClick";

import {
  addProducts,
  setfetchingProducts,
} from "../redux/products/productSlice";
import { capitalize } from "lodash";
function SearchInput({
  id = "",
  className = "flex search-input items-center",
}) {
  const { searchParam } = useSelector((state) => state.products);
  const [show, setshow] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalRef = useRef();

  const [items, setitems] = useState([]);
  useOutsideClick(modalRef, () => show && setshow(false));

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      localStorage.removeItem("category_id");
      navigate({
        pathname: "/products",
        search: `?search=${searchInput}`,
      });
    }
  };
  useEffect(() => {
    let isFetching = true;

    if (searchInput) {
      if (isFetching) {
        getSearchProducts(searchInput);
      }
      dispatch(setSearchParam(searchInput));
      setshow(true);
    } else {
      setshow(false);
    }

    return () => {
      isFetching = false;
    };
  }, [searchInput]);

  const filterProducts = (products , search) => {

    var filteredProducts = products.filter((product) => {
      return product.name.toLowerCase().includes(search.toLowerCase());
    });
    var sfilteredProducts = filteredProducts.sort((a, b) => {
      return (
        a.name.toLowerCase().indexOf(search.toLowerCase()) -
        b.name.toLowerCase().indexOf(search.toLowerCase())
      );
    });

    return sfilteredProducts;
  };

  const getSearchProducts = useCallback(
    async (search) => {
      setloading(true);

      try {

        let { products } = await getSearchProduct1(search);
        var t =  filterProducts(products?.items?.slice(0, 20) , search);
        setitems([...t]);

      } catch (err) {
        console.log(err);
      }
      setloading(false);

    },
    [dispatch]
  );

  const gethighlitedText = (text) => {
    var s = text.toLowerCase().split(searchInput.toLowerCase());
    return (
      <div>
        {s.map((itemtmp, i) => {
          var item = itemtmp.split(" by ")[0];
          return (
            <span className="text-black" key={i}>
              {i === 0 ? capitalize(item) : item}
              {i < s.length - 1 && (
                <span className="text-[#53acaa]">
                  {i === 0 ? capitalize(searchInput) : searchInput}
                </span>
              )}
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <div className={className}>
      {show && (
        <div
          ref={modalRef}
          className="searchDrop shadow-xl overflow-y-scroll scrollbar-thin scrollbar scrollbar-thumb-gray-300 scrollbar-track-gray-100"
          onClick={() => setSearchInput("")}
        >
          {loading ? (
            <div className="flex items-center justify-center w-full h-full">
              <span className="  flex justify-between">
                <Bars
                  height="50"
                  width="50"
                  radius="9"
                  color="#53acaa"
                  ariaLabel="three-dots-loading"
                  wrapperStyle
                />
              </span>
            </div>
          ) : (
            <div className={"w-full flex flex-col overflow-y-auto "}>
              {items.map((item, i) => {
                return (
                  <Link
                    to={`/products?sku=${item.sku}`}
                    className="item gap-2 text-sm "
                    key={i}
                  >
                    <img src={item.image.url} alt="" />

                    {gethighlitedText(item.name)}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      )}
      <img src={searchIcon} className="search-icon" alt="" />
      <input
        type="text"
        placeholder="Find anything home.."
        onChange={(e) => {
          setSearchInput(e.target.value);

          dispatch(setSearchParam(e.target.value));
        }}
        className="bg-[#fafafa]"
        value={searchInput}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}

export default SearchInput;
