import React, { useEffect, useState } from "react";
import DefaultLayout from "../../common/DefaultLayout";
import book from "../../assets/images/book3c_2048x.png";
import OrderDetailSidebar from "../../common/OrderDetailSidebar";
// import InvoiceDetailSidebar from "../../common/InvoiceDetailSidebar";
import search from "../../assets/images/icon_search-white.png";
import filter from "../../assets/images/icon_filter.png";
import Modal from "react-modal";
import closeicon from "../../assets/images/icon_close-menu.png";
import { ReactComponent as InfoIcon } from "../../assets/images/icons/icon_info.svg";
import itemimg from "../../assets/images/render7_2048x.png";
import Breadcrumbs from "../../common/Breadcrumbs";
import * as routes from "../../routePaths";
import { getOrderInfo } from "../../services/order";
import { useDispatch, useSelector } from "react-redux";
import { addOrder } from "../../redux/orders/orderSlice";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import InvoiceDetailSidebar from "../../common/InvoiveDetailSidebar";
import AddToCart from "../../common/AddToCart";
import { addItemsToCart, getRewardPts } from "../../services/cart";
import { toast } from "react-toastify";
import { addToCart } from "../../redux/cart/CartSlice";
import facebook from "../../assets/images/icons/logo_facebook.svg";
import Jannah from "../../assets/images/jannah.png";

import instagram from "../../assets/images/icons/logo_instagram.svg";
export default function RewardPage() {
  const [orderType, setOrderType] = useState("my-points");
  const [rewardData, setrewardData] = useState(null);

  const customer = useSelector((state) => state?.customer);
  const { rewardPoints } = useSelector((state) => state?.customer);
  useEffect(() => {
    const getRewards = async () => {
      setrewardData(rewardPoints);
    };
    getRewards();
  }, [customer]);
  console.log("customer", customer);
  const orderTypes = [
    {
      id: "my-points",
      title: "My Reward Points",
    },

    {
      id: "history",
      title: "My History",
    },
  ];
  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  return (
    <DefaultLayout hasOptionsPartners={true}>
      <div className="container main-container pt-4">
        <div className="mb-4">
          <Breadcrumbs
            paths={[
              { url: routes.homepage, text: "Home" },
              { url: routes.productsPage, text: "My Account" },
              { url: "/rewards", text: "My Reward Points" },
            ]}
          />
        </div>
        {/* dheading div --> */}
        <div className="main-heading-div row mb-5">
          <div className="col-md-8 col-12">
            <div className="heading-flex">
              <h1 className="heading ">
                <b>My Reward Points</b>
              </h1>
              <button className="btn filters-btn">
                <img src={filter} />
                Filters
              </button>
            </div>
          </div>
        </div>
        {/* dul --> */}
        <ul className="tabs-div row mt-5 mb-5">
          {orderTypes?.map((type) => (
            <li
              className={orderType === type.id ? "active" : ""}
              key={type.id}
              onClick={() => setOrderType(type.id)}
            >
              {type.title}
            </li>
          ))}
        </ul>
        {/* dorder list  --> */}
        {/* <!-- 1 --> */}
        {orderType === "my-points" && (
          <div className="order-list-div">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="order-list-heading">
                  <h3 className="heading">
                    Current Balance
                    <span className="ml-3 text-2xl items-center">
                      <img src={Jannah} className="w-12 mx-2" alt="" />
                      {rewardData?.balance ? rewardData.balance : 0} Pts
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        )}
        {orderType === "share-save" && (
          <div className="order-list-div">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="order-list-heading">
                  <h3 className="heading">My Reward Points</h3>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="w-full p-2">
                <div className="order-list-heading w-full">
                  <h6 className="heading">
                    Share the referral link in the social media
                  </h6>
                </div>
                <hr />
              </div>
            </div>
            <div className="row mt-2 flex gap-5 p-2">
              <Link
                className="flex items-center text-dark gap-3"
                target="_blank"
                to={"https://www.facebook.com/azoomuk"}
              >
                <img src={facebook} className="" alt="" />
                Share on Facebook
              </Link>
              <Link
                className="flex items-center text-dark gap-3"
                target="_blank"
                to={"https://www.instagram.com/azoomuk/"}
              >
                <img src={instagram} className="" alt="" />
              </Link>
              Share on Instagram
            </div>
            <div className="row mt-2">
              <div className="w-full p-2">
                <div className="order-list-heading w-full">
                  <h4 className="heading">
                    You may send this link in your messages to anyone
                  </h4>
                </div>
                <hr />
              </div>
            </div>
            <div className="row mt-2">
              <div className="w-full p-2">
                <div className="order-list-heading w-full">
                  <a href={"https://www.facebook.com/azoomuk"}>LinkToSHARE</a>
                </div>
                <hr />
              </div>
            </div>
          </div>
        )}
        {orderType === "history" && (
          <div className="order-list-div">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="order-list-heading">
                  <h3 className="heading">My Reward Points</h3>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="w-full p-2">
                {/* <div className="order-list-heading w-full">
                  <h6 className="heading">
                    To unsubscribe from points expiring notification click
                    <a className="ml-1" href="#">
                      Unsubscribe
                    </a>
                  </h6>
                </div> */}
              </div>
            </div>
            <div className="row p-2">
              <div className="w-full">
                <div className="order-list-heading">
                  <h4 className="heading">Transactions History</h4>
                </div>
              </div>
              {rewardData?.transactions?.lenght !== 0 ? (
                <table className="table ">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Comment</th>
                      <th scope="col">Points</th>
                      <th scope="col">Created</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rewardData.transactions?.map((item, index) => (
                      <tr key={index}>
                        <th scope="row">{item?.transaction_id}</th>
                        <td className="flex items-center gap-2"> 
                          <img className="w-10 h-10 " src={Jannah} alt="" />

                          {item?.comment}
                        </td>
                        <td >{item?.amount} pts</td>
                        <td>{moment(item?.created_at).fromNow()}</td>
                        <td>{!item?.is_expired ? "active" : "expired"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <span>there are no transactions yet</span>
              )}
            </div>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
}
