import React from 'react'
import DefaultLayout from '../../common/DefaultLayout';
import i1 from '../../assets/images/icon_quote-small.png'
import i2 from '../../assets/images/icon_quote-small.png'
import Contact from './contact_form'

export default function about_whyus() {
    return (
<DefaultLayout>
<div className="container-fluid">
        <div className="row ">
            <div className="col-12 help_head">
                <h1>Why Us?</h1>
            </div>
        </div>
    </div>
    <div className="container">
    <p className='route'>Home  /  About  /  <span>Why Us</span></p>

        <div className="row help_main">
            <div className="col-12 col-md-7">
                <div className="pr-4">
                    <h1>Why Us?</h1>

                    <p>Since our inception, we've been dedicated to scouring the globe for the finest books, but our mission goes beyond that. So why choose Azoom? Our team consistently expands our inventory and uncovers new treasures, gifts, and categories that are guaranteed to bring a smile to your face. We strive to be the kind of online store that we ourselves would want to shop at, and we are committed to maintaining that standard as your new favourite shopping destination.
                    </p>
                    <p>
                    
                        Our unique blend of a  <span className="grey_span">customer-centric 
                            </span> approach and a <span className="grey_span">passion</span> for sourcing products, combined with our cultural heritage, sets Azoom apart. Instead of simply selling products for profit, we actively seek out items that align with your needs and preferences. This results in an online store that is 
                            <span className="grey_span"> authentic </span>, 
                            <span className="grey_span"> honest </span>
                        , 
                            and 
                            <span className="grey_span"> true </span>
                             to every aspect of Islamic culture. You can trust us with your time and money, knowing that we value your satisfaction and peace of mind above all else.
                    
                    </p>
                    <p>In addition to our impressive selection of products and gifts, we're constantly pursuing new ventures to help our community grow and flourish. Our top priority is to collaborate with authors and assist them in publishing their work on our platform. This initiative allows us to bring fresh voices, perspectives, and innovative ideas to the Islamic community. By choosing Azoom, you can expect to discover unparalleled products and services that you won't find anywhere else.</p>
                    <div className="gdiv">
                        <img className="i1" src={i1} alt="ixon"/>
                        <img className="i2" src={i2} alt="ixon"/>
                        <p>This is why you should try us, <br />
                            and we’re ready when you are. </p>
                    </div>
                </div>
            </div>
            <Contact/>
        </div>
    </div>





</DefaultLayout>
        )
}
