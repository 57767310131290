import "./style.scss";
import { forwardRef } from "react";
import clsx from "clsx";

const Checkbox = forwardRef(
  ({special= false, label = "", color = "", size = "", className = "", ...rest }, ref) => {
    return (
      <label className={className + " round"}>
        <input
          ref={ref}
          id={`filter-selection-checkbox-${label}`}

          // className={clsx(
          //   "app-checkbox",
          //   color && `app-checkbox--${color}`,
          //   size && `app-checkbox--${size}`
          // )}

          type="checkbox"
          {...rest}
        />
        <label htmlFor={`filter-selection-checkbox-${label}`}></label>

        <div className={"ml-3 " + (special ? " text-[#EE5F3B] " : "")}>{label}</div>
      </label>
    );
  }
);

export default Checkbox;
