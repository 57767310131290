import React, { useEffect ,useState } from 'react';
import Splide from '@splidejs/splide';
import ItemCard from './ItemCard';
import { set } from 'lodash';
// import { current } from '@reduxjs/toolkit';

export default function SimilarItems({ relatedProducts }) {
  const [current, setcurrent] = useState(-1)
  
  useEffect(() => {

    new Splide('#similar-items-slider', {
      height: '20rem',
      cover: false,
      heightRatio: 0,
      arrows: true,
      perPage: 5,
      // type: 'loop',
      // interval: 3000,
      pagination: false,
      gap: 20,
      breakpoints: {
        992: {
          perPage: 3,
        },
        768: {
          perPage: 2,
          pagination: true,
        },
      },
    }).mount();
  });

  return (
    <div className="container mt-5 similar-items-wrapper">
     <h6 className="text-center shop-text mb-5 heading-text">
     Compare Similar Items{' '}
      </h6>
      <div className="row">
        <div className="col-12 products-div">
          <div id="similar-items-slider" className="splide">
            <div className="splide__track h-[400px]">
              <ul className="splide__list ">
                {relatedProducts.map((product, i) => 
                {
                  if (product)
                    return(
                      <li  key={i} className="splide__slide">
                        <ItemCard onClick={()=>setcurrent(i)}selected={current === i} item={product} />
                      </li>
                    )
                    return<li key={i} className="splide__slide">
                      </li>
                })}

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
