import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customer: null,
  rewardPoints: null,
  logedIn: false,
  previousRoute: '/',
  loading: false,
  favourites: null,
};

export const customerSlice = createSlice({
  name: 'customerInfo',
  initialState,
  reducers: {
    addCustomer: (state, action) => {
      const { payload } = action;
      state.customer = payload;
    },
    setCustomerFetching: (state, { payload }) => {
      state.loading = payload;
    },
    setLogedIn: (state, { payload }) => {
      state.logedIn = payload;
    },
    setRewardPoints: (state, { payload }) => {
      state.rewardPoints = payload;
    },
    addCustomerAddress: (state, action) => {
      const { payload } = action;
      state.customer = {
        ...state.customer, 
        addresses : [payload]
      };
    },
    addEmail: (state, action) => {
      const { payload } = action;
      state.customer = {
        ...state.customer,
        email : payload
      };
    },
    addCustomerWishlist: (state, action) => {
      const { payload } = action;
      state.customer.favourites = payload;
    },
    setPreviousRoute: (state, action) => {
      const { payload } = action;
      state.previousRoute = payload;
    },
    setFavourites: (state, action) => {
      const { payload } = action;
      state.favourites = payload;
    }
  },
});
 
export const {
  addEmail,
  setLogedIn,
  addCustomer,
  setCustomerFetching,
  setRewardPoints,
  addCustomerAddress,
  addCustomerWishlist,
  setPreviousRoute,
  setFavourites
} = customerSlice.actions;

export default customerSlice.reducer;
