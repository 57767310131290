import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSubHeading: null,
  filters: null,
  products: null,
  selectedProduct: null,
  selectedFilters: {},
  selectedFiltersQuery: {
    category_id: {
      eq: "",
    },
  },
  homeSliderProducts: [],
  recommendedProducts: [],
  categories: [],
  outletAndDiscountCategories: [],
  loading: true,
  searchParam: null,
  recentlyViewedProducts: [],
  suggestedProducts: [],
  selectedCategory: null,
  currentPage: 1,
  pageSize: 20,
};

export const productSlice = createSlice({
  name: 'productInfo',
  initialState,
  reducers: {
    addProductCategory: (state, { payload }) => {
      state.selectedCategory = payload;
    },

    setfetchingProducts: (state, { payload }) => {
      state.loading = payload;
    },
    addSubHeading: (state, { payload }) => {
      state.selectedSubHeading = payload;
    },
    addCategories: (state, { payload }) => {
      state.categories = payload;
    },
    addOutletAndDiscountCategories: (state, { payload }) => {
      state.outletAndDiscountCategories = payload;
    },
    addSliderProducts: (state, { payload }) => {
      state.homeSliderProducts = payload;
    },
    setSearchParam: (state, { payload }) => {
      state.searchParam = payload;
    },
    addRecommendedProducts: (state, { payload }) => {
      state.recommendedProducts = payload;
    },
    addFilters: (state, { payload }) => {
      state.filters = payload;
    },
    setSelectedFilters: (state, { payload }) => {
      state.selectedFilters = { ...state.selectedFilters, ...payload };
    },
    removeSelectedFilter: (state, { payload }) => {
      delete state.selectedFilters[payload.key];
    },
    setSelectedFiltersQuery: (state, { payload: { value, key } }) => {
      state.selectedFiltersQuery[key] = { ...value};
    },
    setSelectedProduct: (state, { payload }) => { 
      state.selectedProduct = payload;
    },
    updateRecentlyViewedProducts: (state, { payload }) => {
      state.recentlyViewedProducts = payload;
    },
    updateSuggestedProducts: (state, { payload }) => {
      state.suggestedProducts = payload;
    },
    removeSelectedFilterQuery: (state, { payload }) => {
      delete state.selectedFiltersQuery[payload.key];
    },
    addProducts: (state, { payload }) => {
      state.products = payload;
    },
    updatePageCount: (state, { payload }) => {
      state.pageSize = payload;
    },
    updateCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
});

export const {
  addProductCategory,
  addSubHeading,
  addFilters,
  addProducts,
  setSelectedFilters,
  setSelectedFiltersQuery,
  removeSelectedFilterQuery,
  removeSelectedFilter,
  setfetchingProducts,
  setSelectedProduct,
  addCategories,
  addOutletAndDiscountCategories,
  addSliderProducts,
  addRecommendedProducts,
  setSearchParam,
  updateRecentlyViewedProducts,
  updateSuggestedProducts,
  updatePageCount,
  updateCurrentPage,
} = productSlice.actions;

export default productSlice.reducer;
