export const homepage = "/";
export const helpPage = "/help/:id";
export const deliveryInfo = "/delivery-policy";
export const returnPolicy = "/help/return-policy";
export const productsPage = "/products/:cat";
export const productsPagep = "/products/:cat/:subcat";
export const productsPagesub = "/products/:cat/:subcat/:subSubCat";
export const  products = "/products";
export const  rewardPolicy = "/help/rewards-policy";
// export const productsSubCategoryPage = "/products/:category/:subCategory";
export const productDetail = "/product?sku=:sku";
export const aboutPage = "/about";
export const expertisePage = "/about/expertise";
export const contactPage = "/about/contact";
export const ideaPage = "/about/gotanidea";
export const chooseUsPage = "/about/whyus";
export const orders = "/orders";
export const login = "/login/email";
export const pass = "/login/password";
export const c_pass = "/register";
export const order_success = "/orders/success";
export const checkout_info = "/checkout/info";
export const checkout_review = "/checkout/review";
export const returnCenter = "/return-center";
export const myFavourites = "/favourites";
export const reviewProduct = "/product-review";
export const myReviewProduct = "/my-product-review";
export const rewardPage = "/rewards";
