import React, { useEffect, useRef, useState } from "react";
import Splide from "@splidejs/splide";
import Tag1 from "../../assets/images/Group 2516.png";
import Tag2 from "../../assets/images/badgetag.png";
import group0 from "../../assets/images/slider_tajweed-quran-mobile.jpg";
import group1 from "../../assets/images/slider_new-products-mobile.jpg";
import group2 from "../../assets/images/slider_sunnah-mobile.jpg";
import group3 from "../../assets/images/slider_sealed-nectar-mobile.jpg";
import group4 from "../../assets/images/slider_majestic-quran-mobile.jpg";
import group5 from "../../assets/images/slider_desidoll-mobile.jpg";
import group6 from "../../assets/images/slider_children-mobile.jpg";
import { saveAs } from "file-saver";
import { ReactComponent as Magestic } from "../../assets/images/magestic_slide.svg";
import { ReactComponent as Nectar } from "../../assets/images/Nectar.svg";
import { ReactComponent as Badge } from "../../assets/images/badge_muslim-world-league.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setloader } from "../../redux/loaderSlice";
import { data } from "./data.js";
const NewHomeBanner = (props) => {
  const { homeSliderProducts } = useSelector((state) => state.products);
  const sliderRef = useRef(null);
  const dispatch = useDispatch();

  const [loadCount, setloadCount] = useState(0);
  let splide = null;
  useEffect(() => {
    const images = document.querySelectorAll("img");
    let loaded = 0;

    const handleImageLoad = (e) => {
      loaded++;

      if (loaded === images.length) {
        // setIsLoading(false);
      }
    };

    images.forEach((img) => {
      if (img.complete) {
        handleImageLoad();
      } else {
        img.addEventListener("load", handleImageLoad);
      }
    });

    return () => {
      images.forEach((img) => {
        img.removeEventListener("load", handleImageLoad);
      });
    };
  }, []);

  const getSlideData = async () => {
    var dat = data.map((item) => {
      if (item.id === 2363) {
        return {
          img_resp: group0,
          _product: item,
          id: item.id,
          name: "Colour Coded Tajweed Quran Collection",
          home_slider_image: item.home_slider_image,
          pos: "center",
          tag: "",
          magestic: null,
          by: null,
          price_tag: null,
        };
      } else if (item.id === 1944)
        return {
          img_resp: group1,
          _product: item,
          id: item.id,
          name: "Daily essentials for your family ",
          tag: "New Products",
          home_slider_image: item.home_slider_image,
          magestic: null,
          pos: "right",
          by: null,
          price_tag: null,
        };
      else if (item.id === 1774) {
        return {
          img_resp: group2,
          _product: item,
          id: item.id,
          name: "The Dar As-Sunnah Classical Collection",
          tag: "New Products",
          home_slider_image: item.home_slider_image,
          magestic: null,
          pos: "right",
          by: null,
          price_tag: null,
        };
      } else if (item.id === 1732) {
        return {
          img_resp: group3,
          _product: item,
          id: item.id,
          name:
            "One of the best Biography Books of the Prophet (Peace be upon him)",
          tag: "5 star",
          home_slider_image: item.home_slider_image,
          magestic: {
            img: <Nectar />,
            by: "Safiur Rahman Mubarakpuri",
          },
          pos: "right",
          bot: <Badge />,
          price_tag: {
            img: Tag2,
            price: "31.99",
            ex_price: "49.00",
          },
          by: null,
        };
      } else if (item.id === 1583) {
        return {
          img_resp: group4,
          _product: item,
          id: item.id,
          name:
            "A beautiful and rich English language work with new aspects and comments.",
          tag: "Best Seller",
          home_slider_image: item.home_slider_image,
          magestic: {
            img: <Magestic />,
            by: "Musharraf Hussain",
          },
          pos: "right",
          price_tag: {
            img: Tag1,
            price: "19.99",
            ex_price: "23.45",
          },
          by: null,
        };
      } else if (item.id === 1576) {
        return {
          img_resp: group5,
          _product: item,
          id: item.id,
          name: (
            <div>
              Desi Dolls <br /> It was the perfect Eid Gift
            </div>
          ),
          tag: "New",
          home_slider_image: item.home_slider_image,
          magestic: null,
          pos: "right",
          by: "Razia Begum",
          price_tag: null,
        };
      } else if (item.id === 1475) {
        return {
          img_resp: group6,
          _product: item,
          id: item.id,
          name: "Azoom children section",
          tag: "New",
          home_slider_image: item.home_slider_image,
          magestic: null,
          pos: "right",
          by: null,
          price_tag: null,
        };
      }
    });
    sethomeSlider([...dat.reverse()]);
  };
  const [homeSlider, sethomeSlider] = useState([]);
  useEffect(() => {
    getSlideData(homeSliderProducts);
  }, [homeSliderProducts]);
  const setloaderState = (e, i) => {
    if (i === 0) {
      dispatch(setloader(false));
    }
  };
  useEffect(() => {
    if (sliderRef.current && homeSlider.length > 0) {
      splide = new Splide("#image-slider", {
        height: "45rem",
        cover: false,

        heightRatio: 0,
        arrows: false,
        autoplay: true,
        type: "loop",
        interval: 3000,
      }).mount();
    }

    return () => {
      if (splide) {
        splide.destroy();
      }
    };
  }, [homeSlider]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getBadgeClass = (i) => {
    if (i === 3) return "tag-wrapper badge2";
    else if (i === 2) return "tag-wrapper badge1 strait-text ";
    else return "hidden";
  };

  return (
    <div className="row">
      <div className="col-12 slider-wrapper">
        <div id="image-slider" ref={sliderRef} className="splide">
          <div className="splide__track p-0">
            <ul className="splide__list splide__list-style">
              {homeSlider?.map((product, i) => {
                return (
                  <li
                    key={i}
                    className="splide__slide bg-img image-slider-list-item"
                  >
                    <div className="w-full h-full position-relative">
                      {product?.price_tag && (
                        <div className={getBadgeClass(i)}>
                          <img
                            src={product?.price_tag?.img}
                            className="tag-img"
                            alt=""
                          />
                          <div className="tag-price text-sm ml-8">
                            <div className="tag-text mb-2 ml-[-15px] text-sm line-through">
                              {"£" + product?.price_tag?.ex_price}
                            </div>
                            <div className="ml-[-10px]">NOW ONLY</div>
                            <span className="text-[20px] text-white">
                              £{product?.price_tag?.price}
                            </span>
                          </div>
                        </div>
                      )}
                      <img
                        className="img"
                        onLoad={(e) => {
                          setloaderState(e, i);
                        }}
                        src={
                          windowWidth >= 700
                            ? product?.home_slider_image
                            : product?.img_resp
                        }
                        alt="dw"
                      />
                    </div>
                    {
                      <SliderData
                        windowWidth={windowWidth}
                        product={product}
                        i={i}
                      />
                    }
                    {product?.magestic && (
                      <div className="magestic_slide flex flex-col text-black">
                        {product?.magestic?.img}
                        <span style={{ color: "black" }} className="mt-[27px] ">
                          by{" "}
                          <span
                            style={{ color: "black" }}
                            className="font-bold"
                          >
                            {product.magestic.by}
                          </span>
                        </span>
                      </div>
                    )}
                    {product?.bot && (
                      <div className="  posbottom">{product?.bot}</div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export function SliderData({ product, i, windowWidth }) {
  return (
    <>
      <div
        className={
          "slider-textbox-right " +
          (product.pos === "center"
            ? " slide_center"
            : product?.magestic !== null && windowWidth < 700
            ? " topRight"
            : " ")
        }
      >
        {product?.tag &&
          (product?.tag === "5 star" ? (
            <div
              className="mb-2 font-bold whitespace-nowrap"
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
                color: "black",
              }}
            >
              5 star rating by our AZoom readers
              <div
                style={{
                  alignItems: "flex-start",
                  display: "flex",
                  color: "black",
                }}
                className="flex gap-2 mb-2"
              >
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
                <i className="fa fa-star" />
              </div>
            </div>
          ) : (
            <div
              className={"best-seller-tag flex " + (i === 1 ? "floatleft" : "")}
            >
              {product?.tag}
            </div>
          ))}
        <div className="main-text">{product?.name}</div>
        {product?.by && (
          <div style={{ color: "black" }} className="mt-[5px] ">
            by{" "}
            <span style={{ color: "black" }} className="font-bold">
              {product?.by}
            </span>
          </div>
        )}
        <Link
          to={`/products?sku=${product._product?.sku}`}
          state={{ product: product?._product }}
        >
          {" "}
          <button className="btn btn-shop-now">Shop Now</button>
        </Link>
      </div>
      <div className="resp-btn">
        <Link
          to={`/products?sku=${product._product?.sku}`}
          state={{ product: product?._product }}
        >
          {" "}
          <button
            style={{ bottom: product.id === 2363 ? "60%" : "14%" }}
            className="btn btn-shop-now-resp"
          >
            Shop Now
          </button>
        </Link>
      </div>
    </>
  );
}

export default NewHomeBanner;
