import React, { useState, useEffect, useCallback } from "react";
import ReactStars from "react-rating-stars-component";
import Specifications from "./Specifications";
import eye from "../../assets/images/icon_view.png";
import {ReactComponent as Gift} from "../../assets/images/icon_gift-card.svg";
import Bus_img from "../../assets/images/icon_free-delivery.png";
import Jannah from "../../assets/images/jannah.png";
import badge_img from "../../assets/images/badge_azoom-community.png";
import call from "../../assets/images/icon_phone.svg";
import { calculateAvg, calculateRatings } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import AddToCart from "../../common/AddToCart";
import { getFreeGift } from "../../services/home";
import { toast } from "react-toastify";
import UpSell, { UpsellLazyLoad } from "./Upsell";
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { HEADERS, URL } from "../../constants/api";
import axios from "axios";
import { setCart } from "../../redux/cart/CartSlice";

export default function ProductDetail({
  setShowOverviewModal,
  setShowReviewModal,
  setShowDnRModal,
  product,
  upSellProducts,
  childProduct, 
  setChildProduct,

}) {
  const [synopsis, setsynopsis] = useState(false);

  const { customer } = useSelector((state) => state.customer);
  const { shipping_addresses } = useSelector((state) => state.cart);
  const { id  ,include_free_gift } = useSelector((state) => state.cart);
  const cart = useSelector((state) => state.cart);
  const [productCount, setProductCount] = useState(1);
  const [contitySold, setcontitySold] = useState("");
  const [freeGift, setFreeGift] = useState(include_free_gift === "1" ? true : false);
  const [seeMore, setseeMore] = useState(false);
  const [selectedPro, setselectedPro] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const length = product?.reviews?.items.length;
  const percentage = calculateAvg(
    calculateRatings(product.reviews?.items),
    length
  ).toFixed(1); // TODO can be optimised
  const disPrice = (product) => {
    return product?.price_range?.minimum_price?.regular_price?.value;
  };

  const finalPrice = (product) => {
   
    return product?.price_range?.minimum_price?.final_price?.value * productCount;
  }
  const upsellprice = (product) => {
    var price = 0;
    for (const item of selectedPro)
    {
      price +=  upSellProducts[item].price_range?.minimum_price?.regular_price?.value;
    }
    
    return price;
  };
  const upsellSave = (product) => {
    var price = 0;
    console.log(selectedPro)
    for (const item of selectedPro)
    {
      price +=  upSellProducts[item].price_range?.minimum_price?.discount?.amount_off;
    }
    
    return price;
  };

  const description = product?.description?.html;

  const {
    azoom_product_specifications: { publisher },
  } = product;

  const handleProductCounter = (opertor) => {
    if (opertor === "plus") {
      setProductCount(productCount + 1);
    } else {
      setProductCount(productCount - 1);
    }
  };

  useEffect(() => {
    console.log(product)
    getQuantitySold()
    return () => {};
  }, [product]);

  const gqlForGettingCuantitySold = `query MyQuery {
    products(filter: {sku: {eq: "${decodeURIComponent(product?.sku.replace(/\+/g, " "))}"}}) {
      items {
        quantity_sold
      }
    }
  }`;
  const getQuantitySold = async () => {
    try {
      const { data } = await axios({
        url: URL,
        method: "POST",
        data: {
          query: gqlForGettingCuantitySold,
          // variables: {
          //   ...productData,
          // },
        },
        headers: HEADERS,
      });
      setcontitySold(data.data.products.items[0].quantity_sold);

    }
    catch (e) {
      console.log(e);
    }

   
    // alert(data.data.products.items[0].quantity_sold)
  };

  const variations = product?.variants;
  console.log("variation", variations);

  const selectedOption = (e) => {
    let cProduct = variations.filter(
      (variation) => variation.product.id === parseInt(e.target.value)
    );
    cProduct = cProduct[0]?.product;
    setChildProduct(cProduct);
  };

  const variationOptions = () => {
    return variations.map((variation) => (
      <option
        className="p-2"
        key={variation.product.id}
        // onClick={() => selectedOption(variation.product)}
        value={variation.product.id}
      >
        {variation.attributes[0].label}
      </option>
    ));
  };

  const variationSelectBox = () => {
    return (
      <select style={{height: "40px"}}
        className=" col border counter_div mb-3 p-2"
        onChange={selectedOption}
      >
        <option value="" disabled selected>Please make a selection</option>
        {variationOptions()}
      </select>
    );
  };
  useEffect(() => {
    setFreeGift(include_free_gift === "1" ? true : false);
  }, [include_free_gift]);
  useEffect(() => {
    console.log("include_free_gift", include_free_gift);

       dispatch(setCart({
          ...cart,
          include_free_gift: freeGift ? "1" : "0",
        }));
  }, [freeGift]);
  const handleFreeGiftClick = async () => {
    try {
      const { setIncludeFreeGift } = await getFreeGift({
        cart_id: id,
        include_free_gift: !freeGift ? "1" : "0",
      });
      if (setIncludeFreeGift.quote_id) {
        setFreeGift(!freeGift);

        toast.success(`Free gift ${!freeGift ? "added" : "removed"}`);
      }
    } catch (e) {
      toast.error("Unable to free gift");
    } finally {
      setFreeGift(!freeGift);
    }
  };

  return (
    <div className="product_details_div">
            <Modal
        className="reviews-modal-wrapper border rounded-lg max-h-[80vh] min-h-[30vh]  text-center  shadow-lg font-semibold"
        isOpen={synopsis}
        onRequestClose={() => setsynopsis(false)}
        >
        <div 
                
                dangerouslySetInnerHTML={{ __html: product?.synopsis ? product?.synopsis : "No Synopsis Available"}}
                >

</div>          
      </Modal>
      <div className="basic-product-info">
        <p>{childProduct ? childProduct.name : product.name}</p>
        <p className="instock">
          <i className="fas fa-chevron-down"></i>
          {product.stock_status?.replace("_", " ") || "In Stock"}
        </p>
        {publisher !=="" && (
          <p className="seemore">
            See more by <Link className="ml-2" to="/products">{publisher}</Link>
          </p>
        )}
        {percentage !== "0.0" &&<div className="rating-stars rating-stars-wrapper mt-4">
          <ReactStars
            count={5}
            size={14}
            value={parseFloat(percentage)}
            edit={false}
            isHalf={true}
            emptyIcon={<i className="far fa-star" />}
            halfIcon={<i className="fa fa-star-half-alt" />}
            filledIcon={<i className="fa fa-star" />}
            activeColor="#000000"
            color="black"
            />
          <span>{percentage}</span>
          <span className="total-reviews">{length} reviews</span>
        </div>}
            {
              (product?.bestseller === 1) &&  <div style={{justifyContent :"flex-start"}}  className="w-full  flex items-center  ">
                <div className="highlight  ribbon">
                  #1 Best Seller 
                </div>
                <span className="font-bold ml-10">
                More than <span className="text-[#002060]">
                  {contitySold} sold
    
                  </span>
      
                </span>
                </div>
              
            }
      </div>
      <p className="price mb-0">
        £{((  finalPrice( childProduct ? childProduct : product) )+ upsellprice()).toFixed(2)}
       {(finalPrice( product) !== disPrice( product)) &&  <del>£{disPrice( product).toFixed(2)}</del>}
       {customer?.group &&  customer?.group.group_name === "Wholesale" && 
       <span style={{justifyContent:"center" , color : ""}}className="wholesale ml-3 text-dark rounded-full w-7 h-7 flex items-center justify-center bg-[#FCD12A] ">
        <div
          className="tooltipa"
         >
Wholesale Price
        </div>
        W</span> }
      </p>
      <div style={{justifyContent : "flex-start"}} className="flex items-center gap-2 m-2 font-medium text-[#002060] max-sm:flex-col">
      {(product?.price_range?.minimum_price?.discount?.amount_off !== 0) && <p className="font-medium text-[#002060] mb-0">
      
        Save £{(parseFloat(product?.price_range?.minimum_price?.discount?.amount_off * productCount) + parseFloat(upsellSave())).toFixed(2)}
      </p>}
            <div className="max-sm:text-xs">

            <img
              className="w-12 mr-2"
              src={Jannah}
              />
              Collect  <strong className="font-bold">{(finalPrice( product) + upsellprice()).toFixed(0)}</strong> Zoom points with this purchase
            </div>
      </div>
      {true && (
        <div className="delivery-div ">
          <img src={Bus_img} alt="bus" />
          <div className="flex">
            <div>
              <p className="free_del">FREE DELIVERY</p>
              <p className="same_day">Same Day Dispatch to</p>
            </div>
            <div>
              <span>
              {shipping_addresses[0] ? (
                     (shipping_addresses[0].postcode ? shipping_addresses[0].postcode +
                      " - " : "")+
                      shipping_addresses[0].city
                    ) : (
                      <span
                        className=" cursor-pointer"
                        onClick={() => navigate("/login/email")}
                      >
                        Please Sign In
                      </span>
                    )}
                <i className="fas fa-chevron-down"></i>
              </span>
            </div>
          </div>
        </div>
      )}
      <button  onClick={
        ()=>setsynopsis(true)
      } className="view outline-none">
        {/* <div className="tooltipa">
                Unvailble for now
        </div> */}
        <img src={eye} alt="eye" />
        View Synopsis Preview
      </button>

      {variations && variationSelectBox()}
      {/* <div> */}
     
    
      {(upSellProducts !== null && upSellProducts?.length > 0 ) && (
       <UpSell
          upSellProducts={upSellProducts}
          selectedPro={selectedPro}
          setselectedPro={setselectedPro}

        />) 
      }
      <div className="container-fluid">
        <div className="row flex prod-deatils-buttons-wrapper">
          <div className="flex-1 border counter_div ">
            <button
              className="minus"
              disabled={productCount === 1}
              onClick={() => handleProductCounter("minus")}
            >
              <i className="fas fa-minus"></i>
            </button>
            <p>{productCount}</p>
            <button
              className="plus"
              onClick={() => handleProductCounter("plus")}
            >
              <i className="fas fa-plus"></i>
            </button>
          </div>
          <div className=" save-btn-resp-wrapper mx-2">
              <i className="far fa-heart"></i>
          </div>
          <div className="col-md-5 col-5 basket-btn">
            <AddToCart
              item={product}
              quantity={productCount}
              isVariable={childProduct ? true : false}
              childProduct={childProduct}
              disabled={
                variations && variations.length > 0 && !childProduct
                  ? true
                  : false
              }
              upsell={upSellProducts?.filter((item , i) => selectedPro.includes(i) === true)}
            />
          </div>
          <div className="col-md-4 col-4 save-btn-pd">
            <button className="save-btn save-btn-wrapper">
              <i className="far fa-heart mr-2"></i> Save
            </button>
          </div>
        </div>
      </div>
      <div className="gift"> 
        <div className="round">=
          <input
            type="checkbox"
            id="checkbox1"
            checked={freeGift}
            onChange={handleFreeGiftClick}
          />
          <label htmlFor="checkbox1"></label>
        </div>
        <div className="flex">
              <Gift className="mr-2" />
          <p>
            I want a <b>FREE GIFT</b>
          </p>
        </div>
      </div>
      <div className="shop_at_amazon">
        <div className="">
          <img src={badge_img} alt="badge-img" />
        </div>
        <div className="shop">
          <h2>Shop at Azoom and help our community come closer.</h2>
          <p>
            We are community of people in the midlands trying to push forward a
            better collobaration of consumer purchase items that is ethically
            ‘Made’ & ‘Sourced’
          </p>
        </div>
      </div>
      <div className="product-overview-wrapper collapse-wrapper">
        <p className="heading">Product Overview</p>
        <label  htmlFor="collapsible" className="wrap-collabsible border-bottom w-full">
          <h2 className="sub_heading">Description</h2>
          <input defaultChecked id="collapsible" className="toggle" type="checkbox" />
          <label htmlFor="collapsible" className="lbl-toggle"></label>
          <div className={ ( "collapsible-content" )}>
            <div className={"content-inner "}>
              <p
                className={"para overflow-hidden" + (seeMore ? " " : " line-clamp-3 max-h-[100px]")}
                dangerouslySetInnerHTML={{ __html: description }}
              />
              
            </div>
          </div>
          { 
   
            <div onClick={(e)=>{setseeMore(!seeMore); e.preventDefault() }} className={"text-[#ee5f3b] underline font-bold cursor-pointer"}>
             { !seeMore ? "See more" : "See less"}
            </div>}
        </label>
      </div>
      <Specifications product={product} />
      <ul className="mobile-modal-wrapper">
        <li onClick={() => setShowOverviewModal(true)}>
          <div className="div-wrapper">Product Overview</div>
        </li>
        <li onClick={() => setShowDnRModal(true)}>
          <div className="div-wrapper">Delivery & Returns</div>
        </li>
        <li onClick={() => setShowReviewModal(true)}>
          <div className="div-wrapper flex-center">
            Reviews
            <div className="rating-stars rating-stars-wrapper ml-1">
              <ReactStars
                count={5}
                size={14}
                value={4.6}
                edit={false}
                isHalf={true}
                emptyIcon={<i className="far fa-star" />}
                halfIcon={<i className="fa fa-star-half-alt" />}
                filledIcon={<i className="fa fa-star" />}
                activeColor="#000000"
                color="black"
              />
              <span>4.6</span>
            </div>
          </div>
        </li>
      </ul>
      <div className="help-wrapper">
        <div>
          <h2 className="heading">Have a questions?</h2>
          <span>We are here to help</span>
        </div>
        <button 
        onClick={
          () => {
            navigate("/about/contact")
        }
      }
        className="btn min-w-[140px]">
          <img src={call} alt="call" />
          Call Us
        </button>
      </div>
    </div>
  );
}
