import axios from "axios";
import { URL, HEADERS } from "../constants/api";
import { raiseErrorForServices } from "../constants/errors";
//sample gqlData format - query or mutation data
const gqlDataForProducts = `query products(
  $filter: ProductAttributeFilterInput,
  $pageSize: Int,
  $currentPage: Int
  $sort: ProductAttributeSortInput
  ){
  products(
    filter: $filter,
    pageSize: $pageSize,
    currentPage: $currentPage,
    sort: $sort     
    ) {
      total_count  
      page_info {
        page_size
        current_page
      }
    
      items {      
        id
        uid
        name
        synopsis
        sku
        new
        sale
        special_price
        #erin_recommends     
        bestseller
        azoom_product_specifications{
          publication_date:publication_year      
          publisher
          format
          authors  
          pages
          edition
          reading_time
          language
          isbn
          isbn_10
          isbn_13
          dimensions
          recommended_age
        }
        price_range {        
          minimum_price {
            regular_price {
              value
              currency
            }
            discount{
              amount_off
              percent_off
            }
            final_price{
              value
              currency
            }
  
          }
          maximum_price {
            regular_price {
              value
              currency
            }
            discount{
              amount_off
              percent_off
            }
            final_price{
              value
              currency
            }
          }
          
        }
        image {
          label
          url
        }
        media_gallery {
          label
          url
        }
        meta_keyword
        price_tiers {
          final_price {
            currency
            value
          }
          quantity
        }
        options_container
        only_x_left_in_stock
        stock_status      
        short_description {
          html
        }
        description{
          html
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id_v2
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id          
          }
          variants {
            product {
              id
              name
              sku
              attribute_set_id
              ... on PhysicalProductInterface {
                weight            
              }
              image{
                url
              }
              
              price_range{
                minimum_price{
                  regular_price{
                    value
                    currency
                  }
                  discount{
                    amount_off
                    percent_off
                  }
                  final_price{
                    value
                    currency
                  }
                }              
              }
            }        
            attributes {
              uid
              label
              code
              value_index            
            }
          }
        }
        reviews {       
          page_info{
            current_page
            page_size
            total_pages
          }
          items {          
            average_rating
            ratings_breakdown{
              name
              value
            }          
            created_at
            nickname
            summary
            text
          }
          #page_info {
          #  page_size
          #}
        }
        upsell_products {
          name
          description {
            html
          }
          image{
              url
              label
          }
          media_gallery {
            label
            url
          }
          azoom_product_specifications{
            publication_date:publication_year      
            publisher
            format
            authors  
            pages
            edition
            reading_time
            language
            isbn
            isbn_10
            isbn_13
            dimensions
            recommended_age
          }
          reviews {       
            page_info{
              current_page
              page_size
              total_pages
            }
            items {          
              average_rating
              ratings_breakdown{
                name
                value
              }          
              created_at
              nickname
              summary
              text
            }
            #page_info {
            #  page_size
            #}
          }
          special_price
          price_range {
            minimum_price {
              regular_price {
                currency
                value
              },
              discount{
                amount_off
                percent_off
              }
            }
            maximum_price {
              final_price {
                currency
                value
              }
            }
          }
        }
        related_products {
          name
          description {
            html
          }
          image{
              url
              label
          }
          media_gallery {
            label
            url
          }
          azoom_product_specifications{
            publication_date:publication_year      
            publisher
            format
            authors  
            pages
            edition
            reading_time
            language
            isbn
            isbn_10
            isbn_13
            dimensions
            recommended_age
          }
          reviews {       
            page_info{
              current_page
              page_size
              total_pages
            }
            items {          
              average_rating
              ratings_breakdown{
                name
                value
              }          
              created_at
              nickname
              summary
              text
            }
            #page_info {
            #  page_size
            #}
          }
          special_price
          price_range {
            minimum_price {
              regular_price {
                currency
                value
              },
              discount{
                amount_off
                percent_off
              }
            }
            maximum_price {
              final_price {
                currency
                value
              }
            }
          }
        }
        crosssell_products {
          name
          description {
            html
          }
          image{
              url
              label
          }
          media_gallery {
            label
            url
          }
          azoom_product_specifications{
            publication_date:publication_year      
            publisher
            format
            authors  
            pages
            edition
            reading_time
            language
            isbn
            isbn_10
            isbn_13
            dimensions
            recommended_age
          }
          reviews {       
            page_info{
              current_page
              page_size
              total_pages
            }
            items {          
              average_rating
              ratings_breakdown{
                name
                value
              }          
              created_at
              nickname
              summary
              text
            }
            #page_info {
            #  page_size
            #}
          }
          special_price
          price_range {
            minimum_price {
              regular_price {
                currency
                value
              },
              discount{
                amount_off
                percent_off
              }
            }
            maximum_price {
              final_price {
                currency
                value
              }
            }
          } 
        }
        categories {
          name
          url_path
        }     
        
      }    
      suggestions {
        search
      }       
      aggregations {
        attribute_code
        count
        label
        position
        options{
           label
           count
           value
        }
      }
    }
  }`;

const gqlDataForSearchProduct = `query products($search: String){
  products(search: $search){
    total_count  
      page_info {
        page_size
        current_page
      }
    
      items {      
        id
        uid
        name
        sku
        new
        sale
        bestseller
        special_price
        #erin_recommends     
        azoom_product_specifications{
          publication_date:publication_year      
          publisher
          format
          authors  
          pages
          edition
          reading_time
          language
          isbn
          isbn_10
          isbn_13
          dimensions
          recommended_age
        }
        price_range {        
          minimum_price {
            regular_price {
              value
              currency
            }
            discount{
              amount_off
              percent_off
            }
            final_price{
              value
              currency
            }
  
          }
          maximum_price {
            regular_price {
              value
              currency
            }
            discount{
              amount_off
              percent_off
            }
            final_price{
              value
              currency
            }
          }
          
        }
        image {
          label
          url
        }
        media_gallery {
          label
          url
        }
        meta_keyword
        price_tiers {
          final_price {
            currency
            value
          }
          quantity
        }
        options_container
        only_x_left_in_stock
        stock_status      
        short_description {
          html
        }
        description{
          html
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id_v2
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id          
          }
          variants {
            product {
              id
              name
              sku
              attribute_set_id
              ... on PhysicalProductInterface {
                weight            
              }
              image{
                url
              }
              
              price_range{
                minimum_price{
                  regular_price{
                    value
                    currency
                  }
                  discount{
                    amount_off
                    percent_off
                  }
                  final_price{
                    value
                    currency
                  }
                }              
              }
            }        
            attributes {
              uid
              label
              code
              value_index            
            }
          }
        }
        reviews {       
          page_info{
            current_page
            page_size
            total_pages
          }
          items {          
            average_rating
            ratings_breakdown{
              name
              value
            }          
            created_at
            nickname
            summary
            text
          }
          #page_info {
          #  page_size
          #}
        }
        upsell_products {
          name
          description {
            html
          }
          image{
              url
              label
          }
          media_gallery {
            label
            url
          }
          azoom_product_specifications{
            publication_date:publication_year      
            publisher
            format
            authors  
            pages
            edition
            reading_time
            language
            isbn
            isbn_10
            isbn_13
            dimensions
            recommended_age
          }
          reviews {       
            page_info{
              current_page
              page_size
              total_pages
            }
            items {          
              average_rating
              ratings_breakdown{
                name
                value
              }          
              created_at
              nickname
              summary
              text
            }
            #page_info {
            #  page_size
            #}
          }
          special_price
          price_range {
            minimum_price {
              regular_price {
                currency
                value
              },
              discount{
                amount_off
                percent_off
              }
            }
            maximum_price {
              final_price {
                currency
                value
              }
            }
          }
        }
        related_products {
          name
          description {
            html
          }
          image{
              url
              label
          }
          media_gallery {
            label
            url
          }
          azoom_product_specifications{
            publication_date:publication_year      
            publisher
            format
            authors  
            pages
            edition
            reading_time
            language
            isbn
            isbn_10
            isbn_13
            dimensions
            recommended_age
          }
          reviews {       
            page_info{
              current_page
              page_size
              total_pages
            }
            items {          
              average_rating
              ratings_breakdown{
                name
                value
              }          
              created_at
              nickname
              summary
              text
            }
            #page_info {
            #  page_size
            #}
          }
          special_price
          price_range {
            minimum_price {
              regular_price {
                currency
                value
              },
              discount{
                amount_off
                percent_off
              }
            }
            maximum_price {
              final_price {
                currency
                value
              }
            }
          }
        }
        crosssell_products {
          name
          description {
            html
          }
          image{
              url
              label
          }
          media_gallery {
            label
            url
          }
          azoom_product_specifications{
            publication_date:publication_year      
            publisher
            format
            authors  
            pages
            edition
            reading_time
            language
            isbn
            isbn_10
            isbn_13
            dimensions
            recommended_age
          }
          reviews {       
            page_info{
              current_page
              page_size
              total_pages
            }
            items {          
              average_rating
              ratings_breakdown{
                name
                value
              }          
              created_at
              nickname
              summary
              text
            }
            #page_info {
            #  page_size
            #}
          }
          special_price
          price_range {
            minimum_price {
              regular_price {
                currency
                value
              },
              discount{
                amount_off
                percent_off
              }
            }
            maximum_price {
              final_price {
                currency
                value
              }
            }
          } 
        }
        categories {
          name
        }     
        
      }    
      suggestions {
        search
      }       
      aggregations {
        attribute_code
        count
        label
        position
        options{
           label
           count
           value
        }
      }
  }
}`;

const gqlDataForSliderProducts = `query {
  home_slider_products: products(    
    filter: {        
      home_slider: {
        eq: "1"
      }
    }    
  ) {    
    items {      
      id
      uid
      name
      synopsis
      sku
      new
      sale
      special_price 
      home_slider_image
      #erin_recommends     
      azoom_product_specifications{
        publisher
  
      }
      price_range {        
        minimum_price {
          regular_price {
            value
            currency
          }
          discount{
            amount_off
            percent_off
          }
          final_price{
            value
            currency
          }

        } 
      }
      image {
        label
        url
      }
     
      ... on ConfigurableProduct {
        configurable_options {
          id
          attribute_id_v2
          label
          position
          use_default
          attribute_code
          values {
            value_index
            label
          }
          product_id          
        }
        variants {
          product {
            id
            name
            sku
            attribute_set_id
            ... on PhysicalProductInterface {
              weight            
            }
            image{
              url
            }
            
            price_range{
              minimum_price{
                regular_price{
                  value
                  currency
                }
                discount{
                  amount_off
                  percent_off
                }
                final_price{
                  value
                  currency
                }
              }              
            }
          }        
          attributes {
            uid
            label
            code
            value_index            
          }
        }
      }   
      
    }    
    suggestions {
      search
    }       
    aggregations {
      attribute_code
      count
      label
      position
      options{
         label
         count
         value
      }
    } 
  }
  recommended_products: products(    
    filter: {        
      recommended: {
        eq: "1"
      }
    }    
  ) {    
    items {      
      id      
      name
      sku
      new
      categories {
        name
        url_path 
      }
      azoom_product_specifications{
        publisher
        
      } 
           
      price_range {        
        minimum_price {
          regular_price {
            value
            currency
          }
          discount{
            amount_off
            percent_off
          }
          final_price{
            value
            currency
          }

        }
      }      
      image {        
        url
      } 
      reviews {       
        page_info{
          current_page
          page_size
          total_pages
        }
        items {          
          average_rating
          ratings_breakdown{
            name
            value
          }          
          created_at
          nickname
          summary
          text
        }
        #page_info {
        #  page_size
        #}
      }           
    }
  }
  
}`;

const gqlDataForSettingRecentlyViewedProduct = `mutation recentViewedProducts($product_ids: [Int]!) {
  recentViewedProducts(
      product_ids: $product_ids,
  ) 
  {
    message
    total_count
  }
}`;

export const gqlDataForGettingRecentlyViewedProduct = `query recentViewedProducts(
    $search: String,
    $pageSize: Int,
    $currentPage: Int) {
    recentViewedProducts(
      search: $search,
      pageSize: $pageSize,
      currentPage: $currentPage,
      ){
        total_count
        currentPage
        pageSize
        totalPages
        items {
          id
          sku
          uid
          name
          url_key
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
            }
          }
          image {
            url
            label
          }
          reviews {
            items {          
              average_rating
              ratings_breakdown{
                name
                value
              } 
            }
          }
        }
      }
}`;

const gqlDataForGettingSuggestedProducts = `query {    
  suggested_searches:mostViewedProducts (count: 10){
      id
      sku
      name
      price
      image        
      rating
  }
}`;

const gqlDataForSettingFavouriteProduct = `mutation addProductsToWishlist($wishlistId: ID!, $wishlistItems: [WishlistItemInput!]!) {
  addProductsToWishlist(
    wishlistId: $wishlistId,
    wishlistItems: $wishlistItems,
  ) {
    wishlist {
      id
      items_count
      items_v2 (currentPage: 1, pageSize: 8 ) {
        items {
          id
          quantity
          product {
            id
            uid
            name
            sku
            price_range {
              minimum_price {
                regular_price {
                  currency
                  value
                }
              }
              maximum_price {
                regular_price {
                  currency
                  value
                }
              }
            }
          }
        }
      }
    }
    user_errors {
      code
      message
    }
  }
}`;

const gqlDataForSettingProductReview = `mutation createProductReview($input: AddReviewInput!) {
  addReview(
      input: $input,
  )
  {
    customer_id
    product_id
    nick_name
    average_rating
    ratings{
      price
      quality
      value
      rating
    }
    review_title
    review_detail
  }
}`;

const gqlDataForSearchProducts = `query products($search: String){
  products(search: $search      
    ) {
      total_count  
      page_info {
        page_size
        current_page
      }
    
      items {      
        id
        uid
        name
        sku
        new
        sale
        special_price
        #erin_recommends     
        azoom_product_specifications{
          publication_date:publication_year      
          publisher
          format
          authors  
          pages
          edition
          reading_time
          language
          isbn
          isbn_10
          isbn_13
          dimensions
          recommended_age
        }
        price_range {        
          minimum_price {
            regular_price {
              value
              currency
            }
            discount{
              amount_off
              percent_off
            }
            final_price{
              value
              currency
            }
  
          }
          maximum_price {
            regular_price {
              value
              currency
            }
            discount{
              amount_off
              percent_off
            }
            final_price{
              value
              currency
            }
          }
          
        }
        image {
          label
          url
        }
        media_gallery {
          label
          url
        }
        meta_keyword
        price_tiers {
          final_price {
            currency
            value
          }
          quantity
        }
        options_container
        only_x_left_in_stock
        stock_status      
        short_description {
          html
        }
        description{
          html
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id_v2
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
            }
            product_id          
          }
          variants {
            product {
              id
              name
              sku
              attribute_set_id
              ... on PhysicalProductInterface {
                weight            
              }
              image{
                url
              }
              
              price_range{
                minimum_price{
                  regular_price{
                    value
                    currency
                  }
                  discount{
                    amount_off
                    percent_off
                  }
                  final_price{
                    value
                    currency
                  }
                }              
              }
            }        
            attributes {
              uid
              label
              code
              value_index            
            }
          }
        }
        reviews {       
          page_info{
            current_page
            page_size
            total_pages
          }
          items {          
            average_rating
            ratings_breakdown{
              name
              value
            }          
            created_at
            nickname
            summary
            text
          }
          #page_info {
          #  page_size
          #}
        }
        upsell_products {
          name
          description {
            html
          }
          special_price
          price_range {
            minimum_price {
              regular_price {
                currency
                value
              },
              discount{
                amount_off
                percent_off
              }
            }
            maximum_price {
              final_price {
                currency
                value
              }
            }
          }
        }
        related_products {
          name
        }
        crosssell_products {
          name
          thumbnail {
            label
            url
          }
          color
        }
        categories {
          name
        }     
        
      }    
      suggestions {
        search
      }       
      aggregations {
        attribute_code
        count
        label
        position
        options{
           label
           count
           value
        }
      }
    }
  }`;
  const gqlDataForSearchProduct1 = `query products($search: String){
    products(search: $search){
      total_count  
        page_info {
          page_size
          current_page
        }
      
        items {    
          sku  
          name
          image {
            url
          }
          
        }
    }
  }`;
  const gqlDataForProductReview =  `query products(  $filter: ProductAttributeFilterInput,
    ){
    products(filter: $filter){
     
    
      items {    
        sku  
        id
        uid
        name
        image {
          label
          url
        }
        
      }
  }
}`;
  const gqlDataForReviews =  `query {
    customer {
    
    
      reviews{
        items{
          average_rating
          created_at
          nickname
          summary 
          text        
          ratings_breakdown {
            name
            value
          }               
          product{          
            sku
            name          
            image{
              url
            }          
            price_range{
               minimum_price{
                final_price{
                  currency
                  value
                }
                discount{
                  amount_off
                  percent_off
                }
                regular_price{
                  currency
                  value
                }
              }
              maximum_price{
                final_price{
                  currency
                  value
                }
                discount{
                  amount_off
                  percent_off
                }
                regular_price{
                  currency
                  value
                }
              }
             
            }        
                   
          }
          
        }
      }
     
          
    }  
  }`;
export const getProductsInfo = async (productData) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForProducts,
      variables: {
        ...productData,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);

}
export const getRewviews = async () => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForReviews,
    
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);

}
export const getReviewProduct = async (productData) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query:  gqlDataForProductReview,
      variables: {
        ...productData,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
export const getProducts1 = async (productData) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForProducts2,
      variables: {
        ...productData,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};


export const getSliderAndRecommendedProducts = async () => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForSliderProducts,
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};

export const setRecentlyViewedProducts = async (id) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForSettingRecentlyViewedProduct,
      variables: {
        product_ids: [id],
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
const gqlDataForRelatedProducts = `
  query products($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {

        upsell_products {
          sku
          id
          name
          image{
            url
        }
          price_range {
            minimum_price {
              regular_price {
                currency
                value
              },
              discount{
                amount_off
                percent_off
              }
            }
            maximum_price {
              final_price {
                currency
                value
              }
            }
          }
        }
        related_products {
          id
          sku
          azoom_product_specifications
          {
            publisher
          }
          name
          description {
            html
          }
          media_gallery {
            label
            url
          }
          image{
              url
              label
          }
          special_price
          price_range {
            minimum_price {
              regular_price {
                currency
                value
              },
              discount{
                amount_off
                percent_off
              }
            }
            maximum_price {
              final_price {
                currency
                value
              }
            }
          }
          reviews {
              items {          
              average_rating
              ratings_breakdown{
                  name
                  value
              } 
              }        
          }
        }
        crosssell_products {
          id
          sku
          azoom_product_specifications
          {
            publisher
          }
          name
          description {
            html
          }
          media_gallery {
            label
            url
          }
          image{
              url
              label
          }
          special_price
          price_range {
            minimum_price {
              regular_price {
                currency
                value
              },
              discount{
                amount_off
                percent_off
              }
            }
            maximum_price {
              final_price {
                currency
                value
              }
            }
          }
          reviews {
              items {          
              average_rating
              ratings_breakdown{
                  name
                  value
              } 
              }        
          }
        }
      }
    }
  }
`;
const gqlDataForProducts2 = `query products(
  $filter: ProductAttributeFilterInput,
  $pageSize: Int,
  $currentPage: Int
  $sort: ProductAttributeSortInput
  ){
  products(
    filter: $filter,
    pageSize: $pageSize,
    currentPage: $currentPage,
    sort: $sort     
    ) {
      total_count  
      page_info {
        page_size
        current_page
      }
    
      items {      
        id
        uid
        name
        sku
        new
        sale
        special_price
        #erin_recommends     
        bestseller
        azoom_product_specifications{
          publication_date:publication_year      
          publisher
          authors
        }
        price_range {        
          minimum_price {
            regular_price {
              value
              currency
            }
            discount{
              amount_off
              percent_off
            }
            final_price{
              value
              currency
            }
  
          }
        }
        image {
          label
          url
        }
        reviews {       
          
          items {          
            average_rating
             
          }
          #page_info {
          #  page_size
          #}
        }
      }
    }
  }`;
export const getRelatedProducts = async (sku) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForRelatedProducts,
      variables: {
        sku: sku,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
const gqlDataForUpsellProducts = `
  query products($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        related_products {
          id
          name
          sku
        }
      }
    }
  }
`;
export const getUpsellProducts = async (sku) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForUpsellProducts,
      variables: {
        sku: sku,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
export const getRecentlyViewedProducts = async () => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForGettingRecentlyViewedProduct,
      variables: {
        search: "",
        pageSize: 10,
        currentPage: 1,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};

export const getSuggestedSearches = async () => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForGettingSuggestedProducts,
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};

export const setFavouriteProduct = async (wishlistData) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForSettingFavouriteProduct,
      variables: {
        ...wishlistData,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};

export const setProductReview = async (input) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForSettingProductReview,
      variables: {
        input,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};

export const getSearchProductInfo = async (search) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForSearchProduct,
      variables: {
        search: search,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
export const getSearchProduct1 = async (search) => {
  const { data } = await axios({
    url: URL,
    method: "POST",
    data: {
      query: gqlDataForSearchProduct1,
      variables: {
        search: search,
      },
    },
    headers: HEADERS,
  });
  return raiseErrorForServices(data);
};
