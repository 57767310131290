import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import Splide from "@splidejs/splide";
import { ReactComponent as Badge } from "../../assets/images/azoom_community.svg";
import { calculateAvg, calculateRatings } from "../../utils";

export default function ProductImages({ product, contitySold, childProduct }) {
  const [allImages, setallImages] = useState([]);
  const { media_gallery, variants, image } = product;
  useEffect(() => {
    var allImages = [];
    media_gallery?.forEach((element) => {
      allImages.push(element.url);
    });
    variants?.forEach((element) => {
      allImages.push(element.product.image.url);
    });
    // check if image.url is already in allImages if it does make it first element if not push it to allImages
    if (allImages.includes(image.url)) {
      var findImageIndex = allImages.findIndex(
        (element) => element === image.url
      );
      allImages.splice(findImageIndex, 1);
      allImages.unshift(image.url);
    } else {
      allImages.unshift(image.url);
    }

    setallImages(allImages);
  }, [product]);

  const bg = "center";

  // useEffect(() => {
  //   const left = document.querySelector(".left");

  //   return () => {
  //   left.addEventListener("mousemove", handleMousemove, false);
  //   left.addEventListener("mouseout", handleMouseOut, false);
  //     left.removeEventListener("mousemove", handleMousemove, false);
  //     left.removeEventListener("mouseout", handleMouseOut, false);
  //   };
  // }, []);
  const handleMouseOut = (event) => {
    const left = document.querySelector(".left");

    left.classList.remove("zoom");
    left.style.transform = `translate(-${50}%, -${50}%)`;
  };
  function handleMousemove(event) {
    const left = document.querySelector(".left");
    const wraper = document.querySelector(".wrapper");
    const { width, height } = wraper.getBoundingClientRect();
    let xAxis = (event.clientX / width) * 80;
    let yAxis = (event.clientY / height) * 80;
    left.classList.add("zoom");
    left.style.transform = `translate(-${xAxis}%, -${yAxis}%)`;
  }
  const [backgroundPosition, setBackgroundPosition] = useState(bg);

  const [largeImg, setLargeImg] = useState(0);
  const length = product?.reviews?.items.length;
  useEffect(() => {
    if (childProduct) {
      var findImageIndex = allImages.findIndex(
        (element) => element === childProduct.image.url
      );

      setLargeImg(findImageIndex);
    }
  }, [childProduct]);
  // const handleMouseMove = (e) => {
  //   // if (isMobile || isTablet)
  //   // 		return;
  //   const { left, top, width, height } = e.target.getBoundingClientRect();
  //   const x = ((e.pageX - left) / width) * 100;
  //   const y = ((e.pageY - top) / height) * 100;
  //   setBackgroundPosition(`${x}% ${y}%`);
  // };
  // const handleMouseOut = () => {
  //   setBackgroundPosition(`center`);
  // };

  useEffect(() => {
    let productImagesSlider = new Splide("#product_images_slider", {
      cover: false,
      arrows: true,
      perPage: 3,
      // autoplay: true,
      // type: 'loop',
      // interval: 300000,
      pagination: false,
      gap: 20,
      autoWidth: true,
      autoHeight: true,
      breakpoints: {
        768: {
          arrows: false,

          perPage: 2,
        },
        992: {
          perPage: 4,
        },
      },
    }).mount();
    productImagesSlider.on("click", function (obj) {});
  });
  useEffect(() => {
    let productImagesSlider = new Splide("#product_images", {
      cover: false,
      perPage: 1,
      // autoplay: true,
      // type: 'loop',
      // interval: 300000,
      pagination: true,
      // gap: 20,
      // autoWidth: true,
      // autoHeight: true,
      arrows: false,
      breakpoints: {},
    }).mount();
    // productImagesSlider.on('click', function (obj) {});
  });

  const percentage = calculateAvg(
    calculateRatings(product.reviews?.items),
    length
  ).toFixed(1); // TODO can be optimised
  const selectLargeImg = (index) => {
    // alert(index);
    setLargeImg(index);
  };

  return (
    <div className="product_images_wrapper">
      <div className="basic-product-info">
        <p>
          {product?.name}
          <br />
          {/* (Little Kids) */}
        </p>
        <div className="flex items-center justify-between">
          <p className="seemore  h-auto m-0">
            See more by{" "}
            <a href="/">
              {" " + product?.azoom_product_specifications?.publisher}
            </a>
          </p>
          <p className="instock whitespace-nowrap mb-0">
            <i className="fas fa-chevron-down"></i>
            In Stock
          </p>
        </div>

        {percentage !== "0.0" && (
          <div className="rating-stars rating-stars-wrapper mt-2">
            <ReactStars
              count={5}
              size={14}
              value={parseFloat(percentage)}
              edit={false}
              isHalf={true}
              emptyIcon={<i className="far fa-star" />}
              halfIcon={<i className="fa fa-star-half-alt" />}
              filledIcon={<i className="fa fa-star" />}
              activeColor="#000000"
              color="black"
            />
            <span>{percentage}</span>
            <span className="total-reviews">{length} reviews</span>
          </div>
        )}
        {product?.bestseller === 1 && (
          <div
            style={{ justifyContent: "flex-start" }}
            className="w-full  flex items-center  "
          >
            <div className="highlight  ribbon">#1 Best Seller</div>
            <span className="font-bold ml-10">
              More than{" "}
              <span className="text-[#002060]">{contitySold} sold</span>
            </span>
          </div>
        )}
      </div>
      <ProductImagesImages
        allImages={allImages}
        product={product}
        backgroundPosition={backgroundPosition}
        handleMouseMove={handleMousemove}
        handleMouseOut={handleMouseOut}
        largeImg={largeImg}
        selectLargeImg={selectLargeImg}
      />

      <div className="shop_at_amazon">
        <div className="p-3">
          <Badge />
        </div>
        <div className="shop">
          <h2>Shop at Azoom and help our community come closer.</h2>
          <p className="text-xs">
            We are community of people in the midlands trying to push forward a
            better collobaration of consumer purchase items that is ethically
            ‘Made’ & ‘Sourced’
          </p>
        </div>
      </div>
    </div>
  );
}

export function ProductImagesImages({
  allImages,
  product,
  backgroundPosition,
  handleMouseMove,
  handleMouseOut,
  largeImg,
  selectLargeImg,
}) {
  const [loading, setloading] = useState(true);

  return (
    <div>
      <div className="big-image-wrapper h-[450px] overflow-hidden">
        <div id="product_images" className="splide w-full ">
          {product.new === 1 && (
            <div className={"tag_ " + (product.new && "bg-[#B6B39F]")}>NEW</div>
          )}
          {product.sale === 1 && (
            <div className={"tag_sale " + (product.sale && "bg-[#ee5f34]")}>
              SALE
            </div>
          )}
          <div className="splide__track h-[250px]">
            <ul className="splide__list h-full ">
              {allImages.slice(0 , 3)?.map((item, i) => {
                return (
                  <li
                    key={i} 
                    className={"main_image  splide__slide "}
                    style={{
                      backgroundImage: `url(${allImages[i]})`,
                      backgroundPosition: backgroundPosition,
                    }}
                  >
                    
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div
          onMouseMove={(e) => handleMouseMove(e)}
          onMouseOut={handleMouseOut}
          className={"relative w-full h-full wrapper "}
        >
          <div className=" hover left">
          {loading && (
            <div
              className={  "load z-999 absolute  rounded w-full h-full "}
            ></div>
          )}
            <img className={!loading ?" " : " hidden"} onLoad={() => setloading(false)} src={allImages[largeImg]} />
          </div>
        </div>
        {/* <div
          className="main_image hover load"
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseOut}
          style={{
            backgroundImage: `url(${allImages[largeImg]})`,
            backgroundPosition: backgroundPosition,
          }}
        >
          {product.new === 1 && (
            <div className={"tag_ " + (product.new && "bg-[#B6B39F]")}>NEW</div>
          )}
          {product.sale === 1 && (
            <div className={"tag_sale " + (product.sale && "bg-[#ee5f34]")}>
              SALE
            </div>
          )}
        </div> */}
      </div>
      <div id="product_images_slider" className="splide product_images_slider">
        <div className="splide__track m-auto">
          <ul className="splide__list">
            {allImages?.map((product, i) => (
              <li
                className={
                  "splide__slide slider-img " +
                  (largeImg === i && "selectedImg")
                }
                key={i}
                onClick={() => selectLargeImg(i)}
              >
              
                <img src={product} alt={`${product}`} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
