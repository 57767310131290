import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-stars-component";
import DefaultLayout from "../../common/DefaultLayout";
import Ratings from "./Rating";
import CustomerPhotos from "./CustomerPhotos";
import ProductImages from "./ProductImages";
import ProductDetail from "./ProductDetail";
import Delivery from "./DeliveryAndReturns";
import Reviews from "./Reviews";
import ViewedItems from "./ViewedItems";
import SimilarItems from "./SimilarItems";
import FrequentlyBought from "./FrequentlyBought";
import Breadcrumbs from "../../common/Breadcrumbs";
import Modal from "react-modal";

import closeicon from "../../assets/images/icon_close-menu.png";
import product_img from "../../assets/images/big_5000x.png";
import search from "../../assets/images/icon_search.png";
import DropDown from "../../assets/images/dropdown.png";
import icontime from "../../assets/images/icon_time-grey.png";
import { ReactComponent as Green } from "../../assets/images/imgpsh_fullsize_anim.svg";
import { ReactComponent as GreenResp } from "../../assets/images/imgpsh_fullsize_anime_resp.svg";
import user from "../../assets/images/icon_user.png";
import iconlike from "../../assets/images/icon_like.png";
import deliveryicon from "../../assets/images/icon_free-delivery.png";
import Flag from "../../assets/images/Group 2738.png";
import time from "../../assets/images/icon_same-day-delivery.png";
import card from "../../assets/images/icon_money-back-guarantee.png";
import * as routes from "../../routePaths";
import { Bars } from "react-loader-spinner";

import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  setRecentlyViewedProducts,
  getRelatedProducts,
  getUpsellProducts,
  getProductsInfo,
} from "../../services/product";
import { useDispatch, useSelector } from "react-redux";
import Specifications from "./Specifications";
import MoneyBackGuarantee from "../../common/MoneyBackGuarantee";
import { setloader } from "../../redux/loaderSlice";
import { calculateAvg, calculateRatings } from "../../utils";
import moment from "moment";
import AddToCart from "../../common/AddToCart";
import axios from "axios";
import { HEADERS, URL } from "../../constants/api";
import Loader from "../../common/Loader/Loader";
import { set } from "lodash";

const ProductDetailPage = () => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  const [childProduct, setChildProduct] = useState(null);

  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showOverviewModal, setShowOverviewModal] = useState(false);
  const [showDnRModal, setShowDnRModal] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const producto = location?.state?.product;
  const [product, setproduct] = useState(producto);
  const [load, setload] = useState(true);

  var route = location?.state?.route;
  if (!route) {
    route = [];
  }
  const { customer } = useSelector((state) => state.customer);
  const { shipping_addresses } = useSelector((state) => state.cart);

  const [productBigData, setproductBigData] = useState({
    upSellProducts: [],
    relatedP: [],
    crosssellP: [],
  });
  const description = product?.short_description?.html;
  const length = product?.reviews?.items.length;
  const [contitySold, setcontitySold] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getQuantitySold();
    return () => {};
  }, [product]);

  const gqlForGettingCuantitySold = `query MyQuery {
    products(filter: {sku: {eq: "${decodeURIComponent(
      product?.sku.replace(/\+/g, " ")
    )}"}}) {
      items {
        quantity_sold
      }
    }
  }`;
  const getQuantitySold = async () => {
    try {
      const { data } = await axios({
        url: URL,
        method: "POST",
        data: {
          query: gqlForGettingCuantitySold,
          // variables: {
          //   ...productData,
          // },
        },
        headers: HEADERS,
      });
      setcontitySold(data.data.products.items[0].quantity_sold);
    } catch (e) {
      console.log(e);
    }

    // alert(data.data.products.items[0].quantity_sold)
  };
  const percentage = calculateAvg(
    calculateRatings(product?.reviews?.items),
    length
  ).toFixed(1); //

  const searchParams = new URLSearchParams(location.search);
  const sku = searchParams.get("sku");
  console.log(sku);
  useEffect(() => {
    const getProduct = async () => {
      setload(true);
      let productData = {
        filter: { sku: { eq: decodeURIComponent(sku.replace(/\+/g, " ")) } },
      };

      try {
        let { products } = await getProductsInfo(productData);
        // get first product from the list

        let product = products.items[0];
        setproduct(product);
      } catch (err) {
        console.log(err);
      } finally {
      }
      setload(false);

      getRelatedProductsData();
    };
    console.log(product);
    getProduct();
    // else dispatch(setloader(false))
  }, [sku]);

  // const setRecommendedProducts = async () => {
  //   try {
  //     const recentViewedProducts = await setRecentlyViewedProducts(product.id);
  //   } catch (e) {
  //   } finally {
  //   }
  // };
  const getRelatedProductsData = async () => {
    try {
      console.log(product?.sku);
      const recentViewedProducts = await getRelatedProducts(sku);
      setproductBigData({
        upSellProducts:
          recentViewedProducts.products.items?.[0].upsell_products,
        relatedP: recentViewedProducts.products.items?.[0].related_products,
        crosssellP: recentViewedProducts.products.items?.[0].crosssell_products,
      });
      console.log(recentViewedProducts.products.items?.[0]);
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  return (
    <div>
      {load ? (
        <DefaultLayout>
          <div className="bg-white h-[100vh]  flex items-center justify-center">
         
                              <Bars
                                className="w-10 h-10"
                                height="100"
                                width="100"
                                radius="9"
                                color="#53acaa"
                                ariaLabel="three-dots-loading"
                                wrapperStyle
                              />
          </div>

        </DefaultLayout>
      ) : (
        <>
          <Modal
            className="reviews-modal-wrapper border shadow-lg"
            isOpen={showReviewModal}
            onRequestClose={() => setShowReviewModal(false)}
          >
            <div>
              <div className="flex border-bottom">
                <p>Reviews</p>
                <button onClick={() => setShowReviewModal(false)}>
                  <img src={closeicon} />
                </button>
              </div>
              <div className="d-flex mt-3">
                <div className="image-wrapper">
                  <img src={product?.image?.url} />
                </div>
                <div className="product-info-wrapper">
                  <div className="basic-product-info">
                    <p>{product?.name}</p>
                    <p className="author">
                      By
                      {/* <span> {product.azoo}</span> */}
                    </p>
                    <span className="price">£8.99</span>
                    <div className="rating-stars rating-stars-wrapper">
                      <ReactStars
                        count={5}
                        size={14}
                        value={4.6}
                        edit={false}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star" />}
                        halfIcon={<i className="fa fa-star-half-alt" />}
                        filledIcon={<i className="fa fa-star" />}
                        activeColor="#000000"
                        color="black"
                      />
                      <span>4.6</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button className="addtobasket-btn">Add to Basket</button>
              </div>
              <div className="ratings mt-3">
                <h1>4.6</h1>
                <div className="rating-stars rating-stars-wrapper">
                  <ReactStars
                    count={5}
                    size={16}
                    value={4.6}
                    edit={false}
                    isHalf={true}
                    emptyIcon={<i className="far fa-star" />}
                    halfIcon={<i className="fa fa-star-half-alt" />}
                    filledIcon={<i className="fa fa-star" />}
                    activeColor="#000000"
                    color="black"
                  />
                </div>
                <pre>8 Reviews</pre>
              </div>
              <div className="">
                <div className="progress_div">
                  <pre>5</pre>
                  <span className="fa fa-star unchecked"></span>

                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "69%" }}
                      aria-valuenow="75"
                    ></div>
                  </div>
                  <pre>24</pre>
                </div>
                <div className="progress_div">
                  <pre>4</pre>
                  <span className="fa fa-star unchecked"></span>

                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "35%" }}
                      aria-valuenow="75"
                    ></div>
                  </div>
                  <pre>12</pre>
                </div>
                <div className="progress_div">
                  <pre>3</pre>
                  <span className="fa fa-star unchecked"></span>

                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "23%" }}
                      aria-valuenow="75"
                    ></div>
                  </div>
                  <pre>8</pre>
                </div>
                <div className="progress_div">
                  <pre>2</pre>
                  <span className="fa fa-star unchecked"></span>

                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "12%" }}
                      aria-valuenow="75"
                    ></div>
                  </div>
                  <pre>6</pre>
                </div>
                <div className="progress_div">
                  <pre>1</pre>
                  <span className="fa fa-star unchecked"></span>

                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "5%" }}
                      aria-valuenow="75"
                    ></div>
                  </div>
                  <pre>2</pre>
                </div>
              </div>
              {/* <CustomerPhotos/> */}
              <div className="modal-reviews-wrapper mt-5">
                <p>Show reviews that mention</p>
                <div className="reviews-header">
                  <div className="search-wrapper">
                    <div className="input-groups">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search Reviews"
                      />
                      <div className="input-group-append">
                        <img src={search} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p>Showing 1-2 of 83 reviews.</p>
                  <div className="btn_div">
                    <div className="dropdown">
                      <button className="dropdowns">
                        <img src={DropDown} />
                        <p>Sort By</p>
                        <small>Most Relevant</small>
                      </button>
                      <div className="dropdown-content">
                        <a href="#">Ascending</a>
                        <a href="#">Descending</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex mt-3">
                  <div className="rating-stars rating-stars-wrapper">
                    <ReactStars
                      count={5}
                      size={14}
                      value={4.5}
                      edit={false}
                      isHalf={true}
                      emptyIcon={<i className="far fa-star" />}
                      halfIcon={<i className="fa fa-star-half-alt" />}
                      filledIcon={<i className="fa fa-star" />}
                      activeColor="#000000"
                      color="black"
                    />
                  </div>
                  <div className="date-wrapper">
                    <img src={icontime} />
                    <p className="date">23/06/21</p>
                  </div>
                </div>
                <div>
                  <p className="review-text">
                    My son has just started his book and is looking forward to
                    doing more. We’ve had a look through the book and can’t wait
                    To try the different activities. It’s such a colourful book,
                    wish these were around when I was younger!
                  </p>
                </div>
                <div className="flex">
                  <div className="user_div">
                    <div className="">
                      <img src={user} />
                    </div>
                    <div>
                      <h3>Michael Newman</h3>
                      <pre className="text-muted">London, GB</pre>
                      <p>
                        <i className="fas fa-chevron-down"></i>
                        Verified Buyer
                      </p>
                    </div>
                  </div>
                  <div className="btn">
                    <button>
                      <img src={iconlike} />3
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="products-overview-wrapper border shadow-lg"
            isOpen={showOverviewModal}
            onRequestClose={() => setShowOverviewModal(false)}
          >
            <Loader />
            <div>
              <div className="flex border-bottom">
                <p>Product Overview</p>
                <button onClick={() => setShowOverviewModal(false)}>
                  <img src={closeicon} />
                </button>
              </div>
              <div className="d-flex mt-3">
                <div className="image-wrapper">
                  <img src={product?.image.url} />
                </div>
                <div className="product-info-wrapper">
                  <div className="basic-product-info">
                    <p>{product?.name}</p>
                    {product?.azoom_product_specifications?.publisher !==
                      "" && (
                      <p className="author">
                        By
                        {" " + product?.azoom_product_specifications?.publisher}
                      </p>
                    )}
                    <span className="price">£8.99</span>
                    <div className="rating-stars rating-stars-wrapper">
                      <ReactStars
                        count={5}
                        size={14}
                        value={4.6}
                        edit={false}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star" />}
                        halfIcon={<i className="fa fa-star-half-alt" />}
                        filledIcon={<i className="fa fa-star" />}
                        activeColor="#000000"
                        color="black"
                      />
                      <span>4.6</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button className="addtobasket-btn">Add to Basket</button>
              </div>
              <div className="description-wrapper">
                <h2 className="heading">Description</h2>
                <p
                  className={"desc-text  text-[12px] overflow-hidden"}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
              {product && <Specifications product={product} />}

              {/* <div className="specifications-wrapper">
                  <h2 className="heading">Specifications</h2>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td className="bg-light">ISBN-13</td>
                        <td className="bg-white">9780062975980</td>
                      </tr>
                      <tr>
                        <td className="bg-light">Publication date</td>
                        <td className="bg-white">06/30/2020</td>
                      </tr>
                      <tr>
                        <td className="bg-light">Pages</td>
                        <td className="bg-white">136</td>
                      </tr>
                      <tr>
                        <td className="bg-light">Product dimensions</td>
                        <td className="bg-white">50cm H x 20cm W x 3cm D</td>
                      </tr>
                      <tr>
                        <td className="bg-light">Recommended Age</td>
                        <td className="bg-white">13+</td>
                      </tr>
                      <tr>
                        <td className="bg-light">Format</td>
                        <td className="bg-white">Hardback</td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
            </div>
          </Modal>
          <Modal
            className="deliverynreturns-wrapper border shadow-lg"
            isOpen={showDnRModal}
            onRequestClose={() => setShowDnRModal(false)}
          >
            <div>
              <div className="flex border-bottom">
                <p>Delivery & Returns</p>
                <button onClick={() => setShowDnRModal(false)}>
                  <img src={closeicon} />
                </button>
              </div>
              <div className="d-flex mt-3">
                <div className="image-wrapper">
                  <img src={product?.image?.url} />
                </div>
                <div className="product-info-wrapper">
                  <div className="basic-product-info">
                    <p> {product?.name}</p>
                    {product?.azoom_product_specifications?.publisher !==
                      "" && (
                      <p className="author">
                        By
                        {" " + product?.azoom_product_specifications?.publisher}
                      </p>
                    )}
                    <span className="price">
                      £{product?.price_range?.minimum_price?.final_price?.value}
                    </span>
                    <div className="rating-stars rating-stars-wrapper">
                      <ReactStars
                        count={5}
                        size={14}
                        value={parseFloat(percentage)}
                        edit={false}
                        isHalf={true}
                        emptyIcon={<i className="far fa-star" />}
                        halfIcon={<i className="fa fa-star-half-alt" />}
                        filledIcon={<i className="fa fa-star" />}
                        activeColor="#000000"
                        color="black"
                      />
                      <span>{percentage}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button className="addtobasket-btn">Add to Basket</button>
              </div>
              <div className="delivery-div ">
                <div className="image-wrapper">
                  <img src={deliveryicon} />
                </div>
                <div>
                  <p className="free_del">
                    FREE DELIVERY on orders over £30.00{" "}
                  </p>
                </div>
              </div>
              <div className="dispatchment-wrapper mt-3">
                <p>
                  Dispatched to: <img src={Flag} />
                </p>
                <p>
                  Dispatched via:<span>2-Day Delivery</span>
                </p>
                <p>
                  Deliver To:
                  <span>
                    {shipping_addresses[0] ? (
                      shipping_addresses[0].postcode +
                      " - " +
                      shipping_addresses[0].city
                    ) : (
                      <span
                        className=" cursor-pointer"
                        onClick={() => navigate("/login/email")}
                      >
                        Please Sign In
                      </span>
                    )}
                  </span>
                  <i className="fas fa-chevron-down"></i>
                </p>
              </div>
              <div className="estimated-delivery">
                <h4>1-Day Delivery</h4>
                <p>
                  Estimated delivery:{" "}
                  {moment().add("days", 2).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="delivery-feature ">
                <div className="flex">
                  <div className="img-wrapper">
                    <img className="delivery-img" src={time} />
                  </div>
                  <div>
                    <h3>Delivery</h3>
                    <p>
                      Your order means a lot to us. That’s why we offer fast,
                      safe and reliable delivery options for every item
                    </p>
                    <span>Delivery Policy</span>
                  </div>
                </div>
              </div>
              <div className="delivery-feature">
                <div className="flex">
                  <div className="img-wrapper">
                    <img className="moneyback-img" src={card} />
                  </div>
                  <div>
                    <h3>Money Back Guarantee</h3>
                    <p>
                      Not loving it? We offer return of most items within 3é
                      days of delivery for a refund or store credit
                    </p>
                    <span>Return Policy</span>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <DefaultLayout>
            
              <div className="container-fluid background">
                <div className="row pb-4">
                  <div className="col-12 px-0">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 justify-between ">
                          <Breadcrumbs
                            paths={[
                              ...route,
                              {
                                url: "/",
                                text: product && product?.name,
                              },
                            ]}
                          />
                          <div
                            className="cursor-pointer back-btn-p"
                            onClick={() => navigate(-1)}
                          >
                            Back to Results
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container product_detail_wrapper">
                      <div className="row">
                        <div className="col-12 col-md-6 ">
             
                          {product && product?.media_gallery && (
                            <ProductImages
                              childProduct={childProduct}
                              contitySold={contitySold}
                              product={product}
                            />
                          )}
                        </div>
                        <div className="col-12 col-md-6">
                          {product && (
                            <ProductDetail

                              setShowOverviewModal={setShowOverviewModal}
                              setShowReviewModal={setShowReviewModal}
                              setShowDnRModal={setShowDnRModal}
                              product={product}
                              upSellProducts={productBigData.upSellProducts}
                              childProduct={childProduct}
                              setChildProduct={setChildProduct}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
            {productBigData.relatedP && productBigData.relatedP.length > 0 && (
              <SimilarItems relatedProducts={productBigData.relatedP} />
            )}
            {productBigData.crosssellP &&
              productBigData.crosssellP.length > 0 && (
                <FrequentlyBought
                  cosssellProducts={productBigData.crosssellP}
                />
              )}
            <div className="container-fluid ratings-review-wrapper mt-5 pb-4 mobile-pb-0">
              <div className="row">
                <div className="col-12">
                  <Ratings product={product} />
                  {/* <CustomerPhotos /> */}
                  {product?.reviews?.items && (
                    <Reviews reviews={product?.reviews} />
                  )}
                </div>
              </div>
            </div>
            <Delivery />
            <ViewedItems />
            <div className="m-none mt-5">
              <MoneyBackGuarantee />
            </div>
            <div className="share mt-5 container">
              <p>Share On: </p>
              <a target={"_blank"} href="https://www.facebook.com/azoomuk">
                <i className="fab fa-facebook-f"></i>Facebook
              </a>
              <a target={"_blank"} href="https://www.instagram.com/azoomuk/">
                <i className="fab fa-instagram"></i>Instagram
              </a>
            </div>
            <div className="container related-searches mt-5">
              <h2>Related Searches</h2>
              {product?.categories?.map((item, index) => {
                return (
                  <button
                    onClick={() => navigate("/products/" + item.url_path)}
                    key={index}
                    className="hover:border-[#58A7FE] border-2 border-solid   "
                  >
                    back to {item.name}
                  </button>
                );
              })}
            </div>

            <div
              onClick={() => {
                navigate("/help/recycling");
              }}
              className="greenBanner cursor-pointer container h-auto"
            >
              <div className="bannerText text-[2rem] text-[#E4F399] flex flex-col items-center font-medium">
                Recycling your unwanted
                <span className="text-[2rem] text-[#FFFFFF]">books</span>
              </div>
              {windowWidth > 768 ? (
                <Green width={"100%"} />
              ) : (
                <GreenResp width={"100%"} />
              )}
            </div>
          </DefaultLayout>
        </>
      )}
    </div>
  );
};
export default ProductDetailPage;
