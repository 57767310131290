import React from "react";

const RewardPolicy = (props) => (
  <>
    <h1>Terms and Conditions - Zoom Points</h1>
    <br />
    <b>1. Zoom Points</b>
    <p className="ml-3">
      <br />
      1.1. Zoom points are a virtual currency that can be earned and redeemed on
      the AZoom.co.uk website.
      <br />
      1.2. The conversion rate for Zoom points is 100 Zoom points for £1. This
      rate may be subject to change without prior notice.
      <br />
      1.3. Zoom points are earned for every purchase made on the AZoom.co.uk
      website, with 1 Zoom point awarded for every pound spent.
      <br />
      1.4. Zoom points may also be awarded on certain sale items, as determined
      by AZoom.co.uk.
      <br />
      1.5. Additional Zoom points may be awarded and added to a user's account
      at the discretion of AZoom.co.uk administration.
      <br />
    </p>
    <b> 2. Zoom Point Usage</b>
    <p className="ml-3">
      <br />
      2.1. Zoom points can be redeemed for discounts on eligible purchases made
      on the AZoom.co.uk website.
      <br />
      2.2. Zoom points cannot be transferred, exchanged, or redeemed for cash or
      any other form of currency.
      <br />
      2.3. Zoom points cannot be used in conjunction with any other offers,
      discounts, or promotions, unless explicitly stated by AZoom.co.uk.
      <br />
    </p>
    <b> 3. Amendments and Adjustments</b>
    <p className="ml-3">
      <br />
      3.1. AZoom.co.uk reserves the right to amend, modify, or terminate the
      Zoom points program at any time without prior notice.
      <br />
      3.2. In the event of a mistake or error in the addition of Zoom points to
      a user's account, AZoom.co.uk reserves the right to amend or adjust the
      points at its discretion.
      <br />
      3.3. If a user believes that an error has occurred with their Zoom points
      balance, they should contact AZoom.co.uk customer support for resolution.
      <br />
    </p>
    <b> 4. Account Responsibility</b>
    <p className="ml-3">
      <br />
      4.1. Users are responsible for maintaining the security and
      confidentiality of their AZoom.co.uk account and Zoom points.
      <br />
      4.2. AZoom.co.uk is not liable for any unauthorized use of Zoom points or
      any loss or damage resulting from such unauthorized use.
    </p>
    <b> 5. General Terms</b>
    <p className="ml-3">
      <br />
      5.1. AZoom.co.uk reserves the right to suspend or terminate a user's
      account and void any Zoom points if they are found to be in violation of
      these Terms or engaged in fraudulent activity.
      <br />
      5.2. AZoom.co.uk may modify these Terms at any time without prior notice.
      It is the user's responsibility to review the Terms periodically.
      <br />
      5.3. These Terms shall be governed by and construed in accordance with the
      laws of the United Kingdom.
      <br />
      5.4. Any disputes arising out of or in connection with these Terms shall
      be subject to the exclusive jurisdiction of the courts of the United
      Kingdom.
    </p>
    <b>By participating in the Zoom points program, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</b>
  </>
);
export default RewardPolicy;
